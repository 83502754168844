import React from 'react'
import PropTypes from 'prop-types'
import { abbreviateName } from '../../helpers'
import { phoneIcon } from '../../assets'

export const CardVillageClient = ({
    size,
    children,
    clientId,
    completed,
    mobile,
    lastPaymentDate,
    amountLeft,
    phoneNumber,
    status,
    ...props
}) => {
    const nameAbbr = abbreviateName(children)
    const cardClientId = clientId && (
        <p className="card-client-id card-client-id--payment">ID {clientId}</p>
    )
    const cardPaymentLabel = completed ? (
        <p className="card-pipe-left client-card-payment--label client-card-payment--label-completed">
            Paiements terminés
        </p>
    ) : (
        <p className="card-pipe-left client-card-payment--label">
            Dernier paiement
            <span className={`card-client-status client-card--${status}`}>
                {' '}
                {lastPaymentDate}
            </span>
        </p>
    )

    return (
        <div className="card-client-parent" {...props}>
            <div className="card-client-name-abbr">
                <div
                    className={[
                        'card-client-abbr',
                        `card-client-abbr-payment--${size}`
                    ].join(' ')}
                >
                    {nameAbbr}
                </div>
                <div className="card-client-data">
                    <h2 className="card-client-name">{children}</h2>
                    <div className="card-client-footer">
                        {cardClientId}
                        {mobile && <>{cardPaymentLabel}</>}
                    </div>
                </div>
            </div>
            {!mobile && (
                <div className="card-client-payment">
                    <p className="card-pipe-left card-payment-amount-date card-payment-left">
                        Reste à payer{' '}
                        <span className="payment-amount-date amount-left">
                            {amountLeft}
                        </span>
                    </p>
                    <p className="card-pipe-left card-payment-amount-date card-last-payment">
                        Dernier paiement{' '}
                        <span className="payment-amount-date">
                            {lastPaymentDate}
                        </span>
                    </p>
                </div>
            )}
            <div className="card-phone-icon">
                {!mobile && <p className="card-phonenumber">{phoneNumber}</p>}
                {phoneIcon({ color: '' })}
            </div>
        </div>
    )
}

CardVillageClient.propTypes = {
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    children: PropTypes.any,
    clientId: PropTypes.string,
    completed: PropTypes.bool,
    onClick: PropTypes.func,
    lastPaymentDate: PropTypes.string,
    status: PropTypes.oneOf(['red', 'green', 'good']),
    mobile: PropTypes.bool,
    amountLeft: PropTypes.string,
    phoneNumber: PropTypes.string
}

CardVillageClient.defaultProps = {
    size: 'small',
    mobile: false
}
