import {
    ShowWarningModalInterface,
    VisitActionTypes,
    VisitEnum,
    VisitStatusInterface
} from 'storeTypes'

const initState: VisitStatusInterface = {
    isVisited: false
}

export const setIsVisitedReducer = (
    state: VisitStatusInterface = initState,
    action: VisitActionTypes
): VisitStatusInterface => {
    switch (action.type) {
        case VisitEnum.SET_IS_VISITED:
            return {
                isVisited: action.payload
            }
        default:
            return state
    }
}

export const setShowWarningReducer = (
    state: ShowWarningModalInterface = { showWarningModal: false },
    action: VisitActionTypes
): ShowWarningModalInterface => {
    switch (action.type) {
        case VisitEnum.SHOW_WARNING_MODAL:
            return {
                showWarningModal: action.payload
            }
        default:
            return state
    }
}
