import React, { FC } from 'react'
import { HappyFarmer, HutIcon } from 'assets'
import { capitalizeWord } from 'helpers'
import { useTranslation } from 'react-i18next'

export const SuccessModalContent: FC<{ clientName: string }> = ({
    clientName
}) => {
    const { t } = useTranslation()
    return (
        <>
            <div>
                <h2 className="modal--success-header">
                    {t('visit.successModalHeader')}
                </h2>
            </div>
            <div className="modal--farmer-icon">
                <HappyFarmer />
            </div>
            <div className="modal--message-container">
                <div>
                    <HutIcon color={'#16A34A'} />
                </div>
                <div>
                    <p
                        className="modal--success-text"
                        data-testid="success-modal-message"
                    >
                        {t('visit.successModalMessage', {
                            clientName: capitalizeWord(clientName)
                        })}
                    </p>
                </div>
            </div>
        </>
    )
}
