import React from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

export const MenuOption = ({ children, icon, to = '', onClick, ...props }) => {
    const navigate = useNavigate()

    const menuOptionHandler = () => {
        if (to !== '') {
            navigate(to)
            return
        }
        onClick()
    }
    return (
        <div
            onClick={menuOptionHandler}
            className="menu-option--child"
            {...props}
        >
            {icon}
            <p className="menu-option--text">{children}</p>
        </div>
    )
}

MenuOption.propTypes = {
    children: PropTypes.any,
    icon: PropTypes.any,
    to: PropTypes.string,
    onClick: PropTypes.any
}
