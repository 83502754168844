import { differenceBy } from 'lodash'

import { SelectedPacket } from 'components/enrollForm/enrollFormTypes'
import { generateUuidv4 } from 'helpers'
import { Order } from 'storeTypes/orderTypes'

export const buildOrderEvents = (
    existingOrders: Order[],
    newOrders: SelectedPacket[],
    clientCode: string
) => {
    const dropOrders = differenceBy(existingOrders, newOrders, 'uuid')
    const createOrders = differenceBy(newOrders, existingOrders, 'uuid')

    return {
        drop: buildDropOrderEvents(dropOrders, clientCode),
        create: buildCreateOrderEvents(createOrders, clientCode)
    }
}

export const buildDropOrderEvents = (orders: Order[], clientCode: string) => {
    return orders
        .filter((order) => !order.delivered && !order.dropped)
        .map((order) => ({
            uuid: generateUuidv4(),
            order_id: order.uuid,
            client_code: clientCode,
            dropped: true,
            date: new Date().toISOString()
        }))
}

export const buildCreateOrderEvents = (
    orders: SelectedPacket[],
    clientCode: string
) => {
    return orders.map((order) => ({
        uuid: order.uuid,
        client_code: clientCode,
        desired_units: order.size,
        currency: 'XOF',
        pricebook_id: order.pricebook_id,
        product_id: order.product_id,
        delivered: false,
        enrollment_date: new Date().toISOString()
    }))
}
