import React from 'react'
import { Card } from './Card'
import { ProductInfo } from 'components/enrollForm/enrollFormTypes'

type CardProductProps = {
    product: ProductInfo
    onClick: (product: ProductInfo) => void
    nbSelectedUnits?: number
}

export const CardProduct = ({
    product,
    onClick,
    nbSelectedUnits = 0
}: CardProductProps) => {
    const handleProductClick = () => {
        onClick(product)
    }

    const selectedClassName =
        nbSelectedUnits > 0 ? 'card-product--selected' : ''

    return (
        <Card onClick={handleProductClick} className="card--product-parent">
            <div
                data-testid={`card-product-${product.sf_id}`}
                className={`card-product ${selectedClassName}`}
            >
                {product.image_url && (
                    <img
                        src={product.image_url}
                        alt={product.name}
                        className="card-product-picture"
                        onError={(
                            event: React.SyntheticEvent<HTMLImageElement, Event>
                        ) =>
                            ((event.target as HTMLImageElement).style.display =
                                'none')
                        }
                    ></img>
                )}
                <div className="card-product-name">{product.name}</div>
                {nbSelectedUnits > 0 && (
                    <div className="card-product-units">{nbSelectedUnits}</div>
                )}
            </div>
        </Card>
    )
}
