import React, { Dispatch, FC, SetStateAction, useEffect, useRef } from 'react'
import './packet.css'
import { plusIcon } from 'assets'
import { CounterButton } from 'components/counterButton/CounterButton'
import {
    ProductInfo,
    SelectedPacket
} from 'components/enrollForm/enrollFormTypes'
import { amountFormatter, constants, generateUuidv4 } from 'helpers'
import { useGetStorage } from 'hooks'

type PackageProps = {
    productPacket: SelectedPacket
    quantity: number
    selectedProduct: Partial<ProductInfo>
    packetSelectionHandler: Dispatch<SetStateAction<SelectedPacket[]>>
}

export const Packet: FC<PackageProps> = ({
    productPacket,
    quantity,
    packetSelectionHandler,
    selectedProduct
}) => {
    const [counter, setCounter] = React.useState(quantity || 0)
    const [showCounterButton, setShowCounterButton] = React.useState(false)
    const [selectedPacket, setSelectedPacket] = React.useState(false)
    const packetRef = useRef<HTMLDivElement>(null)
    const prevCountRef = useRef<number>(counter)
    const {
        price,
        size,
        size_display: sizeDisplay,
        pricebook_id: pricebookId
    } = productPacket

    const { country } = useGetStorage('connect_user')

    useEffect(() => {
        const handleClickOutside = (event: Event) => {
            if (
                packetRef.current &&
                !packetRef.current.contains(event.target as Node)
            ) {
                setShowCounterButton(false)
                setSelectedPacket(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        document.addEventListener('touchstart', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
            document.removeEventListener('touchstart', handleClickOutside)
        }
    }, [])

    useEffect(() => {
        if (counter > prevCountRef.current) {
            // add to packet
            packetSelectionHandler((prevPackets) => {
                const newPacket = {
                    product_id: selectedProduct.sf_id || '',
                    pricebook_id: pricebookId,
                    size: size?.toString(),
                    size_display: sizeDisplay?.toString(),
                    name: selectedProduct.name || '',
                    price: price?.toString(),
                    hidePrice: selectedProduct.hide_price || false,
                    unit: selectedProduct.unit || '',
                    uuid: generateUuidv4()
                }

                return prevPackets ? [...prevPackets, newPacket] : [newPacket]
            })
        }
        if (counter < prevCountRef.current) {
            // remove only one packet by size
            packetSelectionHandler((prevPackets) => {
                if (!prevPackets) return prevPackets
                const foundPacket = prevPackets.find(
                    (packet) =>
                        packet.pricebook_id === pricebookId &&
                        (packet.size === size?.toString() ||
                            packet.size_display === sizeDisplay?.toString())
                )

                if (!foundPacket) return prevPackets

                const res = prevPackets.filter(
                    (packet) => packet.uuid !== foundPacket.uuid
                )
                return res
            })
        }
        prevCountRef.current = counter
    }, [counter])

    const handlePacketSelection = () => {
        setSelectedPacket(true)
        setShowCounterButton(!showCounterButton)
        if (counter === 0) setCounter(1)
    }

    return (
        <div className="package--parent" ref={packetRef}>
            <div className="package--main-parent">
                <div
                    className={`package--main ${
                        selectedPacket ? 'package--main-selected' : ''
                    }`.trim()}
                    data-testid={`package--main--${size}`}
                    onClick={handlePacketSelection}
                >
                    <h2 className="package--size">{sizeDisplay}</h2>
                </div>
                {showCounterButton ? (
                    <div className="package--counter">
                        <CounterButton
                            count={counter}
                            onCountChange={setCounter}
                            maxCount={constants.MAX_ORDER_OF_PACKAGE}
                        />
                    </div>
                ) : counter > 0 ? (
                    <div className="package--quantity package--container-bottom-right">
                        <p className="package--quantity-text">{counter}</p>
                    </div>
                ) : (
                    <div
                        onClick={handlePacketSelection}
                        className="package--add-icon package--container-bottom-right"
                    >
                        {plusIcon({
                            color: '#186D2A',
                            width: '16',
                            height: '16'
                        })}
                    </div>
                )}
            </div>
            <div className="package--price-parent">
                <p className="package--price">
                    {amountFormatter(price, country)}
                </p>
            </div>
        </div>
    )
}
