import React from 'react'

interface ModalProps {
    content: string
    showAppModal: boolean
}

export const AppModal = ({ content, showAppModal }: ModalProps) => {
    return showAppModal ? (
        <div className="alert-parent alert-parent--floating alert--success">
            <div className="alert-floating alert-floating--success">
                <p className="alert-content">{content}</p>
            </div>
        </div>
    ) : (
        <></>
    )
}
