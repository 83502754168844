import { useGetStorage } from '../hooks'

export const HelpHREF = () => {
    const { whatsapp_helpdesk_number: whatsappHelpDeskNumber } =
        useGetStorage('country_config')
    if (whatsappHelpDeskNumber) {
        window.location.href = `whatsapp://send?phone=${whatsappHelpDeskNumber}`
    }
    return null
}
