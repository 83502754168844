import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { CardClientContext } from '../../contexts'
import { useTranslation } from 'react-i18next'
import { setDaysTime } from '../../helpers'

export const LastPaymentDate = ({
    children,
    langTranslate = false,
    dateDisplayLength = 'long',
    ...props
}) => {
    const { t } = useTranslation()
    const { paymentCompleted } = useContext(CardClientContext)

    const dateLength =
        dateDisplayLength === 'long' ? 'dateMonthYear' : 'dayMonth'
    const formattedChildren = children
        ? langTranslate
            ? t(dateLength, {
                  date: new Date(children)
              })
            : children
        : '-'

    const dateColorConfig = (date) => {
        if (!date) {
            return
        }
        const paymentColorClass = 'payment-color'

        const last60thDay = new Date().getTime() - setDaysTime(60)
        const last30thDay = new Date().getTime() - setDaysTime(30)

        const lastPaymentDateTime = new Date(date).getTime()

        if (lastPaymentDateTime >= last30thDay) {
            return `${paymentColorClass}--green`
        } else if (lastPaymentDateTime >= last60thDay) {
            return `${paymentColorClass}--yellow`
        } else {
            return `${paymentColorClass}--red`
        }
    }

    return (
        <div {...props}>
            <div className="card-client-payment">
                <p className="card-pipe-left card-payment-amount-date card-last-payment">
                    {paymentCompleted ? (
                        <span className="payment-amount-date completed-payment">
                            100%
                        </span>
                    ) : (
                        <>
                            {t('village.lastPayment')}{' '}
                            <span className="payment-amount-date">
                                <span className={dateColorConfig(children)}>
                                    {formattedChildren}
                                </span>
                            </span>
                        </>
                    )}
                </p>
            </div>
        </div>
    )
}

LastPaymentDate.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date)
    ]),
    status: PropTypes.oneOf(['red', 'green', 'good']),
    langTranslate: PropTypes.bool,
    dateDisplayLength: PropTypes.oneOf(['long', 'short'])
}
