import React, { Dispatch, SetStateAction, useState } from 'react'

interface onChangeInterface<T> {
    inputText: T
    onChangeHandler: (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => void
    resetTextInput: () => void
    setTextValue: Dispatch<SetStateAction<T>>
}

export const useOnChange = <T extends {}>(
    initialText: T
): onChangeInterface<T> => {
    const [inputText, setTextValue] = useState<T>(initialText)
    const onChangeHandler = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const inputValue =
            e.target.type === 'checkbox'
                ? (e.target as HTMLInputElement).checked
                : e.target.value
        const inputName = e.target.name
        setTextValue((prevState) => {
            return {
                ...prevState,
                [inputName]: inputValue
            }
        })
    }

    const resetTextInput = () => {
        setTextValue(initialText)
    }

    return { inputText, onChangeHandler, resetTextInput, setTextValue }
}
