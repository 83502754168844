import React, { useEffect, useState, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useSelector } from 'react-redux'
import {
    Container,
    Row,
    TopBar,
    Spinner,
    TimedAlert,
    Col,
    CardSale,
    Button,
    Alert
} from '../../components'
import { amountFormatter, constants, sortHandler } from '../../helpers'
import { useBindDispatch, useGetStorage } from '../../hooks'
import { useNavigate } from 'react-router-dom'
import { arrowIcon, closeIcon } from '../../assets'
import './card.css'
import { useTranslation } from 'react-i18next'
import { findPermission } from '../../helpers/findPermission'

export const Cards = () => {
    const [animate, setAnimate] = useState(false)
    const [animationList, setAnimationList] = useState(false)
    const nodeRef = useRef(null)
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { navbarHandler, cardInventoryHandler } = useBindDispatch()
    const { username: veCode, country } = useGetStorage('connect_user')
    const [isSelected, setIsSelected] = useState({
        amount: -1,
        isStock: true
    })
    const [errorMsg, setErrorMsg] = useState(false)
    const {
        loading,
        cardDetailData,
        error: cardError
    } = useSelector((store) => store.cardInventory)
    const btnSelected = isSelected.amount >= 0 && isSelected.isStock
    const userPermissions = useGetStorage(constants.USER_PERMISSIONS)
    const canSellCard = findPermission(userPermissions, constants.ADD_CARD_SALE)
    const canViewCards = findPermission(userPermissions, constants.VIEW_CARDS)

    const cannotSellCardAndIsBtnSelected = !canSellCard && btnSelected
    const canSellCardAndIsBtnSelected = canSellCard && btnSelected

    useEffect(() => {
        if (!canViewCards) {
            return navigate('/home')
        }
        setAnimate(true)
        // show menu bar
        navbarHandler(constants.SHOW_NAVBAR)
        if (cardDetailData.length < 1) {
            cardInventoryHandler({ veCode })
        }
        // set page title
        document.title = t('card.pageTitle')
    }, [canViewCards])

    const cardList = (cardDetailData) => {
        cardDetailData = sortHandler(cardDetailData, 'amount')
        const cardArray = []
        let idx = 0
        for (const item of cardDetailData) {
            if (!isNaN(item.cards_in_packet)) {
                idx += 1
                cardArray.push(
                    <Col
                        md={3}
                        col={6}
                        className={
                            animationList
                                ? `card-stock--single animate-show-${idx} animate-showed-${idx}`
                                : `animate-show-${idx}`
                        }
                        key={item.amount}
                        id={`card-single-${item.amount}`}
                    >
                        <CardSale
                            inStock={item.cards_in_packet > 0}
                            stock={{
                                sold: !isNaN(item.used_cards)
                                    ? item.used_cards
                                    : 0,
                                total: item.cards_in_packet
                            }}
                            onClick={() =>
                                selectCardHandler(
                                    item.amount,
                                    item.cards_in_packet > 0
                                )
                            }
                            selected={isSelected.amount === item.amount}
                            data-testid="card-sale-list"
                        >
                            {amountFormatter(item.amount, country)}
                        </CardSale>
                    </Col>
                )
            }
        }
        return cardArray
    }

    // toggle selected card
    const selectCardHandler = (amount, isStock) => {
        setIsSelected((prevState) => {
            if (prevState.amount === amount) {
                return { amount: -1, isStock: true }
            }
            return { amount, isStock }
        })
    }

    const btnAmount = (
        <div>
            {t('sellToCustomer')}
            <span className="button-amount">
                {amountFormatter(isSelected.amount, country)}
            </span>
        </div>
    )

    const cardNavigationHandler = () => {
        if (cannotSellCardAndIsBtnSelected) {
            setErrorMsg(true)
            return setTimeout(() => setErrorMsg(false), 2500)
        }
        navigate(
            canSellCardAndIsBtnSelected
                ? `card-sale?card-amount=${isSelected.amount}`
                : 'card-inventory'
        )
    }

    const cardRender = (cardDetailData) => (
        <CSSTransition
            unmountOnExit
            timeout={constants.ANIMATION_TIMEOUT}
            in={animate}
            classNames="generic"
            appear
            onEnter={() => setAnimationList(true)}
            nodeRef={nodeRef}
        >
            <div className="card-stock page-wrapper" ref={nodeRef}>
                <Container>
                    <Row className="card-stock--row" data-testid="card-list">
                        {cardList(cardDetailData)}
                    </Row>
                    <Row className="card-stock--row">
                        <div className="card-stock--button">
                            <Button
                                icon={
                                    canSellCardAndIsBtnSelected ||
                                    cannotSellCardAndIsBtnSelected
                                        ? ''
                                        : arrowIcon
                                }
                                iconPos="right"
                                style={
                                    canSellCardAndIsBtnSelected
                                        ? 'primary'
                                        : 'secondary'
                                }
                                onClick={cardNavigationHandler}
                                className={
                                    animationList
                                        ? 'animate-show-5 animate-showed-5'
                                        : 'animate-show-5'
                                }
                                id={
                                    canSellCard
                                        ? 'card-sale-btn'
                                        : 'card-inventory-btn'
                                }
                                data-testid="card-sale-btn"
                            >
                                {btnSelected ? (
                                    <> {btnAmount}</>
                                ) : (
                                    t('card.viewInventory')
                                )}
                            </Button>
                        </div>
                        {errorMsg && (
                            <div data-testid="error-msg">
                                <Alert
                                    type="floating"
                                    status="error"
                                    icon={true}
                                    iconType={closeIcon({ color: 'red' })}
                                >
                                    {t('permission.noPermission')}
                                </Alert>
                            </div>
                        )}
                    </Row>
                </Container>
            </div>
        </CSSTransition>
    )

    const cardsPage = loading ? (
        <Spinner size={'90'} pageSpinner={true} />
    ) : cardError ? (
        <TimedAlert type="floating" status="error">
            {cardError}
        </TimedAlert>
    ) : (
        cardRender(cardDetailData)
    )

    return (
        <>
            <div className="header-bar" id="header-bar">
                <Container>
                    <TopBar>{t('cards')}</TopBar>
                </Container>
            </div>
            {cardsPage}
        </>
    )
}
