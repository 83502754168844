import { ActionTypes } from '../actions'
import { groupBy } from 'lodash'

export const villageClientReducer = (
    state = { clientList: {}, loading: true },
    action
) => {
    switch (action.type) {
        case ActionTypes.FETCH_CLIENT_LIST_DATA: {
            const villageClientList = {
                [action.villageId]: action.payload
            }
            return {
                clientList: { ...state.clientList, ...villageClientList },
                loading: false,
                error: ''
            }
        }
        case ActionTypes.FETCHING_VILLAGE_CLIENT_DATA:
            return {
                loading: true,
                error: '',
                clientList: {}
            }
        case ActionTypes.FAIL_REQUEST:
            return { loading: false, clientList: {}, error: action.payload }
        case ActionTypes.SELECT_VILLAGE_CLIENT: {
            const { clientId, villageId } = action.payload
            // get client from client list
            const clientData = state.clientList[villageId]?.find(
                (client) => client.client_code === clientId
            )
            return { ...state, clientData: clientData || {} }
        }
        case ActionTypes.FETCHING_BG_CLIENT_LIST:
            return {
                loading: !Object.keys(state.clientList).length > 0,
                clientList: { ...state.clientList },
                error: ''
            }
        case ActionTypes.RESET_SELECT_VILLAGE_CLIENT:
            return {
                ...state,
                clientData: {}
            }
        case ActionTypes.SELECTED_CLIENT:
            return {
                ...state,
                clientData: action.payload
            }
        default:
            return state
    }
}

export const villagePreviousClientReducer = (
    state = { previousClientList: {}, loading: true },
    action
) => {
    switch (action.type) {
        case ActionTypes.FETCHING_PREVIOUS_CLIENT:
            return {
                previousClientList: [],
                loading: true,
                error: ''
            }
        case ActionTypes.FETCH_PREVIOUS_CLIENT_LIST: {
            const previousClients = {
                [action.villageId]: action.payload
            }
            return {
                previousClientList: {
                    ...state.previousClientList,
                    ...previousClients
                },
                loading: false,
                error: ''
            }
        }
        case ActionTypes.FAIL_PREVIOUS_CLIENT_REQUEST:
            return {
                previousClientList: [],
                error: action.payload,
                loading: false
            }
        case ActionTypes.PREV_SELECT_VILLAGE_CLIENT: {
            const { clientId, villageId } = action.payload
            // get client from client list
            const prevClientData = state.previousClientList[villageId]?.find(
                (client) => client.client_code === clientId
            )
            return { ...state, prevClientData: prevClientData || {} }
        }
        case ActionTypes.SELECTED_PREV_CLIENT:
            return {
                ...state,
                prevClientData: action.payload
            }
        default:
            return state
    }
}

export const openVillagePageReducer = (state = {}, action) => {
    switch (action.type) {
        case ActionTypes.OPENING_VILLAGE_PAGE:
            return {
                openVillagePageData: action.payload
            }

        default:
            return state
    }
}

export const markDeliveredReducer = (
    state = {
        loading: false,
        error: ''
    },
    action
) => {
    switch (action.type) {
        case ActionTypes.MARKING_GOAL_ITEM_DELIVERED:
            return {
                delivered: false,
                loading: true,
                error: ''
            }
        case ActionTypes.MARKED_GOAL_ITEM_DELIVERED:
            return {
                delivered: action.delivered,
                loading: false,
                error: ''
            }
        case ActionTypes.FAILED_GOAL_ITEM_DELIVERED:
            return {
                delivered: false,
                loading: false,
                error: ''
            }

        default:
            return state
    }
}

export const preCertifyReducer = (
    state = { loading: false, error: '', preCertified: false },
    action
) => {
    switch (action.type) {
        case ActionTypes.SUBMITTING_PRE_CERTIFY:
            return { loading: true, error: '', preCertified: false }
        case ActionTypes.SUBMITTED_PRE_CERTIFY:
            return { loading: false, error: '', preCertified: action.payload }
        default:
            return state
    }
}

export const villageSelectionInfoReducer = (state = { url: '' }, action) => {
    switch (action.type) {
        case ActionTypes.SET_SELECTION_VILLAGE_URL:
            return {
                url: action.payload.url,
                clientToEnroll: action.payload.clientToEnroll
            }
        case ActionTypes.CLEAR_SELECTION_VILLAGE_INFO:
            return { url: '' }
        default:
            return state
    }
}

export const singleClientReducer = (
    state = { loading: false, error: '', client: [] },
    action
) => {
    switch (action.type) {
        case ActionTypes.FETCHING_SINGLE_CLIENT:
            return { loading: true, error: '', client: [] }
        case ActionTypes.FETCHED_SINGLE_CLIENT:
            return { loading: false, error: '', client: action.payload }
        case ActionTypes.FAIL_SINGLE_CLIENT_REQUEST:
            return { loading: false, error: action.payload, client: [] }
        case ActionTypes.CLEAR_FETCHED_SINGLE_CLIENT:
            return { loading: false, error: '', client: [] }
        default:
            return state
    }
}

export const villageClientOrdersReducer = (
    state = {
        clientOrders: []
    },
    action
) => {
    const { FETCH_ORDERS, FAIL_FETCH_ORDERS, RESET_CLIENT_ORDERS } = ActionTypes
    switch (action.type) {
        case FETCH_ORDERS:
            return {
                ...state,
                clientOrders: action.payload,
                error: ''
            }
        case FAIL_FETCH_ORDERS:
            return {
                ...state,
                clientOrders: [],
                error: action.payload
            }
        case RESET_CLIENT_ORDERS:
            return {
                ...state,
                clientOrders: [],
                error: ''
            }
        default:
            return state
    }
}

export const multipleVillagesCountReducer = (
    state = { totalClientObj: {} },
    action
) => {
    const clientByVillage = groupBy(action.payload, 'village_id')

    for (const [key, value] of Object.entries(clientByVillage)) {
        const [currentClient, prevClient] = value.reduce(
            ([current, prev], client) =>
                (client?.all_enrollment ?? 0) > 0
                    ? [current + 1, prev]
                    : [current, prev + 1],
            [0, 0]
        )

        clientByVillage[key] = {
            currentClient,
            prevClient
        }
    }

    switch (action.type) {
        case ActionTypes.MULTIPLE_VILLAGE_COUNT:
            return {
                totalClientObj: clientByVillage
            }
        default:
            return state
    }
}
