import React from 'react'
import PropTypes from 'prop-types'
import './stats.css'

export const Stats = ({
    children,
    ranges = { high: 90, low: 50 },
    className,
    ...props
}) => {
    const statFloatValue = parseFloat(children)
    const statsConfigColor =
        statFloatValue >= ranges.high
            ? 'green'
            : statFloatValue < ranges.low
            ? 'red'
            : 'yellow'

    return (
        <div
            className={['stats', `stats--${statsConfigColor}`, className].join(
                ' '
            )}
            {...props}
        >
            <span
                className={[
                    'stats-icon',
                    `stats-icon--${statsConfigColor}`
                ].join(' ')}
            ></span>
            <p className="stats-percentage">
                {statFloatValue === Infinity ? '-' : statFloatValue + '%'}
            </p>
        </div>
    )
}

Stats.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
    ranges: PropTypes.object
}
