/**
 * Pass list of filter options and data to filter.
 * Function returns all the elements that matches the filter option list
 * @param {Array} filterList
 * @param {ArrayObject} dataList
 */

const PAYMENT_STATUS = {
    SUCCESS: 'success',
    FAILED: 'failed',
    PENDING: 'pending',
    USED_VOUCHER: 'used_voucher',
    ALREADY_ASSIGNED_TO_CLIENT: 'already_assigned_to_client'
}

export const filterHandler = (dataList, filterCondition) => {
    return dataList.filter((clientObj) => {
        return filterCondition(clientObj)
    })
}

export const filterUsedPayments = (payments) => {
    const successfulPayments = new Map(
        payments
            .filter((payment) => payment.status === PAYMENT_STATUS.SUCCESS)
            .map((payment) => [
                `${payment.transaction_id}-${payment.client_code}`,
                payment
            ])
    )

    return payments.filter((payment) => {
        if (
            payment.status === PAYMENT_STATUS.USED_VOUCHER ||
            payment.status === PAYMENT_STATUS.ALREADY_ASSIGNED_TO_CLIENT
        ) {
            return !successfulPayments.has(
                `${payment.transaction_id}-${payment.client_code}`
            )
        }
        return true
    })
}
