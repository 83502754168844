import React from 'react'
import { amountFormatter } from 'helpers'
import { useTranslation } from 'react-i18next'
import { TransactionKind, TransactionPayloadType } from 'storeTypes'

interface PaymentRowProps {
    transaction: TransactionPayloadType
    country: string
}

export const PaymentRow = ({ transaction, country }: PaymentRowProps) => {
    const { t } = useTranslation()

    const {
        type,
        amount,
        date_received: dateReceived,
        voucher_code: voucherCode
    } = transaction

    const isDelivery = type === TransactionKind.DELIVERY

    return (
        <div className="client-payment--history-section">
            <p className="payment--date">
                {t('fullDateMonthYear', {
                    date: new Date(dateReceived || '')
                })}
            </p>
            <div className="payment--amount-card-id">
                <p className="payment--content payment--amount">
                    {amountFormatter(
                        isDelivery ? Math.abs(amount || 0) : amount,
                        country
                    )}
                </p>
                <p className="payment--content payment--card-id">
                    {isDelivery ? t('clientDetails.delivery') : voucherCode}
                </p>
            </div>
        </div>
    )
}
