import {
    ProductInfo,
    SelectedPacket,
    ProductPriceType
} from 'components/enrollForm/enrollFormTypes'
import {
    EnrollmentEnum,
    EnrollmentPayloadType,
    EnrollmentActionTypes,
    ClientDetailsPayloadInterface,
    CardPaymentPayloadType
} from '../storeTypes'
import { formatPayloadToClientObject } from 'helpers'

type formatEnrollmentType = {
    product_list: [
        {
            hide_price: string | boolean
            category: string
            unit: string
            product_prices: ProductPriceType[]
        }
    ]
}

type formatEnrollmentReturnType = {}

export function formatEnrollmentData(
    data: formatEnrollmentType[]
): formatEnrollmentReturnType[] {
    if (data) {
        data.forEach((item) => {
            item.product_list.forEach((product) => {
                // Convert hide_price from string to boolean
                product.hide_price = product.hide_price === 'True'

                if (product.product_prices.length) {
                    const price = product.product_prices[0]
                    product.unit = price.units_of_measure
                }
            })
        })
    }
    return data
}

export const enrollmentReducer = (
    state: EnrollmentPayloadType = { productListData: [], loading: true },
    action: EnrollmentActionTypes
): EnrollmentPayloadType => {
    switch (action.type) {
        case EnrollmentEnum.FETCH_ENROLLMENT_FORM:
            return {
                productListData: formatEnrollmentData(
                    action.payload as []
                ) as [],
                loading: false,
                preFetchLoading: false,
                error: ''
            }
        case EnrollmentEnum.FETCHING_ENROLLMENT_FORM:
            return {
                loading: true,
                preFetchLoading: false,
                error: '',
                productListData: []
            }
        case EnrollmentEnum.PRE_FETCHING_ENROLLMENT_FORM:
            return {
                loading: true,
                preFetchLoading: true,
                error: '',
                productListData: []
            }
        case EnrollmentEnum.FAIL_ENROLLMENT_REQUEST:
            return {
                loading: false,
                preFetchLoading: false,
                productListData: [],
                error: action.payload
            }
        default:
            return state
    }
}

export const enrollmentSubmissionReducer = (
    state: EnrollmentPayloadType = {
        response: null,
        loading: false,
        error: null
    },
    action: EnrollmentActionTypes
): EnrollmentPayloadType => {
    switch (action.type) {
        case EnrollmentEnum.SUBMIT_ENROLLMENT_FORM:
            return {
                response: action.payload,
                loading: false,
                error: ''
            }
        case EnrollmentEnum.SUBMITTING_ENROLLMENT_FORM:
            return { loading: true, error: '', response: null }
        case EnrollmentEnum.FAIL_ENROLLMENT_SUBMISSION:
            return {
                loading: false,
                response: null,
                error: action.payload
            }
        default:
            return state
    }
}

export const updatePackagesReducer = (
    state: EnrollmentPayloadType = {},
    action: EnrollmentActionTypes
): EnrollmentPayloadType => {
    switch (action.type) {
        case EnrollmentEnum.UPDATING_PACKAGES:
            return { loading: true, response: null, error: '' }
        case EnrollmentEnum.UPDATE_PACKAGES_SUCCESS:
            return { loading: false, error: '' }
        case EnrollmentEnum.ENROLLMENT_PAYLOAD:
            return {
                ...state,
                enrollmentPayload: formatPayloadToClientObject(action.payload)
            }
        case EnrollmentEnum.UPDATE_PACKAGES_FAIL:
            return { loading: false, response: null, error: action.payload }
        default:
            return state
    }
}

export const clientDetailEnrollmentReducer = (
    state: EnrollmentPayloadType = {
        response: null,
        loading: false,
        error: null
    },
    action: EnrollmentActionTypes
): EnrollmentPayloadType => {
    switch (action.type) {
        case EnrollmentEnum.SUBMIT_CLIENT_DETAILS_ENROLLMENT:
            return {
                response: action.payload,
                loading: false,
                error: ''
            }
        case EnrollmentEnum.SUBMITTING_CLIENT_DETAILS_ENROLLMENT:
            return { loading: true, error: '', response: null }
        case EnrollmentEnum.FAIL_CLIENT_DETAILS_ENROLLMENT:
            return {
                loading: false,
                response: null,
                error: action.payload
            }
        case EnrollmentEnum.CLIENT_DETAILS_PAYLOAD:
            return {
                clientDetailsPayload: action.payload,
                gpsCoords: action.gpsCoords
            }
        default:
            return state
    }
}

type EnrollmentLocalState = {
    client?: ClientDetailsPayloadInterface
    orders?: {
        selectedProduct?: ProductInfo
        selectedPackets?: SelectedPacket[]
        total?: string
    }
    payments?: CardPaymentPayloadType
}
export const enrollmentLocalStateReducer = (
    state: EnrollmentLocalState = {},
    action: EnrollmentActionTypes
): EnrollmentLocalState => {
    switch (action.type) {
        case EnrollmentEnum.SAVE_CLIENTS_STATE:
            return {
                ...state,
                client: {
                    ...state.client,
                    ...(action.payload as ClientDetailsPayloadInterface)
                }
            }
        case EnrollmentEnum.SELECT_PRODUCT:
            return {
                ...state,
                orders: {
                    ...state.orders,
                    selectedProduct: action.payload as ProductInfo
                }
            }
        case EnrollmentEnum.ADD_PACKETS:
            return {
                ...state,
                orders: {
                    ...state.orders,
                    selectedPackets: [
                        ...(state.orders?.selectedPackets || []),
                        { ...(action.payload as SelectedPacket) }
                    ],
                    total: [{ ...(action.payload as SelectedPacket) }]
                        ?.reduce(
                            (prevValue, currValue) =>
                                currValue &&
                                prevValue + Number(currValue.price),
                            0
                        )
                        .toString()
                }
            }
        case EnrollmentEnum.REMOVE_PACKETS:
            return {
                ...state,
                orders: {
                    ...state.orders,
                    selectedPackets: state.orders?.selectedPackets?.filter(
                        (packet) => packet.uuid !== action.payload
                    )
                }
            }
        case EnrollmentEnum.SAVE_PAYMENTS_STATE:
            return {
                ...state,
                payments: action.payload as CardPaymentPayloadType
            }

        default:
            return state
    }
}
