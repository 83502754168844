import React, { useEffect } from 'react'
import { searchIcon } from 'assets'

type NotificationSearchType = {
    searchHandler: (params: string) => void
    inputText: {
        searchInput: string
    }
    onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void
    setActiveFilter: (filter: string) => void
}

export const NotificationSearch: React.FC<NotificationSearchType> = ({
    searchHandler,
    inputText,
    onChangeHandler,
    setActiveFilter
}) => {
    useEffect(() => {
        // handle search process
        searchHandler(inputText.searchInput.trim())
        setActiveFilter('all')
    }, [inputText])

    return (
        <div className="notification-search">
            <input
                className="notification-search--input"
                placeholder="Rechercher carte ou ID client"
                value={inputText.searchInput}
                onChange={onChangeHandler}
                id="searchInput"
                name="searchInput"
            />
            <span className="notification-search--icon">{searchIcon({})}</span>
        </div>
    )
}
