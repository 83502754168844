import {
    PaymentNotificationActionType,
    PaymentNotificationEnum,
    PaymentPayloadType
} from '../storeTypes'
import { sortHandler, constants } from 'helpers'

export const paymentNotificationReducer = (
    state: PaymentPayloadType = { paymentData: [], loading: false, error: '' },
    action: PaymentNotificationActionType
): PaymentPayloadType => {
    switch (action.type) {
        case PaymentNotificationEnum.FETCHING_PAYMENT_NOTIFICATION:
            return {
                paymentData: [],
                loading: true,
                error: ''
            }
        case PaymentNotificationEnum.FETCH_PAYMENT_NOTIFICATION_SUCCESS:
            return {
                loading: false,
                error: '',
                paymentData: sortHandler(
                    action.payload,
                    'date_received',
                    constants.SORT_TYPE.DATE,
                    constants.SORT_DIRECTION.DESCENDING
                ) as []
            }
        case PaymentNotificationEnum.FETCH_PAYMENT_NOTIFICATION_FAILURE:
            return {
                loading: false,
                error: action.payload || 'Un problème est survenu',
                paymentData: []
            }
        case PaymentNotificationEnum.FILTERED_PAYMENT_NOTIFICATION:
            return {
                ...state,
                paymentData: sortHandler(
                    action.payload,
                    'date_received',
                    constants.SORT_TYPE.DATE,
                    constants.SORT_DIRECTION.DESCENDING
                ) as []
            }
        case PaymentNotificationEnum.SEARCHED_PAYMENT_NOTIFICATION:
            return {
                ...state,
                paymentData: sortHandler(
                    action.payload,
                    'date_received',
                    constants.SORT_TYPE.DATE,
                    constants.SORT_DIRECTION.DESCENDING
                ) as []
            }
        default:
            return state
    }
}
