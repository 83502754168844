import {
    ClientDetailsPayloadInterface,
    InteractionPayloadType
} from 'storeTypes'

import { getLastMondayDate } from './getLastMondayDate'
import { isFarmerAwareness } from './interactions/interactionHelper'

const isThisWeek = (date: string | undefined) => {
    if (!date) {
        return false
    }

    return Date.parse(date) >= getLastMondayDate().getTime()
}

export const visitedThisWeek =
    (interactions: InteractionPayloadType[]) =>
    (client: ClientDetailsPayloadInterface) => {
        return interactions.some((interaction) => {
            return (
                interaction.client_code === client.client_code &&
                isFarmerAwareness(interaction) &&
                isThisWeek(interaction.date_submitted || interaction.date)
            )
        })
    }

export const visitedAnytime = (interactions: InteractionPayloadType[]) => {
    return (client: ClientDetailsPayloadInterface) => {
        return interactions.some((interaction) => {
            return (
                interaction.client_code === client.client_code &&
                isFarmerAwareness(interaction)
            )
        })
    }
}

export const visitedNever = (interactions: InteractionPayloadType[]) => {
    return (client: ClientDetailsPayloadInterface) => {
        return !visitedAnytime(interactions)(client)
    }
}
