import { useState } from 'react'

type ValidationRule = (value: string | boolean) => string | undefined

interface ValidationRules {
    [key: string]: ValidationRule[]
}

export interface ValidationResult {
    [key: string]: string | undefined
}

export const useValidation = (
    formInputValues: Record<string, string | boolean | unknown>,
    validationRules: ValidationRules
) => {
    const [errors, setErrors] = useState<ValidationResult>({})

    const validateForm = (): boolean => {
        let isValid = true
        const validationErrors: ValidationResult = {}

        Object.keys(validationRules).forEach((fieldName) => {
            const rules = validationRules[fieldName]
            let fieldError: string | undefined

            rules.some((rule) => {
                fieldError = rule(
                    formInputValues[fieldName] as string | boolean
                )
                return fieldError !== undefined
            })

            if (fieldError) {
                validationErrors[fieldName] = fieldError
                isValid = false
            }
        })

        setErrors(validationErrors)
        return isValid
    }

    const resetError = () => {
        setErrors({})
    }

    return { errors, validateForm, resetError }
}
