import React from 'react'
import { Card } from '../../components'
import { PaymentSummary } from './PaymentSummary'
import { VillageClientPaymentInterface } from './villageTypes'

export const VillageClientPayment: React.FC<VillageClientPaymentInterface> = ({
    allEnrollment,
    balance,
    lastVoucherDate,
    percentage,
    paymentLeft
}) => {
    return (
        <div className="height-100">
            <Card
                shadow
                className="height-100 village-client-payment-card"
            >
                <PaymentSummary
                    allEnrollment={allEnrollment}
                    balance={balance}
                    lastVoucherDate={lastVoucherDate}
                    percentage={percentage}
                    paymentLeft={paymentLeft}
                    paymentHistoryLink={true}
                />
            </Card>
        </div>
    )
}
