import React, { createContext, useState } from 'react'
import { AppModal } from './AppModal'
import { constants } from '../../helpers/constants'

interface AppModalContextProps {
    displayAppModal: (content: string, duration?: number) => void
}

export const AppModalContext = createContext<AppModalContextProps>({
    displayAppModal: () => {}
})

export const AppModalProvider = ({
    children
}: {
    children: React.ReactNode
}) => {
    const [content, setContent] = useState('')
    const [showAppModal, setShowAppModal] = useState(false)

    const displayAppModal = (
        content: string,
        duration = process.env.REACT_APP_NOTIFICATION_TIMEOUT
            ? parseInt(process.env.REACT_APP_NOTIFICATION_TIMEOUT)
            : constants.DEFAULT_FEEDBACK_MODAL_DURATION
    ) => {
        setContent(content)
        setShowAppModal(true)
        const timer = setTimeout(() => {
            setShowAppModal(false)
        }, duration)
        return () => clearTimeout(timer)
    }

    const contextValue: AppModalContextProps = {
        displayAppModal
    }

    return (
        <AppModalContext.Provider value={contextValue}>
            {children}
            <AppModal content={content} showAppModal={showAppModal} />
        </AppModalContext.Provider>
    )
}
