export const TWO_YEARS_APART = new Date().getFullYear() - 2

export const currentClientFilterConfig = (t) => {
    return [
        {
            value: t('village.active'),
            option: t('village.active')
        },
        {
            value: t('village.inactive'),
            option: t('village.inactive')
        },
        {
            value: t('village.completed'),
            option: t('village.completed')
        }
    ]
}

export const previousClientFilterConfig = (t) => {
    const currentYear = new Date().getFullYear()
    const previousClientFilterOptions = []
    for (let i = 0; i < 3; i++) {
        const val = (currentYear - i).toString()
        previousClientFilterOptions.push({
            value: val,
            option: val
        })
    }

    const otherFilterOptions = [
        {
            value: t('village.beforeYear', { year: TWO_YEARS_APART }),
            option: t('village.beforeYear', { year: TWO_YEARS_APART })
        },
        {
            value: t('village.clientPayment'),
            option: t('village.clientPayment')
        },
        {
            value: t('village.neverDelivered'),
            option: t('village.neverDelivered')
        },
        {
            value: t('village.visitedThisWeek'),
            option: t('village.visitedThisWeek'),
            hidden: true
        },
        {
            value: t('village.visited'),
            option: t('village.visited')
        },
        {
            value: t('village.notVisited'),
            option: t('village.notVisited')
        }
    ]

    return [...previousClientFilterOptions, ...otherFilterOptions]
}

export const currentClientFilter = (t) => ({
    ALL: t('all'),
    ACTIVE: t('village.active'),
    INACTIVE: t('village.inactive'),
    COMPLETED_PAYMENT: t('village.completed')
})

export const prevClientFilter = (t) => ({
    ALL: t('all'),
    BEFORE_YEAR: t('village.beforeYear', { year: TWO_YEARS_APART }),
    NEVER_DELIVERED: t('village.neverDelivered'),
    CLIENT_PAYMENT: t('village.clientPayment'),
    VISITED_THIS_WEEK: t('village.visitedThisWeek'),
    VISITED: t('village.visited'),
    NOT_VISITED: t('village.notVisited')
})
