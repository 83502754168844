import { ActionTypes } from '.'
/**
 * This handles the display or hiding of the navbar
 * @param {string} action
 * @returns {dispatch function}
 */
export const navbarAction = (action) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.PROCESS_NAVBAR, payload: action })
    }
}
