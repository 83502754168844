import { NumberInput } from 'components'
import { validationRules } from 'helpers'
import { useValidation } from 'hooks'
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ClientDetailsRouteContextType } from '../v3/types/routeContextType'
import { ContinueButton } from 'components/button/ContinueButton'
import { phoneIcon } from 'assets'

export const PhoneNumber: FC<ClientDetailsRouteContextType> = ({
    phoneNumberHandler,
    phoneNumberInputValue,
    userCountry,
    handleSubmit
}) => {
    const { t } = useTranslation()

    const countryPhoneLengthConfig: Record<string, number> = {
        Mali: 8,
        Senegal: 9
    }

    const phoneNumberValidationRule = validationRules({
        country: userCountry,
        phone: phoneNumberInputValue
    })

    const { errors, validateForm } = useValidation(
        { phone: phoneNumberInputValue },
        phoneNumberValidationRule
    )

    useEffect(() => {
        if (phoneNumberInputValue && phoneNumberInputValue.length > 0) {
            validateForm()
        }
    }, [phoneNumberInputValue, userCountry])

    return (
        <div>
            <div className="enrollment--phone-title">
                <p className="enrollment--input-label">
                    {t('enrollment.clientPhone')}
                </p>
                <div className="enrollment--phone-icon">
                    {phoneIcon({ fillColor: '#16A34A' })}
                </div>
            </div>
            <div className="enrollment--input-phone enrollment-button">
                <div>
                    <NumberInput
                        length={
                            countryPhoneLengthConfig[userCountry || 'Senegal']
                        }
                        onChangeNumberHandler={(number) => {
                            phoneNumberHandler?.(number)
                        }}
                        className={`${
                            countryPhoneLengthConfig[userCountry || 0] > 7 &&
                            'enrollment--input-number'
                        }`}
                        initialValue={phoneNumberInputValue}
                    />
                    {errors.phone && (
                        <p className="enrollment--input-error">
                            {errors.phone}
                        </p>
                    )}
                </div>
                {phoneNumberInputValue && !errors.phone && (
                    <ContinueButton
                        onClick={handleSubmit}
                        btnText={t('enrollment.enrollClient')}
                    />
                )}
            </div>
        </div>
    )
}
