import { ActionTypes } from '../actions'

export const veDepositReducer = (
    state = { veDepositData: undefined, loading: true },
    action
) => {
    switch (action.type) {
        case ActionTypes.FETCH_VE_DEPOSIT_DATA:
            return {
                ...state,
                veDepositData: action.payload,
                loading: false,
                error: ''
            }
        case ActionTypes.FETCHING_VE_DEPOSIT_DATA:
            return { loading: true, error: '', veDepositData: undefined }
        case ActionTypes.FAIL_VE_DEPOSIT_DATA:
            return {
                loading: false,
                veDepositData: undefined,
                error: action.payload
            }
        default:
            return state
    }
}
