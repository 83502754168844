import React, { FC } from 'react'

export const WarningModalContent: FC<{ warningText: string }> = ({
    warningText
}) => {
    return (
        <div className="warning--modal-body">
            <p className="warning--modal-text">{warningText}</p>
        </div>
    )
}
