export const install = () => {
    let deferredPrompt

    window.addEventListener('beforeinstallprompt', (evt) => {
        deferredPrompt = evt

        deferredPrompt.prompt()

        deferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
                console.log('User accepted the A2HS prompt')
            } else {
                console.log('User dismissed the A2HS prompt')
            }
            deferredPrompt = null
        })
    })
}
