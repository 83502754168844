import { Dispatch } from 'redux'
import { VisitActionTypes, VisitEnum } from '../storeTypes/visitTypes'
import { constants, sendMessageToWorker } from '../helpers'
import connectApi from '../api/connectApi'
import { ErrorResponse } from '../types'
import { InteractionPayloadType } from 'storeTypes'

export const visitPostAction =
    (formPayload: InteractionPayloadType) =>
    // eslint-disable-next-line space-before-function-paren
    async (dispatch: Dispatch<VisitActionTypes>) => {
        const { VISIT } = constants.endpoints
        dispatch({ type: VisitEnum.SUBMITTING_VISIT_FORM })
        try {
            // Submit visit form

            sendMessageToWorker({ 'store interaction': formPayload })

            const response = await connectApi.post(VISIT, formPayload)
            dispatch({
                type: VisitEnum.SUBMIT_VISIT_FORM,
                payload: response.data
            })
        } catch (error: unknown) {
            if (error instanceof ErrorResponse) {
                dispatch({
                    type: VisitEnum.FAIL_VISIT_SUBMISSION,
                    payload: error.response.data.detail
                })
            } else {
                dispatch({
                    type: VisitEnum.FAIL_VISIT_SUBMISSION,
                    payload: 'something went wrong'
                })
            }
        }
    }

export const setIsVisitedAction =
    (visitedStatus: boolean) => async (dispatch: Dispatch) => {
        dispatch({
            type: VisitEnum.SET_IS_VISITED,
            payload: visitedStatus
        })
    }

export const showWarningModalAction =
    (showWarningModal: boolean) => async (dispatch: Dispatch) => {
        dispatch({
            type: VisitEnum.SHOW_WARNING_MODAL,
            payload: showWarningModal
        })
    }
