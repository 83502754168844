import { groupBy } from 'lodash'

import {
    ClientDetailsPayloadInterface,
    TransactionPayloadType
} from 'storeTypes'

export const mergeClientsWithTransactions = (
    clients: ClientDetailsPayloadInterface[],
    transactions: TransactionPayloadType[]
) => {
    const transactionsByClientCode = groupBy(transactions, 'client_code')

    return clients.map((client: ClientDetailsPayloadInterface) => {
        const { client_code: clientCode } = client

        if (transactionsByClientCode[clientCode]) {
            client.smss = transactionsByClientCode[clientCode] as []
        }

        return client
    })
}
