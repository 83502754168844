import { useCallback, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

export const useBackAction = (url: string) => {
    const navigate = useNavigate()
    const hasListenerRef = useRef(false)

    const handleBackButton = useCallback(() => {
        navigate(url)
    }, [navigate, url])

    useEffect(() => {
        if (!hasListenerRef.current) {
            const handlePopState = () => {
                handleBackButton()
            }

            window.history.pushState(null, '', window.location.pathname)
            window.addEventListener('popstate', handlePopState)
            hasListenerRef.current = true

            return () => {
                window.removeEventListener('popstate', handlePopState)
                hasListenerRef.current = false
            }
        }
    }, [handleBackButton])
}
