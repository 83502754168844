import React from 'react'
import PropTypes from 'prop-types'
import './progressBar.css'

export const ProgressBar = ({
    percentage,
    ranges = { high: 90, low: 50 },
    size,
    ...props
}) => {
    const percentageColor =
        parseFloat(percentage) >= ranges.high
            ? 'green'
            : parseFloat(percentage) < ranges.low
            ? 'red'
            : 'yellow'

    return (
        <div
            className={[
                'progressbar-parent',
                `progressbar-rail--${percentageColor}`,
                `progressbar--${size}`
            ].join(' ')}
            {...props}
        >
            <span
                style={{ width: `${percentage}%` }}
                className={[
                    `progressbar-gauge--${percentageColor}`,
                    'progressbar-gauge'
                ].join(' ')}
            ></span>
        </div>
    )
}

ProgressBar.propTypes = {
    percentage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ranges: PropTypes.object,
    size: PropTypes.oneOf(['small', 'large'])
}

ProgressBar.defaultProps = {
    percentage: '20',
    size: 'large'
}
