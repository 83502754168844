import React, { FC } from 'react'

type TitleStepType = {
    id: string
    title: string
    stepTitle: string
    step: number
    totalSteps: number
}

const TitleStep: FC<TitleStepType> = ({
    id,
    title,
    stepTitle,
    step,
    totalSteps
}) => {
    return (
        <p id={id}>
            {title} {step}/{totalSteps}: {stepTitle}
        </p>
    )
}

export default TitleStep
