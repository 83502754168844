/**
 * Search for a value in an array of object
 * Supply the array of object and the search term object
 * @param {Array} arraySearchList
 * @param {Object} searchTerm
 */

export const searchHandler = (arraySearchList, searchTerm) => {
    const searchTerms = Object.entries(searchTerm)

    const searchResult = arraySearchList.filter((searchObj) =>
        searchTerms.find((data) => {
            const [key, value] = data
            return searchObj[key]
                ?.toUpperCase()
                .includes(value.toUpperCase().trim())
        })
    )
    return searchResult
}
