import React, { useEffect, ReactNode } from 'react'
import { Alert } from './Alert'
import { useAlert } from '../../hooks'

interface TimedAlertProps {
    children?: ReactNode
    type?: 'default' | 'floating'
    status?: 'success' | 'error'
}

export const TimedAlert: React.FC<TimedAlertProps> = ({
    children,
    ...props
}) => {
    const [displayAlert, setDisplayAlert] = useAlert(2500)
    useEffect(() => {
        if (children && setDisplayAlert) {
            setDisplayAlert(true)
        }
    }, [children])

    return displayAlert ? <Alert {...props}>{children}</Alert> : <></>
}
