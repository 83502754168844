import React from 'react'
import { t } from 'i18next'

import { ClientStatus as ClientStatusEnum } from '../../storeTypes/clientTypes'

type ClientStatusOptions = {
    status: string
}

const ClientStatus = ({ status }: ClientStatusOptions) => {
    if (Object.values(ClientStatusEnum).includes(status as ClientStatusEnum)) {
        return (
            <h2
                className={`card-client-detail--name card-client-status ${status.toLowerCase()}`}
                data-testid="client-status"
            >
                {`Client ${t(`status.${status.toLowerCase()}`)}`}
            </h2>
        )
    } else {
        return (
            <h2 className="card-client-detail--name card-client-status unknown" />
        )
    }
}

export default ClientStatus
