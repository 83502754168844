import React, { useEffect, useState, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useSelector } from 'react-redux'
import {
    Card,
    Container,
    TopBar,
    Row,
    Col,
    Spinner,
    TimedAlert
} from '../../components'
import { useBindDispatch, useGetStorage } from '../../hooks'
import { amountFormatter, constants } from '../../helpers'
import { useTranslation } from 'react-i18next'

export const CardInventory = () => {
    const [animate, setAnimate] = useState(false)
    const [animationList, setAnimationList] = useState(false)
    const nodeRef = useRef(null)
    const { t } = useTranslation()
    const { cardInventoryHandler } = useBindDispatch()
    const { username: veCode, country } = useGetStorage('connect_user')
    const {
        loading,
        cardInventoryData,
        cardSummary,
        error: cardInventoryError
    } = useSelector((store) => store.cardInventory)
    useEffect(() => {
        setAnimate(true)
        if (cardInventoryData.length < 1) {
            cardInventoryHandler({ veCode })
        }
    }, [])

    const formatPacketId = (packetId) => {
        const newPacketId = packetId.split('-')
        return [newPacketId[2], newPacketId[3]].join('-')
    }

    const cardInventoryRender = (cardInventory) => (
        <CSSTransition
            unmountOnExit
            timeout={constants.ANIMATION_TIMEOUT}
            in={animate}
            classNames="generic"
            appear
            onEnter={() => setAnimationList(true)}
            nodeRef={nodeRef}
        >
            <div className="card-inventory-parent page-wrapper" ref={nodeRef}>
                <Container>
                    <Row>
                        <Col md={12}>
                            <Card
                                shadow
                                data-testid="card-inventory-card"
                                className={
                                    animationList
                                        ? 'animate-show-2 animate-showed-2'
                                        : 'animate-show-2'
                                }
                            >
                                <div className="card-inventory--summary">
                                    <div className="card-inventory--summary-total">
                                        <p className="card-inventory--row">
                                            {t('card.cardReceived')}
                                        </p>
                                        <p
                                            className="card-inventory--row"
                                            data-testid="total-card"
                                        >
                                            {cardSummary?.totalCardReceived}{' '}
                                            {t('cards')}
                                        </p>
                                    </div>
                                    <div className="card-inventory--summary-total">
                                        <p className="card-inventory--row">
                                            {t('card.cardsSold')}
                                        </p>
                                        <p
                                            className="card-inventory--row"
                                            data-testid="total-card-amount"
                                        >
                                            {cardSummary?.totalCardSold}{' '}
                                            {t('cards')}
                                        </p>
                                    </div>
                                    <div className="card-inventory--summary-total">
                                        <p className="card-inventory--row">
                                            {t('card.cardsRemaining')}
                                        </p>
                                        <p
                                            className="card-inventory--row"
                                            data-testid="total-card-amount"
                                        >
                                            {cardSummary?.totalCardRemaining}{' '}
                                            {t('cards')}
                                        </p>
                                    </div>
                                    <div className="card-inventory--summary-total">
                                        <p className="card-inventory--row">
                                            {t('card.totalCardSold')}
                                        </p>
                                        <p
                                            className="card-inventory--row"
                                            data-testid="total-card-amount"
                                        >
                                            {amountFormatter(
                                                cardSummary?.totalCardAmountSold,
                                                country
                                            )}{' '}
                                        </p>
                                    </div>
                                </div>
                                {cardInventory.map(
                                    (
                                        {
                                            amount,
                                            cards_in_packet: cardsInPacket,
                                            date_distributed: dateDistributed,
                                            name
                                        },
                                        idx
                                    ) => (
                                        <div
                                            className="card-inventory"
                                            key={idx}
                                            data-testid="card-inventory"
                                        >
                                            <div className="card-inventory--row">
                                                <p className="card-inventory--top card-inventory--date">
                                                    {t('dateMonthYear', {
                                                        date: new Date(
                                                            dateDistributed
                                                        )
                                                    })}
                                                </p>
                                                <h2 className="card-inventory--bottom card-inventory--amount">
                                                    {amountFormatter(
                                                        amount,
                                                        country
                                                    )}
                                                </h2>
                                            </div>
                                            <div className="card-inventory--row">
                                                <p className="card-inventory--top card-inventory--count">
                                                    {cardsInPacket} {t('cards')}
                                                </p>
                                                <h2 className="card-inventory--bottom card-inventory--packet">
                                                    {formatPacketId(name)}
                                                </h2>
                                            </div>
                                        </div>
                                    )
                                )}
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </CSSTransition>
    )

    const cardInventoryPage = loading ? (
        <Spinner size={'90'} pageSpinner={true} />
    ) : cardInventoryError ? (
        <TimedAlert type="floating" status="error">
            {cardInventoryError}
        </TimedAlert>
    ) : (
        cardInventoryRender(cardInventoryData)
    )

    return (
        <>
            <div className="header-bar" id="header-bar">
                <Container>
                    <TopBar back>{t('card.viewInventory')}</TopBar>
                </Container>
            </div>
            {cardInventoryPage}
        </>
    )
}
