import { Dispatch } from 'redux'
import {
    ClientSearchInterface,
    ClientSearchActionTypes,
    ClientSearchEnum,
    ClientType,
    ClientSearchFilter
} from '../storeTypes'
import { canUseRxDb } from 'helpers/rxdbUtils'
import { sendMessageToWorkerWithResponse } from 'helpers/communication'
import { fetchAllClientList, constants } from 'helpers'
import { getAllClientOrders } from 'helpers/packageSelectionHelpers'

export const clientSearchAction =
    ({ searchValue, villageIds }: ClientSearchInterface) =>
    async (dispatch: Dispatch<ClientSearchActionTypes>) => {
        const allClientList: ClientType[] = []
        dispatch({ type: ClientSearchEnum.FETCHING_CLIENT_SEARCH })

        if (canUseRxDb()) {
            const clients = await fetchAllClientList()
            const clientOrders = await getAllClientOrders()

            if (clients) {
                clients.forEach(async (client) => {
                    const orders = clientOrders.find(
                        (clientOrder: [{ client_code: string }]) =>
                            clientOrder[0].client_code === client.client_code
                    )

                    return allClientList.push({
                        ...client._data,
                        goal_items: orders
                    })
                })
            }
        } else {
            const { storedData: clients } =
                await sendMessageToWorkerWithResponse({
                    'get client data': {
                        clientType: constants.CLIENT_TYPES.ALL_CLIENT,
                        villageIds,
                        filter: ClientSearchFilter.VILLAGE
                    }
                })

            allClientList.push(...clients)
        }
        dispatch({
            type: ClientSearchEnum.FETCH_CLIENT_SEARCH,
            payload: {
                allClientList,
                searchParams: searchValue
            }
        })
    }

export const clearClientSearchAction =
    () => (dispatch: Dispatch<ClientSearchActionTypes>) => {
        dispatch({ type: ClientSearchEnum.CLEAR_CLIENT_SEARCH })
    }
