import React from 'react'
import { withTranslation, TFunction } from 'react-i18next'
import { RadioButton, Col, Container, Row, Button, TextInput } from '../'
import './visitForm.css'
import { visitValidationRules } from '../../helpers'
import { useOnChange, useValidation } from '../../hooks'

export interface VisitFormState {
    isAFarmer: 'yes' | 'no' | ''
    isFarmingThisYear: 'yes' | 'no' | ''
    willFarmNextYear: 'yes' | 'no' | ''
    wantsOtherVisit: 'yes' | 'no' | ''
    notes: string
}

interface VisitFormProps {
    t: TFunction
    handleSubmit: (data: VisitFormState) => void
    disable: boolean
    disabledDuration: number
}

export const VisitForm: React.FC<VisitFormProps> = ({
    t,
    handleSubmit,
    disable,
    disabledDuration = 0
}) => {
    const { inputText, onChangeHandler } = useOnChange({
        isAFarmer: '',
        isFarmingThisYear: '',
        willFarmNextYear: '',
        wantsOtherVisit: '',
        notes: ''
    })

    const validationRules = visitValidationRules()
    const { errors, validateForm, resetError } = useValidation(
        inputText,
        validationRules
    )

    const handleClick = (event: React.FormEvent) => {
        event.preventDefault()
        resetError()
        if (validateForm()) {
            handleSubmit(inputText as VisitFormState)
        }
        return false
    }

    return (
        <Container>
            <Row>
                <Col md={12}>
                    <form onSubmit={handleClick}>
                        <h2 className="visit-header">{t('visit.header')}</h2>
                        <p className="question-title">
                            {t('visit.farmerQuestion')}
                        </p>
                        <div className="question-form-horizontal">
                            <Container>
                                <Row>
                                    <Col md={2} col={6}>
                                        <div className="enrollment-checkbox">
                                            <RadioButton
                                                id="isAFarmerYes"
                                                name="isAFarmer"
                                                value="yes"
                                                data-testid="isAFarmerYes"
                                                checked={
                                                    inputText.isAFarmer ===
                                                    'yes'
                                                }
                                                onChange={onChangeHandler}
                                            />
                                            <label
                                                htmlFor="isAFarmerYes"
                                                className="form-label"
                                            >
                                                {t('yes')}
                                            </label>
                                        </div>
                                    </Col>
                                    <Col md={2} col={6}>
                                        <div className="enrollment-checkbox">
                                            <RadioButton
                                                id="isAFarmerNo"
                                                name="isAFarmer"
                                                value="no"
                                                data-testid="isAFarmerNo"
                                                checked={
                                                    inputText.isAFarmer === 'no'
                                                }
                                                onChange={onChangeHandler}
                                            />
                                            <label
                                                htmlFor="isAFarmerNo"
                                                className="form-label"
                                            >
                                                {t('no')}
                                            </label>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        {errors?.isAFarmer && (
                            <p
                                className="generic--error-message"
                                data-testid="visit-form-error"
                            >
                                {errors.isAFarmer}
                            </p>
                        )}
                        <p className="question-title">
                            {t('visit.farmingThisYearQuestion')}
                        </p>
                        <div className="question-form-horizontal">
                            <Container>
                                <Row>
                                    <Col md={2} col={6}>
                                        <div className="enrollment-checkbox">
                                            <RadioButton
                                                id="isFarmingThisYearYes"
                                                name="isFarmingThisYear"
                                                value="yes"
                                                data-testid="isFarmingThisYearYes"
                                                checked={
                                                    inputText.isFarmingThisYear ===
                                                    'yes'
                                                }
                                                onChange={onChangeHandler}
                                            />
                                            <label
                                                htmlFor="isFarmingThisYearYes"
                                                className="form-label"
                                            >
                                                {t('yes')}
                                            </label>
                                        </div>
                                    </Col>
                                    <Col md={2} col={6}>
                                        <div className="enrollment-checkbox">
                                            <RadioButton
                                                id="isFarmingThisYearNo"
                                                name="isFarmingThisYear"
                                                value="no"
                                                data-testid="isFarmingThisYearNo"
                                                checked={
                                                    inputText.isFarmingThisYear ===
                                                    'no'
                                                }
                                                onChange={onChangeHandler}
                                            />
                                            <label
                                                htmlFor="isFarmingThisYearNo"
                                                className="form-label"
                                            >
                                                {t('no')}
                                            </label>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        {errors?.isFarmingThisYear && (
                            <p
                                className="generic--error-message"
                                data-testid="visit-form-error"
                            >
                                {errors.isFarmingThisYear}
                            </p>
                        )}
                        <p className="question-title">
                            {t('visit.farmingNextYearQuestion')}
                        </p>
                        <div className="question-form-horizontal">
                            <Container>
                                <Row>
                                    <Col md={2} col={6}>
                                        <div className="enrollment-checkbox">
                                            <RadioButton
                                                id="willFarmNextYearYes"
                                                name="willFarmNextYear"
                                                value="yes"
                                                data-testid="willFarmNextYearYes"
                                                checked={
                                                    inputText.willFarmNextYear ===
                                                    'yes'
                                                }
                                                onChange={onChangeHandler}
                                            />
                                            <label
                                                htmlFor="willFarmNextYearYes"
                                                className="form-label"
                                            >
                                                {t('yes')}
                                            </label>
                                        </div>
                                    </Col>
                                    <Col md={2} col={6}>
                                        <div className="enrollment-checkbox">
                                            <RadioButton
                                                id="willFarmNextYearNo"
                                                name="willFarmNextYear"
                                                value="no"
                                                data-testid="willFarmNextYearNo"
                                                checked={
                                                    inputText.willFarmNextYear ===
                                                    'no'
                                                }
                                                onChange={onChangeHandler}
                                            />
                                            <label
                                                htmlFor="willFarmNextYearNo"
                                                className="form-label"
                                            >
                                                {t('no')}
                                            </label>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        {errors?.willFarmNextYear && (
                            <p
                                className="generic--error-message"
                                data-testid="visit-form-error"
                            >
                                {errors.willFarmNextYear}
                            </p>
                        )}
                        <p className="question-title">
                            {t('visit.otherVisitQuestion')}
                        </p>
                        <div className="question-form-horizontal">
                            <Container>
                                <Row>
                                    <Col md={2} col={6}>
                                        <div className="enrollment-checkbox">
                                            <RadioButton
                                                id="wantsOtherVisitYes"
                                                name="wantsOtherVisit"
                                                value="yes"
                                                data-testid="wantsOtherVisitYes"
                                                checked={
                                                    inputText.wantsOtherVisit ===
                                                    'yes'
                                                }
                                                onChange={onChangeHandler}
                                            />
                                            <label
                                                htmlFor="wantsOtherVisitYes"
                                                className="form-label"
                                            >
                                                {t('yes')}
                                            </label>
                                        </div>
                                    </Col>
                                    <Col md={2} col={6}>
                                        <div className="enrollment-checkbox">
                                            <RadioButton
                                                id="wantsOtherVisitNo"
                                                name="wantsOtherVisit"
                                                value="no"
                                                data-testid="wantsOtherVisitNo"
                                                checked={
                                                    inputText.wantsOtherVisit ===
                                                    'no'
                                                }
                                                onChange={onChangeHandler}
                                            />
                                            <label
                                                htmlFor="wantsOtherVisitNo"
                                                className="form-label"
                                            >
                                                {t('no')}
                                            </label>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        {errors?.wantsOtherVisit && (
                            <p
                                className="generic--error-message"
                                data-testid="visit-form-error"
                            >
                                {errors.wantsOtherVisit}
                            </p>
                        )}
                        <p className="question-title">{t('visit.notes')}</p>
                        <TextInput
                            className="notes-input"
                            name="notes"
                            id="notesInput"
                            data-testid="notesInput"
                            onChange={onChangeHandler}
                        ></TextInput>
                        <Button
                            className="visit-form-button"
                            style="primary"
                            type="submit"
                            data-testid="submit-btn"
                            id="submit-btn"
                            disable={disable}
                        >
                            {t('visit.submit')}{' '}
                            {disabledDuration > 0
                                ? `(${disabledDuration})`
                                : ''}
                        </Button>
                    </form>
                </Col>
            </Row>
        </Container>
    )
}

export default withTranslation()(VisitForm)
