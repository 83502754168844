import React from 'react'
import { useTranslation } from 'react-i18next'

import {
    Col,
    Links,
    Row,
    Card,
    Stats,
    ProgressBar,
    Spinner
} from '../../components'
import { amountFormatter, capitalizeWord, constants } from 'helpers'
import { useAppSelector } from 'hooks'

interface VeMetricsProps {
    animationList: boolean
    hideBorder?: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ve?: any
    connectUser: { country: string }
    canViewDeposits: boolean
    canViewDepositGap: boolean
    cardClickHandler: (filterOptions: string[], clientTab: number) => void
}

const isTargetValid = (value: number | null | undefined) => value !== 0 && value

export const VeMetrics = ({
    animationList,
    ve,
    connectUser,
    canViewDeposits,
    canViewDepositGap,
    cardClickHandler
}: VeMetricsProps) => {
    const { loading } = useAppSelector((store) => store.interaction)
    const interactionType = `${constants.FARMER_AWARENESS_SURVEY_NAME}-${constants.FARMER_AWARENESS_SURVEY_VERSION}`
    const loadingInteractions = loading && loading[interactionType]

    const countryConfig = JSON.parse(
        localStorage.getItem('country_config') || '{}'
    )
    const progressBarRange = {
        low: countryConfig?.sales_progress_medium_threshold,
        high: countryConfig?.sales_progress_high_threshold
    }

    const { t } = useTranslation()
    const getMonthYear = capitalizeWord(t('monthYear', { date: new Date() }))
    const { VILLAGE_PAGE_TABS } = constants

    const {
        target_monthly_sales: targetMonthlySales,
        sales_this_month: salesThisMonth,
        target_clients_active_this_month: targetClientsActiveThisMonth,
        clients_enrolled_this_month: clientsEnrolledThisMonth,
        target_monthly_enrolled: targetMonthlyEnrolled,
        clients_active_this_month: clientsActiveThisMonth,
        target_weekly_visited: targetWeeklyVisited,
        visits_this_week: visitsThisWeek,
        total_deposit: totalDeposit,
        gap: remainingDeposit,
        total_clients_registered_this_week: totalClientsRegisteredThisWeek,
        target_clients_registered_this_week: targetClientsRegisteredThisWeek
    } = ve || {
        target_monthly_sales: '',
        sales_this_month: '',
        target_clients_active_this_month: '',
        clients_enrolled_this_month: '',
        target_monthly_enrolled: '',
        clients_active_this_month: '',
        target_weekly_visited: '',
        visits_this_week: '',
        total_deposit: '',
        gap: ''
    }

    const hideBorder = !canViewDeposits && !canViewDepositGap

    const remainingSales =
        parseFloat(targetMonthlySales) - parseFloat(salesThisMonth)
    const salesPercentage = (
        (parseFloat(salesThisMonth) / parseFloat(targetMonthlySales)) * 100 || 0
    ).toFixed(0)
    const isTargetMonthlySalesValid = isTargetValid(targetMonthlySales)

    if (loadingInteractions) {
        return (
            <div className="target-list--spinner-parent">
                <Spinner
                    data-testid="loading-indicator"
                    size="50"
                    pageSpinner={false}
                    fullscreen={false}
                />
            </div>
        )
    }

    return (
        <Row>
            <Col md={12} className="card-session">
                <h2 className="session-header header-welcome">
                    {t('home.performance')}
                </h2>
            </Col>
            <Col md={8}>
                <Card
                    className={
                        animationList
                            ? 'performance-card-parent animate-show-2 animate-showed-2'
                            : 'animate-show-2'
                    }
                    shadow
                >
                    <div
                        className="card-sale-parent"
                        style={{
                            border: hideBorder ? '' : 'none'
                        }}
                    >
                        <div className="card-sale">
                            <h2 className="card-sale--header">
                                {t('home.sales')}{' '}
                                <span className="card-sale--duration">
                                    {t('of')} {getMonthYear}
                                </span>
                            </h2>
                            {isTargetMonthlySalesValid && (
                                <Stats
                                    className=""
                                    data-testid="sales-percentage"
                                >
                                    {salesPercentage}
                                </Stats>
                            )}
                        </div>
                        <div className="card-progressbar">
                            <ProgressBar
                                percentage={salesPercentage}
                                size="large"
                                ranges={progressBarRange}
                            />
                        </div>
                        <div className="progressbar-numbers">
                            <h1 className="progressbar-numbers--amount">
                                {amountFormatter(
                                    salesThisMonth,
                                    connectUser.country
                                )}{' '}
                            </h1>
                            {isTargetMonthlySalesValid && (
                                <p className="progressbar-numbers--total">
                                    <span className="progressbar-numbers--of">
                                        {t('on')}
                                    </span>{' '}
                                    {amountFormatter(
                                        targetMonthlySales,
                                        connectUser.country
                                    )}{' '}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="sales-deposit-parent">
                        {isTargetMonthlySalesValid && (
                            <div className="sales-deposit">
                                <p>{t('home.remainingSales')}</p>
                                <p data-testid="remaining-sales">
                                    {amountFormatter(
                                        remainingSales > 0 && remainingSales,
                                        connectUser.country
                                    )}{' '}
                                </p>
                            </div>
                        )}
                        {canViewDeposits && (
                            <div className="sales-deposit">
                                <Links
                                    className="deposits-link"
                                    to="deposits"
                                    id="deposits-link"
                                    role="deposit-link"
                                >
                                    {t('home.deposit')}
                                </Links>
                                <p>
                                    {amountFormatter(
                                        totalDeposit > 0 && totalDeposit,
                                        connectUser.country
                                    )}{' '}
                                </p>
                            </div>
                        )}
                        {canViewDepositGap && (
                            <div
                                className="sales-deposit"
                                title="sales-deposit"
                            >
                                <p>{t('home.remainingDeposit')}</p>
                                <p>
                                    {amountFormatter(
                                        remainingDeposit,
                                        connectUser.country
                                    )}{' '}
                                </p>
                            </div>
                        )}
                    </div>
                </Card>
            </Col>
            <Col md={4}>
                <Row className="client-row">
                    <Col md={12} col={6} className="client-card-parent">
                        <Card
                            className={
                                animationList
                                    ? 'client-card animate-show-3 animate-showed-3'
                                    : 'client-card animate-show-3'
                            }
                            shadow
                            onClick={() =>
                                cardClickHandler(
                                    [t('village.active')],
                                    VILLAGE_PAGE_TABS.CURRENT_CLIENT_TAB
                                )
                            }
                            data-testid="client-active-card"
                            id="active-client-card"
                        >
                            <h2 className="client-card--header">
                                {t('home.activeCustomers')}{' '}
                                <span className="client-card--light-italic">
                                    {t('home.thisMonth')}
                                </span>
                            </h2>
                            <div className="client-number">
                                <h1 className="client-number--content client-number--num">
                                    {clientsActiveThisMonth || 0}
                                </h1>
                                {isTargetValid(
                                    targetClientsActiveThisMonth
                                ) && (
                                    <>
                                        <p className="client-number--content client-number--others client-number--of">
                                            {t('on')}
                                        </p>
                                        <p className="client-number--content client-number--others client-number--den">
                                            {targetClientsActiveThisMonth}
                                        </p>
                                    </>
                                )}
                            </div>
                        </Card>
                    </Col>
                    <Col md={12} col={6} className="client-card-parent">
                        <Card
                            className={
                                animationList
                                    ? 'client-card second-client-card animate-show-4 animate-showed-4'
                                    : 'client-card second-client-card animate-show-4'
                            }
                            shadow
                            onClick={() =>
                                cardClickHandler(
                                    [
                                        t('village.active'),
                                        t('village.inactive')
                                    ],
                                    VILLAGE_PAGE_TABS.CURRENT_CLIENT_TAB
                                )
                            }
                            data-testid="client-enrolled-card"
                            id="registered-client-card"
                        >
                            <h2 className="client-card--header">
                                {t('home.toRegister')}{' '}
                                <span className="client-card--light-italic">
                                    {t('home.thisMonth')}
                                </span>
                            </h2>
                            <div className="client-number">
                                <h1 className="client-number--content client-number--num">
                                    {clientsEnrolledThisMonth || 0}
                                </h1>
                                {isTargetValid(targetMonthlyEnrolled) && (
                                    <>
                                        <p className="client-number--content client-number--others client-number--of">
                                            {t('on')}
                                        </p>
                                        <p className="client-number--content client-number--others client-number--den">
                                            {targetMonthlyEnrolled}
                                        </p>
                                    </>
                                )}
                            </div>
                        </Card>
                    </Col>
                    {
                        <Col md={12} col={6} className="client-card-parent">
                            <Card
                                className={
                                    animationList
                                        ? 'client-card second-client-card animate-show-4 animate-showed-4'
                                        : 'client-card second-client-card animate-show-4'
                                }
                                shadow
                                onClick={() =>
                                    cardClickHandler(
                                        [t('village.visitedThisWeek')],
                                        VILLAGE_PAGE_TABS.PREVIOUS_CLIENT_TAB
                                    )
                                }
                                data-testid="visited-client-card"
                                id="visited-client-card"
                            >
                                <h2 className="client-card--header">
                                    {t('home.potentialClientsVisited')}{' '}
                                    <span className="client-card--light-italic">
                                        {t('home.thisWeek')}
                                    </span>
                                </h2>
                                <div className="client-number">
                                    <h1 className="client-number--content client-number--num">
                                        {visitsThisWeek || 0}
                                    </h1>
                                    {isTargetValid(targetWeeklyVisited) && (
                                        <>
                                            <p className="client-number--content client-number--others client-number--of">
                                                {t('on')}
                                            </p>
                                            <p className="client-number--content client-number--others client-number--den">
                                                {targetWeeklyVisited}
                                            </p>
                                        </>
                                    )}
                                </div>
                            </Card>
                        </Col>
                    }
                    {
                        <Col md={12} col={6} className="client-card-parent">
                            <Card
                                className={
                                    animationList
                                        ? 'client-card second-client-card animate-show-4 animate-showed-4'
                                        : 'client-card second-client-card animate-show-4'
                                }
                                shadow
                                onClick={() =>
                                    cardClickHandler(
                                        [t('village.active')],
                                        VILLAGE_PAGE_TABS.PREVIOUS_CLIENT_TAB
                                    )
                                }
                                data-testid="registered-client-card"
                                id="registered-client-card"
                            >
                                <h2 className="client-card--header">
                                    {t('home.newlyVisited')}{' '}
                                    <span className="client-card--light-italic">
                                        {t('home.thisWeek')}
                                    </span>
                                </h2>
                                <div className="client-number">
                                    <h1 className="client-number--content client-number--num">
                                        {totalClientsRegisteredThisWeek || 0}
                                    </h1>
                                    {isTargetValid(
                                        targetClientsRegisteredThisWeek
                                    ) && (
                                        <>
                                            <p className="client-number--content client-number--others client-number--of">
                                                {t('on')}
                                            </p>
                                            <p className="client-number--content client-number--others client-number--den">
                                                {
                                                    targetClientsRegisteredThisWeek
                                                }
                                            </p>
                                        </>
                                    )}
                                </div>
                            </Card>
                        </Col>
                    }
                </Row>
            </Col>
        </Row>
    )
}
