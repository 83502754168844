import React from 'react'
import { useTranslation } from 'react-i18next'
import { HappyFarmer } from 'assets'

interface SuccessMessageProps {
    isClientRegistration: boolean
    firstName: string
}

export const SuccessMessage = ({ isClientRegistration, firstName }: SuccessMessageProps) => {
    const { t } = useTranslation()
    const successMessageKey = isClientRegistration ? 'successClientRegistration' : 'successClientEnrollment'

    return (
        <div className="client-details--success-modal">
            <div className="client-details--success-modal-header">
                <p className="client-details--success-modal-title">
                    {t('cardPayment.successful')}!
                </p>
            </div>
            <div className="client-details--success-modal-icon">
                {isClientRegistration ? (
                    <HappyFarmer
                        primaryColor="#A96778"
                        secondaryColor="#A9677880"
                    />
                ) : (
                    <HappyFarmer />
                )}
            </div>
            <div className="client-details--success-modal-text">
                <p className="client-details--success-modal-message">
                    {t(`clientDetails.${successMessageKey}`, {
                        firstName
                    })}
                </p>
            </div>
        </div>
    )
}
