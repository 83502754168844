import { useRef } from 'react'
import { useMatomo } from '@jonkoops/matomo-tracker-react'

type TimedEvent = {
    category: string
    action: string
}

export const useTrackTimedEvent = () => {
    const startTime = useRef(Date.now())
    const { trackEvent } = useMatomo()

    const trackTimedEvent = ({ category, action }: TimedEvent) => {
        const endTime = Date.now()
        const duration = endTime - startTime.current

        trackEvent({ category, action, value: duration })
    }

    return { trackTimedEvent }
}
