export const getUserLocation = (callback, errorCallback) => {
    if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(callback, errorCallback)
    }
}

const filterGoalItems = (newItems, oldItems, formType) => {
    return [
        ...newItems,
        ...oldItems.filter((item) =>
            formType !== 'modification'
                ? item.delivered && item.season_active
                : item.delivered || item.ve_delivered
        )
    ]
}

const formatGoalItemsPerType = (oldGoalItems, newGoalItems) => {
    return {
        enrollment: newGoalItems,
        modification:
            oldGoalItems &&
            filterGoalItems(newGoalItems, oldGoalItems, 'modification'),
        're-enrollment':
            oldGoalItems &&
            filterGoalItems(newGoalItems, oldGoalItems, 're-enrollment')
    }
}

export const formatPayloadToClientObject = (payload) => {
    const goalItems = []
    let allEnrollment = 0
    payload?.packets?.forEach((packet) => {
        const goalItem = {
            desired_units: packet.size?.toString(),
            desired_units_display: packet.size_display?.toString(),
            price: packet.price,
            hide_price: packet.hidePrice,
            unit: packet.unit,
            product: {
                id: packet.product_id,
                name: typeof packet.name !== 'undefined' ? packet.name : ''
            },
            uuid: packet.uuid
        }

        goalItems.push(goalItem)
        // the plus in front of the packet.price converts any string price to number data type
        allEnrollment += +packet.price
    })
    // Update payload fields
    payload.sf_id = payload.client_id ? payload.client_id : payload.sf_id
    payload.fullname = `${payload.firstname || ''} ${payload.lastname || ''}`
    payload.certified_for_delivery = 'False'
    payload.all_enrollment = allEnrollment
    payload.goal_items = formatGoalItemsPerType(payload.goal_items, goalItems)[
        payload.form_type
    ]
    return payload
}
