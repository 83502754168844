import React from 'react'
import { Spinner } from 'components'
import { useTranslation } from 'react-i18next'

export const PartialClientLoader = () => {
    const { t } = useTranslation()
    return (
        <div>
            <Spinner size="60" pageSpinner={false} fullscreen={false} />
            <p className="partial-loader--text">
                {t('village.partialLoaderMsg')}
            </p>
        </div>
    )
}
