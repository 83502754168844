import axios from 'axios'

// This function fetches product pictures from the server for the service worker to cache them
export const fetchProductPictures = async (pictureURLs: string[]) => {
    if (pictureURLs) {
        const uniqueURLs = Array.from(new Set(pictureURLs))
        uniqueURLs.forEach((url) => {
            if (url) axios.get(url)
        })
    }
}
