import { t } from 'i18next'
import { amountFormatter } from './amountFormatter'
import { ZoneInfoTemplate } from '../components/enrollForm/enrollFormTypes'

type TitleVarSummaryType = { title: string; variable: string }

type Summary = {
    zone: TitleVarSummaryType
    village: TitleVarSummaryType
    headOfHousehold: TitleVarSummaryType
    groupLeader: TitleVarSummaryType
    gender: TitleVarSummaryType
    phone: TitleVarSummaryType
    reimbursementChoice: TitleVarSummaryType
    mobileMoneyPhone: TitleVarSummaryType
    formType: 'modification' | 'enrollment' | 're-enrollment'
}

type PaymentSummaryType = {
    [x: string]: string
}

type ClientData = {
    village_name: string
    villageName: string
    head_of_household: string
    group_leader: string
    phone_2: string
    gender: string
    sex: string
    reimbursement_choice: string
    mobile_money_phone: string
    form_type: 'modification' | 'enrollment' | 're-enrollment'
}

export const mapClientDataToSummary = (
    zoneProductsInfo: ZoneInfoTemplate,
    currentClient: ClientData
): Summary => {
    return {
        zone: { title: t('zone'), variable: zoneProductsInfo.zone_name || '' },
        village: {
            title: t('village.village'),
            variable: currentClient?.village_name || currentClient?.villageName
        },
        headOfHousehold: {
            title: t('enrollment.headOfHousehold'),
            variable: currentClient?.head_of_household
        },
        groupLeader: {
            title: t('enrollment.groupLeader'),
            variable: currentClient?.group_leader
        },
        gender: {
            title: t('gender'),
            variable: currentClient?.sex || currentClient?.gender
        },
        phone: {
            title: t('enrollment.phone2'),
            variable: currentClient?.phone_2
        },
        reimbursementChoice: {
            title: t('enrollment.reimbursementChoice'),
            variable: currentClient?.reimbursement_choice
        },
        mobileMoneyPhone: {
            title: t('enrollment.mobileMoneyPhone'),
            variable: currentClient?.mobile_money_phone
        },
        formType: currentClient?.form_type
    }
}

export const getDemographicSummary = ({
    zone,
    village,
    headOfHousehold,
    groupLeader,
    gender,
    phone,
    reimbursementChoice,
    mobileMoneyPhone,
    formType
}: Summary) => {
    const summary = {
        [zone.title]: zone.variable,
        [village.title]: village.variable,
        [headOfHousehold.title]: headOfHousehold.variable ? t('yes') : t('no'),
        [groupLeader.title]: groupLeader.variable ? t('yes') : t('no'),
        [gender.title]: gender.variable === 'Male' ? t('man') : t('woman'),
        [phone.title]: phone.variable
    }
    if (formType === 'modification') {
        const reimbursementChoiceType =
            reimbursementChoice.title as unknown as keyof typeof summary
        const mobileMoneyPhoneType =
            mobileMoneyPhone.title as unknown as keyof typeof summary

        summary[reimbursementChoiceType] =
            reimbursementChoice.variable === 'Rollover'
                ? t('enrollment.toBeRollovered')
                : t('enrollment.toBeReimbursed')
        if (reimbursementChoice.variable === 'Reimbursement') {
            summary[mobileMoneyPhoneType] = mobileMoneyPhone.variable
        }
    }
    return summary
}

export const getPaymentSummary = (
    totalTitle: string,
    initialPayment: string,
    voucherCode: string,
    totalToPay: number,
    country: string
): PaymentSummaryType => {
    const initialPaymentTitle = t('initialPayment')
    if (voucherCode) {
        const card = t('voucher')
        return {
            [totalTitle]: amountFormatter(totalToPay, country),
            [card]: voucherCode,
            [initialPaymentTitle]:
                amountFormatter(initialPayment, country) ||
                amountFormatter(2000, country)
        }
    } else {
        return {
            [totalTitle]: amountFormatter(totalToPay, country),
            [initialPaymentTitle]:
                amountFormatter(initialPayment, country) ||
                amountFormatter(2000, country)
        }
    }
}
