// configure expiration timer
export const setTimeByMinute = (minutes) => {
    const currentTime = new Date()
    currentTime.setMinutes(currentTime.getMinutes() + minutes)
    return currentTime.getTime()
}

export const setDaysTime = (days) => days * 24 * 60 * 60 * 1000

export const isDateExpired = (productEndDate) => {
    const currentDate = new Date().getTime()
    const productExpiredDate = new Date(productEndDate).getTime()
    const daysToExpire = Math.floor(currentDate - productExpiredDate)
    const ONE_DAY = 60 * 60 * 24 * 1000
    return ONE_DAY < daysToExpire
}
