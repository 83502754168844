import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useBindDispatch, useGetStorage } from '../../hooks'
import { Container, Row, Col, CardVillage, Card } from '../../components'
import './village.css'
import { constants } from 'helpers'

export const VillageSelection = ({ veVillages }) => {
    veVillages = veVillages || useGetStorage(constants.CONNECT_USER).veVillages
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { openVillagePageData } = useSelector(
        (state) => state.openVillagePage
    )
    const { url } = useSelector((state) => state.villageSelectionInfo)
    const { totalClientObj } = useSelector(
        (state) => state.multipleVillagesCount
    )
    const {
        openVillagePageHandler,
        clearVillageSelectionInfoHandler,
        handleMultipleVillagesCountHandler
    } = useBindDispatch()

    useEffect(() => {
        handleMultipleVillagesCountHandler(
            veVillages.map((village) => village.sf_id)
        )

        return () => {
            clearVillageSelectionInfoHandler()
        }
    }, [])

    const villageSelectionHandler = (sfId) => {
        if (
            openVillagePageData &&
            Object.keys(openVillagePageData).length < 1
        ) {
            const updatedVillageData = {
                ...openVillagePageData,
                villageId: sfId
            }
            openVillagePageHandler(updatedVillageData)
        }

        if (url) {
            if (typeof url === 'function') {
                return navigate(url(sfId))
            }
            return navigate(`${url}${sfId}`)
        }
        navigate(`/village/${sfId}`)
    }

    return (
        <div className="dual-village">
            <Container>
                <Row className="dual-village--header-parent">
                    <Col md={12}>
                        <h2 className="header-welcome dual-village--header">
                            {t('village.chooseVillage')}
                        </h2>
                    </Col>
                </Row>
                <Row>
                    {veVillages.map(({ name, sf_id: sfID }, idx) => (
                        <Col
                            col={12}
                            md={6}
                            className="dual-village--section"
                            key={idx}
                            data-testid="village-card"
                        >
                            <Card
                                onClick={() => villageSelectionHandler(sfID)}
                                className="village-card"
                                data-testid="village-card-selection"
                            >
                                <CardVillage
                                    clientNumbers={{
                                        potentialClients:
                                            totalClientObj[sfID]?.prevClient ||
                                            0,
                                        clients:
                                            totalClientObj[sfID]
                                                ?.currentClient || 0
                                    }}
                                >
                                    {name}
                                </CardVillage>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    )
}

VillageSelection.propTypes = {
    veVillages: PropTypes.array
}
