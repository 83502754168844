import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import './menu.css'
import { Alert } from '../../components'
import { t } from 'i18next'
import { closeIcon } from '../../assets'

export const MenuChild = ({
    children,
    setActive,
    idx,
    active,
    icon,
    isDesktop,
    href,
    onClickCallback,
    path,
    disableActive = false,
    menuOption,
    setDisplayOpt,
    displayOpt,
    isVisible: { isVisible, idx: id },
    ...props
}) => {
    const location = useLocation()
    const navigate = useNavigate()
    const activePath = location.pathname.split('/')[1]
    let prevKey = 0
    const [errorMsg, setErrorMsg] = useState(false)
    useEffect(() => {
        if (activePath === path) {
            setActiveMenu(idx)
        }
    }, [location])
    // handle set active menu
    const setActiveMenu = (key) => {
        setActive((prevState) => {
            prevKey = prevState.key
            return {
                status: true,
                key,
                prevKey: prevState.key
            }
        })
        // persist active menu
        sessionStorage.setItem(
            'activeMenu',
            JSON.stringify({ status: true, key, prevKey })
        )
    }

    const handleSetActiveLink = (idx) => {
        !disableMenuChild && setActiveMenu(idx)
    }
    // retrieve active menu
    const activeStore = JSON.parse(sessionStorage.getItem('activeMenu')) || {
        status: true,
        key: 0
    }
    const activeStyle =
        activeStore.key === idx && !disableActive ? 'menu-child--active' : ''
    const disableMenuChild = !isVisible && id === idx
    const desktopChild = isDesktop ? 'desktop-menu-child' : ''
    const pageNavigationHandler = () => {
        if (disableMenuChild) {
            setErrorMsg(true)
            return setTimeout(() => setErrorMsg(false), 2500)
        }
        if (onClickCallback) {
            onClickCallback()
        } else {
            navigate(href || '#')
        }
        // handle and restore prev active Menu
        const handlePrevMenu = () => {
            setActiveMenu(active.prevKey)
            return ''
        }
        if (menuOption) {
            setDisplayOpt((prevState) => ({
                key: prevState?.key === idx ? handlePrevMenu() : idx
            }))
            return
        }
        setDisplayOpt({ key: '' })
    }

    return (
        <div
            onClick={pageNavigationHandler}
            className={'menu-child--link'}
            role="menu-child"
        >
            <div
                className={[
                    desktopChild,
                    'menu-child',
                    `${!disableMenuChild && activeStyle}`,
                    `${disableMenuChild && 'menu-child--disable'}`
                ]
                    .join(' ')
                    .trim()}
                onClick={() => handleSetActiveLink(idx)}
                {...props}
                role="menu-child-link"
            >
                <>
                    <span className="desktop-menu-icon">{icon}</span>
                    <p className="menu-content">{children}</p>
                </>
            </div>
            {displayOpt.key === idx && (
                <div className="menu-option-parent">{menuOption}</div>
            )}
            {errorMsg && (
                <div data-testid="error-msg">
                    <Alert
                        type="floating"
                        status="error"
                        icon={true}
                        iconType={closeIcon({
                            color: 'red'
                        })}
                    >
                        {t('permission.noPermission')}
                    </Alert>
                </div>
            )}
        </div>
    )
}

MenuChild.propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.any,
    setActive: PropTypes.func,
    idx: PropTypes.number,
    active: PropTypes.object,
    icon: PropTypes.element,
    isDesktop: PropTypes.bool,
    href: PropTypes.string,
    onClickCallback: PropTypes.func,
    path: PropTypes.string,
    disableActive: PropTypes.bool,
    menuOption: PropTypes.any,
    setDisplayOpt: PropTypes.func,
    displayOpt: PropTypes.object,
    isVisible: PropTypes.object
}
