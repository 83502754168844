/* eslint-disable no-useless-escape */
import connectApi from '../api/connectApi'
import { ActionTypes } from '../actions'
import {
    constants,
    sendMessageToWorkerWithResponse,
    internetConnect,
    sendMessageToWorker,
    fetchClientIndexDbClientList,
    canUseRxDb,
    partitionClients,
    bulkInsertClients
} from '../helpers'
import { groupBy } from 'lodash'
import {
    hasLocalDataExpired,
    updateLocalDataExpirationTime
} from '../datastores/LocalDataExpiration'
import * as Database from 'database/Database'

const dispatchClientList = (
    clientData = [],
    prevClientData = [],
    villageId,
    dispatch
) => {
    // fetch new client data
    const enrich = (clients) =>
        clients.map((client) => ({
            ...client,
            village_id: villageId
        }))

    dispatch({
        type: ActionTypes.FETCH_CLIENT_LIST_DATA,
        payload: enrich(clientData),
        villageId
    })
    dispatch({
        type: ActionTypes.FETCH_PREVIOUS_CLIENT_LIST,
        payload: enrich(prevClientData),
        villageId
    })
}

export const refreshVillageClientListAction =
    ({ villageId, clients }) =>
    async (dispatch) => {
        if (canUseRxDb()) {
            const storedFetchedClientData = await bulkInsertClients([
                ...clients
            ])
            const clientsByVillageId = groupBy(
                storedFetchedClientData,
                '_data.village_id'
            )
            const { currentClients: client, previousClients: previous } =
                partitionClients(clientsByVillageId[villageId] || [])
            const clientListData = client.map((item) => item._data)
            const prevClientListData = previous.map((item) => item._data)

            dispatchClientList(
                clientListData,
                prevClientListData,
                villageId,
                dispatch
            )
        } else {
            const clientsByVillageId = await sendMessageToWorkerWithResponse({
                'send client list data': {
                    villageId,
                    clients
                }
            })
            const { client, previous } = clientsByVillageId[villageId]

            dispatchClientList(client, previous, villageId, dispatch)
        }
    }

const storeVillageClientList = (villages, dispatchAction, dispatch) => {
    const { ALL_CLIENT_LIST } = constants.endpoints
    let allFetchResult = {}

    if (canUseRxDb()) {
        fetchClientIndexDbClientList(
            dispatchClientList,
            dispatch,
            villages[0]?.sf_id
        ).then((storedFetchedClientData) => {
            const clientsByVillageId = groupBy(
                storedFetchedClientData,
                '_data.village_id'
            )
            if (dispatchAction) {
                for (const [villageId, clients] of Object.entries(
                    clientsByVillageId
                )) {
                    const {
                        currentClients: client,
                        previousClients: previous
                    } = partitionClients(clients || [])
                    dispatchClientList(
                        client.map((item) => item._data),
                        previous.map((item) => item._data),
                        villageId,
                        dispatch
                    )
                }
            }
        })
    } else {
        villages.forEach((village) => {
            connectApi
                .get(ALL_CLIENT_LIST, {
                    params: {
                        village: village.sf_id
                    }
                })
                .then(async (response) => {
                    updateLocalDataExpirationTime(
                        constants.CLIENT_LIST_EXPIRATION_NAME,
                        village.sf_id,
                        constants.CLIENT_LIST_EXPIRATION_DURATION
                    )

                    const clientData = await sendMessageToWorkerWithResponse({
                        'send client list data': {
                            villageId: village.sf_id,
                            clients: response.data
                        }
                    })

                    allFetchResult = {
                        ...allFetchResult,
                        ...clientData
                    }

                    if (dispatchAction) {
                        for (const [
                            villageId,
                            { client, previous }
                        ] of Object.entries(allFetchResult)) {
                            dispatchClientList(
                                client,
                                previous,
                                villageId,
                                dispatch
                            )
                        }
                    }
                })
        })
    }
}

export const storeVillageClientListAction =
    ({ villages, dispatchAction = true }) =>
    async (dispatch) => {
        const isInternetConnection = internetConnect()

        if (!isInternetConnection) {
            return
        }

        if (dispatchAction) {
            dispatch({ type: ActionTypes.FETCHING_BG_CLIENT_LIST })
        }

        try {
            storeVillageClientList(villages, dispatchAction, dispatch)
        } catch ({ response }) {
            dispatch({
                type: ActionTypes.FAIL_REQUEST,
                payload: response?.data?.detail
            })
        }
    }

// get both client and previous client data
export const villageClientListAction =
    ({ villageId }) =>
    async (dispatch) => {
        const isInternetConnection = internetConnect()
        let clientListData = []
        let prevClientListData = []

        const fetchFromIndexStore = async () => {
            // get client data from indexDb
            const storedClientData = await sendMessageToWorkerWithResponse({
                'get client data': {
                    villageIds: [villageId],
                    clientType: constants.CLIENT_TYPES.CURRENT_CLIENT,
                    filter: 'village'
                }
            })
            // get previous client data from indexDb
            const storedPrevClientData = await sendMessageToWorkerWithResponse({
                'get client data': {
                    villageIds: [villageId],
                    clientType: constants.CLIENT_TYPES.PREVIOUS_CLIENT,
                    filter: 'village'
                }
            })
            return [
                storedClientData.storedData,
                storedPrevClientData.storedData
            ]
        }

        if (canUseRxDb()) {
            const storedFetchedClientData = await fetchClientIndexDbClientList(
                dispatchClientList,
                dispatch,
                villageId
            )
            const clientsByVillageId = groupBy(
                storedFetchedClientData,
                '_data.village_id'
            )
            const { currentClients: client, previousClients: previous } =
                partitionClients(clientsByVillageId[villageId] || []) || {
                    client: [],
                    previous: []
                }
            clientListData = client.map((item) => item._data)
            prevClientListData = previous.map((item) => item._data)
        } else {
            const [clientData, prevClientData] = await fetchFromIndexStore()
            clientListData = clientData
            prevClientListData = prevClientData
        }

        try {
            if (clientListData.length > 0 || prevClientListData.length > 0) {
                dispatchClientList(
                    [...(clientListData || [])],
                    [...(prevClientListData || [])],
                    villageId,
                    dispatch
                )
            } else {
                dispatch({ type: ActionTypes.FETCHING_VILLAGE_DATA })
            }
            const { veVillages } = JSON.parse(
                localStorage.getItem('connect_user')
            )

            if (
                hasLocalDataExpired(
                    constants.CLIENT_LIST_EXPIRATION_NAME,
                    villageId
                ) &&
                isInternetConnection
            ) {
                // fetch new data
                // get client list data
                dispatch(
                    storeVillageClientListAction({
                        villages: veVillages,
                        dispatchAction: false
                    })
                )
            }
        } catch ({ response }) {
            dispatch({
                type: ActionTypes.FAIL_REQUEST,
                payload: response?.data?.detail
            })
        }
    }

export const selectVillageClientAction =
    ({ clientId, villageId }) =>
    (dispatch) => {
        dispatch({
            type: ActionTypes.SELECT_VILLAGE_CLIENT,
            payload: { clientId, villageId }
        })
    }

export const resetSelectVillageClientAction = () => (dispatch) => {
    dispatch({ type: ActionTypes.RESET_SELECT_VILLAGE_CLIENT })
}

export const selectVillagePrevClientAction =
    ({ clientId, villageId }) =>
    (dispatch) => {
        dispatch({
            type: ActionTypes.PREV_SELECT_VILLAGE_CLIENT,
            payload: { clientId, villageId }
        })
    }

export const selectedClientAction =
    ({ clientData, clientType }) =>
    (dispatch) => {
        dispatch({
            type:
                clientType === 'current'
                    ? ActionTypes.SELECTED_CLIENT
                    : ActionTypes.SELECTED_PREV_CLIENT,
            payload: clientData
        })
    }

export const openVillagePageAction =
    ({ filterOptions, villageId, clientTab }) =>
    (dispatch) => {
        dispatch({
            type: ActionTypes.OPENING_VILLAGE_PAGE,
            payload: { filterOptions, villageId, clientTab }
        })
    }

export const markDeliveredAction =
    ({ payload }) =>
    async (dispatch) => {
        dispatch({ type: ActionTypes.MARKING_GOAL_ITEM_DELIVERED })
        const { MARK_DELIVERED } = constants.endpoints
        await connectApi.post(MARK_DELIVERED, {
            ...payload
        })

        const localClientPackages = JSON.parse(
            localStorage.getItem(constants.SINGLE_CLIENT_ORDERS_KEY)
        )

        // update client details local storage

        localClientPackages.forEach(({ uuid }, idx) => {
            if (uuid === payload.goalitem_ids[0].uuid) {
                localClientPackages[idx].delivered = true
            }
        })

        const database = await Database.get()
        const client = await database?.clients
            ?.findOne({
                selector: { client_code: payload.client_code }
            })
            .exec()

        if (client && localClientPackages?.every((order) => order.delivered)) {
            client.patch({
                balance: client.balance - client.all_enrollment,
                all_enrollment: 0
            })
        }

        localStorage.setItem(
            constants.SINGLE_CLIENT_ORDERS_KEY,
            JSON.stringify(localClientPackages)
        )
        dispatch({
            type: ActionTypes.MARKED_GOAL_ITEM_DELIVERED,
            delivered: true
        })
    }

export const preCertifyClientAction =
    ({ payload }) =>
    async (dispatch) => {
        dispatch({ type: ActionTypes.SUBMITTING_PRE_CERTIFY })

        const { PRE_CERTIFY } = constants.endpoints

        try {
            await connectApi.post(PRE_CERTIFY, { ...payload })
            // update client details local storage
            const localClientDetail =
                JSON.parse(localStorage.getItem('client_details')) ?? []
            localClientDetail.certified_for_delivery = 'True'
            // update client details local storage
            localStorage.setItem(
                'client_details',
                JSON.stringify(localClientDetail)
            )

            dispatch({
                type: ActionTypes.SUBMITTED_PRE_CERTIFY,
                payload: true
            })
        } catch (error) {}
    }

export const villageSelectionInfoAction =
    ({ url }) =>
    (dispatch) => {
        dispatch({
            type: ActionTypes.SET_SELECTION_VILLAGE_URL,
            payload: { url }
        })
    }

export const storeOrdersAction = (villages) => async (dispatch) => {
    const { PACKAGES_ENDPOINT } = constants.endpoints
    const { FAIL_FETCH_ORDERS } = ActionTypes
    try {
        const separator = '&village_ids='

        const internetConnection = internetConnect()
        const villageToUpdate = []
        villages.forEach((village) => {
            const isDataExpired = hasLocalDataExpired(
                constants.CLIENT_LIST_EXPIRATION_NAME,
                village.sf_id
            )
            if (isDataExpired) {
                villageToUpdate.push(village.sf_id)
            }
        })
        const villageToUpdateString = villageToUpdate.join(separator)
        const params = `?village_ids=${villageToUpdateString}`

        if (!internetConnection || villageToUpdate.length === 0) return

        const response = await connectApi.get(`${PACKAGES_ENDPOINT}${params}`)

        if (response.status === 200) {
            villageToUpdate.forEach((village) =>
                updateLocalDataExpirationTime(
                    constants.CLIENT_LIST_EXPIRATION_NAME,
                    village
                )
            )
            sendMessageToWorker({
                'store client orders': response.data.data
            })
        }
    } catch (error) {
        dispatch({ type: FAIL_FETCH_ORDERS, payload: error.message })
    }
}
export const fetchClientOrdersAction = (clientCode) => async (dispatch) => {
    const { FAIL_FETCH_ORDERS, FETCH_ORDERS } = ActionTypes
    try {
        const { result } = await sendMessageToWorkerWithResponse({
            'get client orders': clientCode
        })
        const clientOrders = result
        dispatch({ type: FETCH_ORDERS, payload: clientOrders })
    } catch (error) {
        dispatch({ type: FAIL_FETCH_ORDERS, payload: error.message })
    }
}

export const updateSingleClientBalAndSmsAction =
    ({ villageId, clientId }) =>
    async (dispatch) => {
        dispatch({ type: ActionTypes.FETCHING_SINGLE_CLIENT })

        try {
            const response = await connectApi.get(
                constants.endpoints.ALL_CLIENT_LIST,
                {
                    params: {
                        client_codes: clientId
                    }
                }
            )

            const transactionsResponse = await connectApi.get(
                constants.endpoints.TRANSACTIONS,
                {
                    params: {
                        client_codes: clientId
                    }
                }
            )

            let clientData = {}
            if (response.data.length > 0) {
                response.data[0].villageName = response.data[0].village
                response.data[0].village = villageId
                response.data[0]._is_new = true
                response.data[0].smss = transactionsResponse.data
                clientData = { ...response.data[0] }
            }

            sendMessageToWorkerWithResponse({
                'update balance and sms for client data': {
                    payload: clientData
                }
            })

            dispatch({
                type: ActionTypes.FETCHED_SINGLE_CLIENT,
                payload: response.data
            })
        } catch (error) {
            dispatch({
                type: ActionTypes.FAIL_SINGLE_CLIENT_REQUEST,
                payload: error?.response?.data?.detail
            })
        }
    }

export const clearVillageSelectionInfoAction = () => (dispatch) => {
    dispatch({
        type: ActionTypes.CLEAR_SELECTION_VILLAGE_INFO
    })
}

export const clearSingleClientDataAction = () => (dispatch) => {
    dispatch({
        type: ActionTypes.CLEAR_FETCHED_SINGLE_CLIENT
    })
}

export const resetClientOrdersAction = () => (dispatch) => {
    dispatch({ type: ActionTypes.RESET_CLIENT_ORDERS })
}

export const handleMultipleVillagesCountAction =
    (villageIds) => async (dispatch) => {
        // get client data from indexDb
        const storedClientData = await sendMessageToWorkerWithResponse({
            'get client data': {
                filter: 'village',
                clientType: constants.CLIENT_TYPES.ALL_CLIENT,
                villageIds
            }
        })

        dispatch({
            type: ActionTypes.MULTIPLE_VILLAGE_COUNT,
            payload: storedClientData.storedData
        })
    }
