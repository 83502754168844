import React from 'react'
import PropTypes from 'prop-types'
import './card.css'

interface CardProps {
    children: React.ReactNode
    onClick?: () => void
    className?: string
    shadow?: boolean
    id?: string
}

export const Card = ({ children, className, shadow = false, ...props }: CardProps) => {
    const cardShadow = shadow || 'no-shadow'
    return (
        <div
            className={['card-parent', className, cardShadow].join(' ').trim()}
            {...props}
        >
            {children}
        </div>
    )
}

Card.propTypes = {
    children: PropTypes.any,
    onClick: PropTypes.func,
    className: PropTypes.string,
    shadow: PropTypes.bool
}

Card.defaultProps = {
    children: 'Child'
}
