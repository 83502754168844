import { ActionTypes } from '../actions'
import { constants } from '../helpers'

const initialState = {
    status: constants.SHOW_NAVBAR
}

export const navbarReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
        case ActionTypes.PROCESS_NAVBAR:
            return {
                ...state,
                status: action.payload
            }

        default:
            return state
    }
}
