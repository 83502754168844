import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

export const RemainingPayment = ({
    children,
    text = 'village.leftToPay',
    ...props
}) => {
    const { t } = useTranslation()
    return (
        <div {...props}>
            <p
                className="card-pipe-left card-payment-amount-date card-payment-left"
                data-testid="remaining-text"
            >
                {t(text)}{' '}
                <span
                    className="payment-amount-date amount-left"
                    data-testid="remaining-value"
                >
                    {children}
                </span>
            </p>
        </div>
    )
}

RemainingPayment.propTypes = {
    children: PropTypes.string,
    text: PropTypes.string,
    className: PropTypes.string
}
