import React from 'react'

type FilterOptionType = {
    value: string
    visible: boolean
    checkboxHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void
    isSelected?: (param: string) => boolean
    children: React.ReactNode
    idx?: number
    id: string
}

export const FilterOption: React.FC<FilterOptionType> = ({
    value,
    checkboxHandler,
    isSelected,
    visible = true,
    children,
    idx,
    ...props
}) => {
    const filterOptionId = `${children}-${idx}`
    const hidden = visible ? '' : 'hidden'
    const parentClassName = `filter-option--child ${hidden}`
    return (
        <div className={parentClassName} {...props}>
            <label htmlFor={filterOptionId} className="filter-label">
                <input
                    id={filterOptionId}
                    type="checkbox"
                    value={value}
                    onChange={checkboxHandler}
                    checked={isSelected ? isSelected(value) : false}
                    className="filter-checkbox"
                />{' '}
                {children}
            </label>
        </div>
    )
}
