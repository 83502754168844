import { RxCollection, RxDatabase, createRxDatabase, lastOfArray } from 'rxdb'
import { getRxStorageDexie } from 'rxdb/plugins/storage-dexie'
import {
    RxReplicationState,
    replicateRxCollection
} from 'rxdb/plugins/replication'
import { clientSchema } from './schemas/clientSchema'
import { constants } from 'helpers'
import { collectionsApiEndpoints } from './collectionsApiEndpoints'
import connectApi from 'api/connectApi'

type RxDBCollection = RxDatabase<{
    [key: string]: RxCollection
}>

let db: RxDBCollection | null = null

type FetchedData = {
    client_code?: string
    date_modified?: number
    _deleted: boolean
}

type CheckpointData =
    | { date_modified?: number }
    | { client_code: string | undefined; date_modified: number | undefined }

let replicationState: RxReplicationState<FetchedData, CheckpointData>

const _create = async () => {
    // fetch token from localstorage
    const currentUser = localStorage.getItem(constants.CONNECT_USER)
    const { token } = currentUser ? JSON.parse(currentUser) : { token: '' }
    let connectIndexDatabase: RxDBCollection | null = null

    const endpoints = collectionsApiEndpoints()

    try {
        console.log('DatabaseService: creating database..')
        connectIndexDatabase = await createRxDatabase({
            name: 'connectindexdatabase',
            storage: getRxStorageDexie()
        })

        console.log('DatabaseService: created database')

        console.log('DatabaseService: create collections')
        await connectIndexDatabase.addCollections({
            clients: {
                schema: clientSchema
            }
        })
    } catch (error) {}

    Object.values(connectIndexDatabase?.collections ?? {})
        .map((col) => col.name)
        .map(async (colNames) => {
            replicationState = replicateRxCollection({
                collection: connectIndexDatabase?.[colNames],
                replicationIdentifier: `${process.env.REACT_APP_API_BASE_URL}/`,
                live: true,
                retryTime: 5 * 1000,
                waitForLeadership: true,
                autoStart: true,
                deletedField: '_deleted',
                pull: {
                    async handler(
                        lastCheckpoint: { date_modified?: number },
                        batchSize
                    ) {
                        const minTimestamp = lastCheckpoint?.date_modified || 0
                        const response = await connectApi.get(
                            `${process.env.REACT_APP_API_BASE_URL}${endpoints[colNames]}&updated_since=${minTimestamp}&limit=${batchSize}&order_by=date_modified`,
                            {
                                headers: {
                                    Accept: 'application/json',
                                    'Content-Type': 'application/json',
                                    Authorization: `Bearer ${token}`
                                }
                            }
                        )
                        const remoteData: FetchedData[] = await response?.data
                        return {
                            documents: remoteData,
                            checkpoint:
                                remoteData.length === 0
                                    ? lastCheckpoint
                                    : {
                                          client_code:
                                              lastOfArray(remoteData)
                                                  ?.client_code,
                                          date_modified:
                                              lastOfArray(remoteData)
                                                  ?.date_modified
                                      }
                        }
                    },
                    batchSize: parseInt(
                        process.env.REACT_APP_BATCH_SIZE ?? '50'
                    )
                }
            })

            replicationState.error$.subscribe((err) =>
                console.log('error:', err)
            )

            setInterval(() => {
                replicationState?.reSync()
            }, 50000)
        })

    return connectIndexDatabase
}

export const get = async () => {
    if (!db) {
        db = await _create()
    }
    return db
}

export { replicationState }
