import connectApi from '../api/connectApi'
import { constants } from '../helpers/constants'
import { ActionTypes } from './ActionTypes'

export const getUserPermissions = () => async (dispatch) => {
    try {
        const { PERMISSIONS } = constants.endpoints
        // access token from localstorage
        const { token } = JSON.parse(localStorage.getItem('connect_user'))
        const userPermissions = await connectApi.get(PERMISSIONS, {
            headers: { Authorization: token }
        })

        // extract the data from the endpoint
        const { permissions } = userPermissions.data
        localStorage.setItem(
            constants.USER_PERMISSIONS,
            JSON.stringify(permissions)
        )
        return dispatch({
            type: ActionTypes.FETCH_USER_PERMISSIONS,
            payload: permissions
        })
    } catch (error) {
        return dispatch({
            type: ActionTypes.FAIL_USER_PERMISSIONS,
            payload: 'Something is wrong'
        })
    }
}
