import connectApi from '../api/connectApi'
import { ActionTypes } from '../actions'
import { constants } from '../helpers'

export const veDepositsAction =
    ({ veCODE }) =>
    async (dispatch) => {
        const { VE_DEPOSIT } = constants.endpoints
        dispatch({ type: ActionTypes.FETCHING_VE_DEPOSIT_DATA })
        try {
            // get ves data
            const { data } = await connectApi.get(`${VE_DEPOSIT}${veCODE}/`)
            dispatch({
                type: ActionTypes.FETCH_VE_DEPOSIT_DATA,
                payload: data
            })
        } catch ({ response }) {
            dispatch({
                type: ActionTypes.FAIL_VE_DEPOSIT_DATA,
                payload: response?.data?.detail
            })
        }
    }
