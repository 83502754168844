import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { configStore } from './store/configStore'
import './helpers/i18n'
import './main.css'
import reportWebVitals from './reportWebVitals'
import { ConnectRoutes } from './navigation/ConnectRoutes'
import { handlePushNotification } from './helpers'
import OneSignal from 'react-onesignal'
import { install } from './install'
import * as Sentry from '@sentry/react'
import { MatomoProvider, createInstance } from '@jonkoops/matomo-tracker-react'
import { AppModalProvider } from './components'
// import { addRxPlugin } from 'rxdb'
// import { RxDBDevModePlugin } from 'rxdb/plugins/dev-mode'

import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType
} from 'react-router-dom'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const configStores = configStore()

// addRxPlugin(RxDBDevModePlugin)

if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment: process.env.REACT_APP_ENVIRONMENT || 'development',
        release: `connect-frontend@${process.env.REACT_APP_APP_VERSION}`,
        integrations: [
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes
            }),
            Sentry.replayIntegration()
        ],

        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0
    })
}

if (process.env.REACT_APP_MATOMO_URL) {
    const isTrainingModeOn =
        localStorage.getItem('connect_training_mode') === 'true'
    const instance = createInstance({
        urlBase: process.env.REACT_APP_MATOMO_URL,
        siteId: isTrainingModeOn
            ? parseInt(process.env.REACT_APP_MATOMO_SITE_ID_TRAINING || '10')
            : parseInt(process.env.REACT_APP_MATOMO_SITE_ID || '10'),
        linkTracking: false // Important!
    })
    root.render(
        <React.StrictMode>
            <Provider store={configStores}>
                <MatomoProvider value={instance}>
                    <AppModalProvider>
                        <ConnectRoutes />
                    </AppModalProvider>
                </MatomoProvider>
            </Provider>
        </React.StrictMode>
    )
} else {
    root.render(
        <React.StrictMode>
            <Provider store={configStores}>
                <AppModalProvider>
                    <ConnectRoutes />
                </AppModalProvider>
            </Provider>
        </React.StrictMode>
    )
}
// register offline pwa
serviceWorkerRegistration.register()
// install app to home screen
install()
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

if (process.env.REACT_APP_ONE_SIGNAL_APP_ID) {
    OneSignal.init({
        appId: process.env.REACT_APP_ONE_SIGNAL_APP_ID
    }).then(() => {
        OneSignal.on('notificationDisplay', (event) => {
            console.log('Notification received')
            handlePushNotification(event)
        })
    })
}
