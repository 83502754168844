export const internetConnect = () => {
    let internetConnectionStatus = navigator.onLine

    const handleInternetConnection = () => {
        internetConnectionStatus = navigator.onLine
        return internetConnectionStatus
    }
    window.addEventListener('offline', handleInternetConnection)
    window.addEventListener('online', handleInternetConnection)

    return internetConnectionStatus
}
