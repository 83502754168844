import { useState, useEffect } from 'react'

export const useAlert = (
    duration: number
): [boolean | null, React.Dispatch<React.SetStateAction<boolean | null>>] => {
    const [isVisible, setIsVisible] = useState<boolean | null>(null)

    useEffect(() => {
        if (isVisible !== null) {
            const timeoutId = setTimeout(() => {
                setIsVisible(null)
            }, duration)
            return () => clearTimeout(timeoutId)
        }
    }, [isVisible, duration])

    return [isVisible, setIsVisible]
}
