import { RefObject, useEffect, useState } from 'react'
import QrScanner from 'qr-scanner'
import { useTranslation } from 'react-i18next'

interface QrScannerProps {
    ref: RefObject<HTMLVideoElement>
    qrDataState?: React.Dispatch<React.SetStateAction<string>>
}

export const useQrScanner = ({ ref, qrDataState }: QrScannerProps) => {
    const [qrScannerError, setQrScannerError] = useState('')
    const [videoElm, setVideoElm] = useState<HTMLVideoElement | null>()
    const [qrScanner, setQrScanner] = useState<QrScanner | null>(null)
    const [streamState, setStreamState] = useState<MediaStream | null>(null)
    const { t } = useTranslation()

    let stream: MediaStream | null = null

    const getQR = async () => {
        try {
            setQrScannerError('')
            if (!stream) {
                // open user camera
                stream = await navigator.mediaDevices.getUserMedia({
                    video: {
                        facingMode: { ideal: 'environment' }
                    },
                    audio: false
                })
                setStreamState(stream)
                const videoEl = ref.current
                if (videoEl) {
                    videoEl.srcObject = stream
                    // start camera(Video)
                    await videoEl.play()
                    setVideoElm(videoEl)
                }
            }
        } catch (error) {
            setQrScannerError(t('qrCodePermissionError'))
        }
    }

    const stopQRScanner = (qrScannerInstance: QrScanner) => {
        if (qrScannerInstance) {
            qrScannerInstance.stop()
            qrScannerInstance.destroy()
            setQrScanner(null)
        }

        if (streamState) {
            streamState.getTracks()[0].stop()
            streamState.getTracks()[0].enabled = false
        }
    }

    const qrScannerCallbackHandler = (callback: (data: string) => void) => {
        if (!videoElm) return
        return new QrScanner(
            videoElm,
            ({ data }) => {
                try {
                    if (data) {
                        callback(data)
                    }
                } catch (error) {
                    setQrScannerError(t('qrCodeError'))
                }
            },
            {
                highlightScanRegion: true,
                returnDetailedScanResult: true,
                highlightCodeOutline: true
            }
        )
    }

    useEffect(() => {
        setQrScannerError('')
        getQR()
        if (videoElm) {
            try {
                // initiate qr scanner
                if (qrDataState) {
                    const scanner = qrScannerCallbackHandler(
                        qrDataState
                    ) as QrScanner
                    setQrScanner(scanner)
                    scanner.start()
                }
            } catch (error) {
                setQrScannerError(t('qrCodeError'))
            }
        }
    }, [videoElm])

    useEffect(() => {
        return () => {
            stopQRScanner(qrScanner as QrScanner)
        }
    }, [streamState])

    return {
        qrScannerError,
        stopQRScanner
    }
}
