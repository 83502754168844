import { handleTargetFilter } from 'actions'
import {
    HomeActionType,
    HomeEnum,
    HomeReducerStateType,
    TargetListReducerStateType,
    TargetListType,
    TargetPayloadType
} from '../storeTypes'

const initialState: HomeReducerStateType = {
    userData: [],
    loading: false,
    error: ''
}

export const homeReducer = (
    state: HomeReducerStateType = initialState,
    action: HomeActionType
): HomeReducerStateType => {
    switch (action.type) {
        case HomeEnum.FETCH_USER_DATA:
            return {
                ...state,
                userData: [action.payload],
                loading: false,
                error: ''
            }
        case HomeEnum.FETCHING_HOME_DATA:
            return { loading: true, error: '', userData: [] }
        case HomeEnum.FAIL_REQUEST:
            return {
                loading: false,
                userData: [],
                error: action.payload || 'Un problème est survenu'
            }
        default:
            return state
    }
}

const initialTargetListState: TargetListReducerStateType = {
    targetList: [],
    loading: true,
    processing: true,
    error: '',
    processedTargetList: []
}

const hideIncompleteTargets = (targetList: TargetPayloadType[]) =>
    targetList.filter(
        (target: TargetPayloadType) => target?.firstname && target?.lastname
    )

export const targetListReducer = (
    state: TargetListReducerStateType = initialTargetListState,
    action: TargetListType
): TargetListReducerStateType => {
    switch (action.type) {
        case HomeEnum.FETCHING_TARGET_LIST:
            return {
                ...state,
                loading: true,
                processing: true,
                error: '',
                targetList: [],
                processedTargetList: []
            }
        case HomeEnum.FETCH_TARGET_LIST:
            return {
                ...state,
                loading: false,
                processing: true,
                error: '',
                targetList: action.payload || [],
                processedTargetList: []
            }
        case HomeEnum.FAIL_TARGET_LIST_REQUEST:
            return {
                ...state,
                loading: false,
                processing: false,
                error: action.payload || 'Un problème est survenu',
                targetList: [],
                processedTargetList: []
            }
        case HomeEnum.FETCH_PROCESSED_TARGET_LIST: {
            const processedTargets = action.payload || []
            const cleanedTargetList = hideIncompleteTargets(processedTargets)
            return {
                ...state,
                processing: false,
                processedTargetList: cleanedTargetList || []
            }
        }
        case HomeEnum.FILTER_PROCESSED_TARGET_LIST: {
            const processedTargets =
                handleTargetFilter(
                    action.payload.selectedFilter,
                    action.payload.targetList
                ) || []
            const cleanedTargetList = hideIncompleteTargets(processedTargets)
            return {
                ...state,
                processedTargetList: cleanedTargetList,
                selectedFilter: action.payload.selectedFilter
            }
        }
        case HomeEnum.NO_PROCESSED_TARGET_LIST:
            return {
                ...state,
                processing: false
            }
        default:
            return state
    }
}
