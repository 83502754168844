import { ClientDetailsPayloadInterface } from '../storeTypes/enrollmentTypes'

export const hasClientDetailsChanged = (
    obj1: ClientDetailsPayloadInterface,
    obj2: { [key: string]: string | boolean | number }
) => {
    if (!obj2) {
        return true
    }
    const obj1Keys = Object.keys(obj1) as Array<string>
    const fieldsToCompare = [
        'firstname',
        'lastname',
        'age',
        'sex',
        'head_of_household',
        'group_leader',
        'phone',
        'phone2',
        'mobile_money_phone',
        'phone_owner',
        'reimbursement_choice'
    ]
    return obj1Keys.some(
        (key) =>
            fieldsToCompare.includes(key) &&
            (obj1[key] || obj2[key]) &&
            obj1[key] !== obj2[key]
    )
}
