import {
    RankingEnum,
    RankingActionType,
    RankingPayloadType
} from '../storeTypes'

export const veRankingReducer = (
    state: RankingPayloadType = { veRankingData: [], loading: true },
    action: RankingActionType
): RankingPayloadType => {
    switch (action.type) {
        case RankingEnum.FETCH_VE_RANKING_DATA:
            return {
                ...state,
                veRankingData: [...(action.payload as [])],
                loading: false,
                preFetchLoading: false,
                error: ''
            }
        case RankingEnum.FETCHING_VE_RANKING_DATA:
            return {
                loading: true,
                preFetchLoading: false,
                error: '',
                veRankingData: []
            }
        case RankingEnum.PRE_FETCHING_VE_RANKING_DATA:
            return {
                loading: true,
                preFetchLoading: true,
                error: '',
                veRankingData: []
            }
        case RankingEnum.FAIL_VE_RANKING_DATA:
            return {
                loading: false,
                preFetchLoading: false,
                veRankingData: [],
                error: action.payload as string
            }
        default:
            return state
    }
}
