import { ClientDetailsSmileyIcon, PacketIcon, PaymentIcons } from 'assets'
import { constants } from 'helpers'
import { useBindDispatch, useGetStorage } from 'hooks'
import React, { FC } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { ClientType } from 'storeTypes'
import { InteractionOutcomes } from 'types'

type FarmerIdModalProps = {
    clientData: ClientType
    selectClientHandler: ({
        clientData,
        clientType
    }: {
        clientData: ClientType
        clientType: string
    }) => void
    clientOrders?: { key: string; value: string; delivered: boolean }[]
    closeModalHandler: () => void
}

export const FarmerIdModal: FC<FarmerIdModalProps> = ({
    clientData,
    selectClientHandler,
    clientOrders,
    closeModalHandler
}) => {
    const navigate = useNavigate()
    const { pathname } = useLocation()

    const isClientPrevious = (clientData?.all_enrollment || 0) <= 0
    const { username } = useGetStorage('connect_user')
    const { sendInteractionsActionHandler } = useBindDispatch()

    const farmerModalClickHandler = (callback: () => void) => {
        closeModalHandler()
        selectClientHandler({
            clientData,
            clientType: isClientPrevious ? 'previous' : 'current'
        })

        callback()
    }

    const interactionHandler = (interactionOutcome: InteractionOutcomes) => {
        sendInteractionsActionHandler({
            client_code: clientData.client_code,
            user_id: username,
            outcome: interactionOutcome,
            client_status: clientData.client_status,
            survey_name: constants.TARGET_LIST_SURVEY_NAME,
            survey_version: constants.TARGET_LIST_SURVEY_VERSION,
            village_id: clientData.village_id
        })
    }

    return (
        <div>
            <div
                className="farmer-id--client-data"
                aria-label="client-details-data"
                onClick={() => {
                    farmerModalClickHandler(() =>
                        navigate(
                            `/village/${clientData.village_id}/client/${
                                clientData.client_code
                            }${
                                isClientPrevious ? '/previous' : ''
                            }?from=${pathname}`,
                            { replace: true }
                        )
                    )
                }}
            >
                <div className="farmer-id--circle"></div>
                <h2 className="farmer-id--name">{clientData.fullname}</h2>
                <p className="farmer-id--client-code">
                    ID: {clientData.client_code}
                </p>
            </div>
            <div className="farmer-id--modal-icons">
                <div className="farmer-id--section-icon">
                    <div
                        className="farmer-id--single-icon"
                        onClick={() => {
                            farmerModalClickHandler(() => {
                                if (!isClientPrevious) {
                                    localStorage.setItem(
                                        constants.CLIENT_FOR_ENROLLMENT_STORE,
                                        JSON.stringify({
                                            ...clientData,
                                            goal_items: clientOrders?.filter(
                                                (item) => !item.delivered
                                            ),
                                            form_type: 'modification',
                                            modification:
                                                constants
                                                    .CLIENT_MODIFICATION_TYPES
                                                    .PACKAGES
                                        })
                                    )
                                } else {
                                    localStorage.setItem(
                                        constants.CLIENT_FOR_ENROLLMENT_STORE,
                                        JSON.stringify({
                                            ...clientData,
                                            form_type: 're-enrollment'
                                        })
                                    )
                                    interactionHandler(
                                        InteractionOutcomes.ENROLLED
                                    )
                                }
                                navigate(
                                    `/enroll/${clientData.village_id}?from=${pathname}`
                                )
                            })
                        }}
                        aria-label="packet-icon-button"
                    >
                        <PacketIcon height="43" />
                    </div>
                    <div
                        className="farmer-id--single-icon"
                        onClick={() => {
                            farmerModalClickHandler(() => {
                                navigate(
                                    `/payment-selection?from=${pathname}&clientCode=${
                                        clientData.client_code
                                    }&villageId=${
                                        clientData.village_id
                                    }&clientType=${
                                        isClientPrevious ? 'previous' : ''
                                    }&clientStatus=${
                                        clientData.client_status || ''
                                    }`,
                                    { replace: true }
                                )
                            })
                        }}
                        aria-label="payment-icon-button"
                    >
                        <PaymentIcons />
                    </div>
                    <div
                        className="farmer-id--single-icon"
                        onClick={() => {
                            farmerModalClickHandler(() => {
                                const visitData = JSON.stringify({
                                    clientCode: clientData.client_code,
                                    clientName: clientData.firstname,
                                    origin: isClientPrevious
                                        ? 'prev_client_detail'
                                        : 'client_detail',
                                    villageId: clientData.village_id
                                })
                                localStorage.setItem(
                                    constants.CLIENT_FOR_VISIT_STORE,
                                    visitData
                                )
                                navigate(`/visit?from=${pathname}`, {
                                    replace: true
                                })
                            })
                        }}
                        aria-label="client-details-icon-button"
                    >
                        <ClientDetailsSmileyIcon height="43" />
                    </div>
                </div>
            </div>
        </div>
    )
}
