import React, { FC } from 'react'
import {
    ProductInfo,
    SelectedPacket,
    ZoneInfoTemplate
} from './enrollFormTypes'
import { Button, ProductSelection } from 'components'
import { useTranslation } from 'react-i18next'

type ProductSelectionPageProps = {
    zoneProductsInfo: ZoneInfoTemplate
    onProductSelected: (product: ProductInfo) => void
    selectedPackets: SelectedPacket[]
    onPacketConfirmation: () => void
}

export const ProductSelectionPage: FC<ProductSelectionPageProps> = ({
    zoneProductsInfo,
    onProductSelected,
    selectedPackets,
    onPacketConfirmation
}) => {
    const { t } = useTranslation()
    return (
        <>
            <div className="product-selection-container">
                <ProductSelection
                    onProductSelected={onProductSelected}
                    selectedPackets={selectedPackets}
                    zoneProductsInfo={zoneProductsInfo}
                />
            </div>
            <Button
                className="enrollment-button-package-selection"
                style="primary"
                disable={selectedPackets.length === 0}
                data-testid="package-selection-confirm-btn"
                id="package-selection-confirm-btn"
                onClick={onPacketConfirmation}
            >
                {t('continue')}
            </Button>
        </>
    )
}
