import React from 'react'
import PropTypes from 'prop-types'
import { buildCardContentFromDict, amountFormatter } from '../../helpers'
import { useGetStorage } from '../../hooks'

export const CardFormSummary = ({
    title,
    linkName,
    handleClick,
    contentDict,
    productList
}) => {
    const contentRender = buildCardContentFromDict(contentDict, [
        'card-summary-item'
    ])
    const { country } = useGetStorage('connect_user')

    const productSummaryRender = (productList) => {
        if (productList && productList.length) {
            return productList?.map((product, idx) => {
                return (
                    <div key={idx} className="card-summary-item">
                        <p>{product.name}</p>
                        <div className="enrollment-packet-summary">
                            <p>
                                {product.size_display || product.size}{' '}
                                {product.unit}
                            </p>
                            <p>&nbsp;|</p>
                            <p className="card-summary-product-price">
                                {amountFormatter(product.price, country)}
                            </p>
                        </div>
                    </div>
                )
            })
        }
    }

    return (
        <div>
            <div className="card-summary-header">
                <h3 className="card-form-summury-title">{title}</h3>
                <p
                    className="link-anchor card-summary-top-link"
                    onClick={handleClick}
                    data-testid={
                        title.replace(/\s/g, '-') + '-summary-top-link'
                    }
                    id="summary-modification-link"
                >
                    {linkName}
                </p>
            </div>
            <div className="card-summury-content">
                {productSummaryRender(productList)}
                {contentRender}
            </div>
        </div>
    )
}

CardFormSummary.propTypes = {
    title: PropTypes.string,
    linkName: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    handleClick: PropTypes.func,
    contentDict: PropTypes.object,
    productList: PropTypes.array
}
