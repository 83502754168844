import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { myagroIcon, warningIcon, closeIcon } from '../../assets'
import './card.css'

export const CardSale = ({
    children,
    inStock = true,
    selected = false,
    stock = { sold: 17, total: 20 },
    ...props
}) => {
    const { t } = useTranslation()
    const outOfStock = inStock || (
        <div className="card-sales-no-card" data-testid="card-sales-no-card">
            {warningIcon({})}
        </div>
    )
    const validSelect = selected && inStock
    const cardSaleOut = inStock ? '' : 'card-sales-out'
    const selectedCard = validSelect ? 'card-sales-selected' : ''
    const selectedCardSale = validSelect && (
        <div className="selected-icon" data-testid="selected-icon">
            {closeIcon({ color: '#ffffff', width: '15', height: '15' })}
        </div>
    )
    const { sold, total } = stock
    const stockDisplay =
        sold === total ? t('card.outOfStock') : t('card.inStock')

    return (
        <div className="card-sales-parent" {...props}>
            <div
                className={['card-sales', cardSaleOut, selectedCard]
                    .join(' ')
                    .trim()}
            >
                <h2 className="card-sales--bold card-sales--price">
                    {children}
                </h2>
                <div className="card-sales--logo-parent">
                    {validSelect && (
                        <div className="selected-number">
                            <h1 className="selected-number--content">1</h1>
                        </div>
                    )}
                    <div className="card-sales--logo">{myagroIcon}</div>
                </div>
            </div>
            <div className="card-sales-label">
                <p className="card-sales-label--stock">{stockDisplay}</p>
                <p className="card-sales-label--quantity">
                    <span className="card-sales--bold">{sold}</span>/{total}
                </p>
            </div>
            {outOfStock}
            {selectedCardSale}
        </div>
    )
}

CardSale.propTypes = {
    children: PropTypes.any,
    inStock: PropTypes.bool,
    selected: PropTypes.bool,
    stock: PropTypes.object
}
