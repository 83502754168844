export enum VisitEnum {
    SUBMITTING_VISIT_FORM = 'submitting visit form',
    SUBMIT_VISIT_FORM = 'submit visit form',
    FAIL_VISIT_SUBMISSION = 'fail visit submission',
    SET_IS_VISITED = 'set is visited',
    SHOW_WARNING_MODAL = 'show warning modal'
}

export interface VisitInterface {
    formPayload?: {}
}

export interface VisitStatusInterface {
    isVisited?: boolean
}

export interface ShowWarningModalInterface {
    showWarningModal?: boolean
}

type SubmittingVisitFormType = {
    type: VisitEnum.SUBMITTING_VISIT_FORM
}

type SubmitVisitFormType = {
    type: VisitEnum.SUBMIT_VISIT_FORM
    payload?: []
}

type FailVisitSubmissionType = {
    type: VisitEnum.FAIL_VISIT_SUBMISSION
    payload?: string
}

type SetIsVisitedType = {
    type: VisitEnum.SET_IS_VISITED
    payload: boolean
}

type ShowWarningModalType = {
    type: VisitEnum.SHOW_WARNING_MODAL
    payload: boolean
}

export type VisitActionTypes =
    | SubmittingVisitFormType
    | SubmitVisitFormType
    | FailVisitSubmissionType
    | SetIsVisitedType
    | ShowWarningModalType
