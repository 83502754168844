import * as Database from 'database/Database'
import { partitionClients } from './partitionClients'
import { findPermission } from 'helpers/findPermission'
import { constants } from './constants'
import { Dispatch } from 'redux'
import { groupBy } from 'lodash'

const databaseInstance = async () => {
    const database = await Database.get()
    return database
}

export const fetchClientIndexDbClientList = async (
    dispatchClient: (
        client: {}[],
        previous: {}[],
        villageId: string,
        dispatch: Dispatch
    ) => void,
    dispatch: Dispatch,
    villageId: string
) => {
    const db = await databaseInstance()

    return new Promise((resolve) => {
        db?.clients.find().$.subscribe((data) => {
            if (data.length > 0) {
                const { currentClients: client, previousClients: previous } =
                    partitionClients(groupBy(data, 'village_id')[villageId])
                dispatchClient(
                    client.map((item) => item._data),
                    previous.map((item) => item._data),
                    villageId,
                    dispatch
                )
            }
            resolve(data)
        })
    })
}

export const bulkInsertClients = async (clients: {}[]) => {
    const db = await databaseInstance()
    return db?.clients.bulkUpsert(clients)
}

export const canUseRxDb = () => {
    const userLocalPermissions = localStorage.getItem(
        constants.USER_PERMISSIONS
    )
    return (
        userLocalPermissions &&
        findPermission(JSON.parse(userLocalPermissions), constants.CAN_USE_RXDB)
    )
}

export const fetchAllClientList = async () => {
    const db = await databaseInstance()
    return db?.clients.find().exec()
}
