import { CardPaymentPayloadType } from './cardTypes'

export enum PaymentNotificationEnum {
    FETCHING_PAYMENT_NOTIFICATION = 'fetching payment notification',
    FETCH_PAYMENT_NOTIFICATION_SUCCESS = 'fetch payment notification success',
    FETCH_PAYMENT_NOTIFICATION_FAILURE = 'fetch payment notification failure',
    FILTERED_PAYMENT_NOTIFICATION = 'filtered payment notification',
    SEARCHED_PAYMENT_NOTIFICATION = 'searched payment notification'
}

export type PaymentPayloadType = {
    paymentData: CardPaymentPayloadType[] | []
    loading?: boolean
    error?: string
}

type FetchingPaymentNotificationType = {
    type: PaymentNotificationEnum.FETCHING_PAYMENT_NOTIFICATION
}

type FetchPaymentNotificationSuccess = {
    type: PaymentNotificationEnum.FETCH_PAYMENT_NOTIFICATION_SUCCESS
    payload: PaymentPayloadType[]
}

type FetchPaymentNotificationFailure = {
    type: PaymentNotificationEnum.FETCH_PAYMENT_NOTIFICATION_FAILURE
    payload: string
}

type FilteredPaymentNotificationType = {
    type: PaymentNotificationEnum.FILTERED_PAYMENT_NOTIFICATION
    payload: CardPaymentPayloadType[]
}

type SearchedPaymentNotificationType = {
    type: PaymentNotificationEnum.SEARCHED_PAYMENT_NOTIFICATION
    payload: CardPaymentPayloadType[]
}

export type PaymentNotificationActionType =
    | FetchingPaymentNotificationType
    | FetchPaymentNotificationSuccess
    | FetchPaymentNotificationFailure
    | FilteredPaymentNotificationType
    | SearchedPaymentNotificationType
