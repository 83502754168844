import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

export const CardVillage = ({ children, clientNumbers, ...props }) => {
    const { t } = useTranslation()
    // check if clientNumbers is undefined or not
    const isClientNumbers = !!clientNumbers
    const cardVillageNameOnly = isClientNumbers ? '' : 'card-village--name-only'
    const centerVillageParent = isClientNumbers ? '' : 'content-center'
    return (
        <div
            {...props}
            className={['card-village-parent', centerVillageParent]
                .join(' ')
                .trim()}
        >
            <div
                className={['card-village--name', cardVillageNameOnly]
                    .join(' ')
                    .trim()}
            >
                <h2 className="card-village--village">{children}</h2>
            </div>
            {isClientNumbers && (
                <div className="card-village--count">
                    <div className="card-village--count-section">
                        <p className="card-village--count-header">
                            {t('village.potentials')}
                        </p>
                        <p
                            className="card-village--count-number"
                            aria-label="potential clients count"
                        >
                            {clientNumbers.potentialClients}
                        </p>
                    </div>
                    <div className="card-village--count-section">
                        <p className="card-village--count-header">
                            {t('village.clients')}
                        </p>
                        <p
                            className="card-village--count-number"
                            aria-label="current clients count"
                        >
                            {clientNumbers.clients}
                        </p>
                    </div>
                </div>
            )}
        </div>
    )
}

CardVillage.propTypes = {
    children: PropTypes.string,
    clientNumbers: PropTypes.object
}
