export type FarmerIdQRDataType = {
    clientCode?: string
    type?: string
    country?: string
}

export type FarmerIdType = {
    displayModal: boolean
    farmerIdQrData: FarmerIdQRDataType
}

export enum FarmerIdEnum {
    DISPLAY_MODAL = 'display modal',
    SET_QR_DATA = 'set qr data'
}

export type FarmerIdModalType = {
    type: FarmerIdEnum.DISPLAY_MODAL
    payload?: boolean
}

export type FarmerIQRDataType = {
    type: FarmerIdEnum.SET_QR_DATA
    payload?: FarmerIdQRDataType
}

export type FarmerIdActionType = FarmerIdModalType | FarmerIQRDataType
