import React, { useState } from 'react'
import './toggleButton.css'

type ToggleButtonProps = {
    toggleText?: string
    onToggle?: (e: React.ChangeEvent<HTMLInputElement>) => void
    className?: string
}

export const ToggleButton: React.FC<ToggleButtonProps> = ({
    toggleText,
    onToggle,
    className
}) => {
    const [toggleOn, setToggleOn] = useState<string>('off')
    const toggleHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (onToggle) {
            onToggle(e)
        }
        setToggleOn((prevState) => {
            return prevState === 'off' ? 'on' : 'off'
        })
    }

    return (
        <div
            className={['toggle-parent', `${className}-${toggleOn}`]
                .join(' ')
                .trim()}
        >
            <input
                type="checkbox"
                id="switch"
                className="toggle-input"
                onChange={toggleHandler}
            />
            <label htmlFor="switch" className="toggle-label">
                <p className="toggle-text">{toggleText}</p>
            </label>
        </div>
    )
}
