import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, TopBar } from '../../components'
import { useGetStorage } from '../../hooks'
import './calcapp.css'

export const Calcapp = () => {
    const { t } = useTranslation()
    const { commision_calculator_url: commissionCalculatorUrl } =
        useGetStorage('country_config')

    return (
        <>
            <div className="header-bar" id="header-bar">
                <Container>
                    <TopBar back>{t('calcapp.calcappTitle')}</TopBar>
                </Container>
            </div>
            <div>
                <object
                    data={commissionCalculatorUrl}
                    type="text/html"
                    className="calc-app"
                >
                    Alternative Content
                </object>
            </div>
        </>
    )
}
