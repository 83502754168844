import React, { FC, useEffect, useRef } from 'react'
import { Button } from '../button/Button'
import { Spinner } from '../spinner/Spinner'
import './modal.css'
import { CloseIcon } from 'assets'

type ModalType = {
    position: 'top' | 'left' | 'bottom' | 'right' | 'center'
    okClickHandler?: () => void
    cancelClickHandler?: () => void
    textContent?: string
    textHeader?: string
    okButtonText?: string
    cancelButtonText?: string
    children: React.ReactNode
    className?: string
    showModal: boolean
    btnLoader?: boolean
    closeClickHandler?: () => void
    hideButtons?: boolean
    showModalSlideHandle?: boolean
    showCloseIconBtn?: boolean
    testId?: string
}

export const Modal: FC<ModalType> = ({
    children,
    position = 'bottom',
    okClickHandler,
    okButtonText = 'Ok',
    cancelButtonText = 'Cancel',
    cancelClickHandler,
    textHeader,
    className = '',
    showModal = false,
    btnLoader = false,
    closeClickHandler,
    hideButtons = false,
    showModalSlideHandle = false,
    showCloseIconBtn = false,
    testId,
    ...props
}) => {
    const ref = useRef<HTMLDivElement | null>(null)
    useEffect(() => {
        if (showModal) {
            const rootNode = document.getElementById('root')
            rootNode &&
                ref.current &&
                rootNode.insertBefore(ref.current, rootNode.firstElementChild)
        }
    }, [])

    return (
        <>
            {showModal ? (
                <div
                    onClick={closeClickHandler}
                    ref={ref}
                    id="modal"
                    className={`modal--parent ${className}`}
                    aria-label={testId || 'modal'}
                    {...props}
                >
                    <div
                        onClick={(e) => e.stopPropagation()}
                        className={['modal--body', `modal--body-${position}`]
                            .join(' ')
                            .trim()}
                    >
                        {showCloseIconBtn && (
                            <div
                                onClick={closeClickHandler}
                                className="modal--close-icon-btn"
                                aria-label="close modal button"
                            >
                                <CloseIcon />
                            </div>
                        )}
                        {showModalSlideHandle && (
                            <div className="modal--top-divider"></div>
                        )}
                        {textHeader && (
                            <h2 className="modal--header">{textHeader}</h2>
                        )}
                        {children && (
                            <div
                                className="modal--body-content"
                                data-testid="modal-body-text"
                            >
                                {children}
                            </div>
                        )}
                        {!hideButtons && (
                            <div className="modal--btn-parent">
                                {okClickHandler && (
                                    <Button
                                        onClick={() => okClickHandler()}
                                        className="modal--ok-btn"
                                        style="primary"
                                        data-testid="modal-ok-btn"
                                        id="modal-ok-btn"
                                        disable={btnLoader}
                                    >
                                        {btnLoader ? (
                                            <Spinner size="40" />
                                        ) : (
                                            okButtonText
                                        )}
                                    </Button>
                                )}
                                {cancelClickHandler && (
                                    <Button
                                        onClick={() => cancelClickHandler()}
                                        className="modal--cancel-btn"
                                        style="primary"
                                        data-testid="modal-cancel-btn"
                                        id="modal-cancel-btn"
                                    >
                                        {cancelButtonText}
                                    </Button>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <div data-testid="empty-div"></div>
            )}
        </>
    )
}
