/* eslint-disable no-unused-vars */
export enum PhoneOwnerEnum {
    OWN_NUMBER = 'Own number',
    OTHER = 'Other'
}

export type ProductType = {
    name: string
    sf_id: string
    id: string
}

// TODO: Goal item is the old name we used to describe an order and this type
// was likely used for the previous client list endpoint. Going forward, we
// should consolidate GoalItemType with the Order type in orderTypes.ts.
export type GoalItemType = {
    client_code: string
    delivered: boolean
    dropped: boolean
    product: ProductType
    ve_delivered: boolean
    uuid: string
    desired_units?: string
    desired_units_display: string
    price: number
}
