export interface CardInterface {
    veCode: string
}

export interface SearchClientListInterface {
    clientCode: string
}

export type CardSummary = {
    totalCardReceived: number
    totalCardSold: number
    totalCardRemaining: number
    totalCardAmountSold: number
}

export type CardPayloadType = {
    cardData?: []
    cardInventoryData?: []
    cardDetailData?: []
    searchedClient?: []
    response?: unknown
    loading?: boolean
    error?: string
    cardSummary?: CardSummary
    cardPaymentResponse?: string
    paymentResponse?: Array<{ status: string; amount?: string }>
}

export enum CardEnum {
    FETCHING_CARD_INVENTORY_DATA = 'fetching card inventory data',
    FETCH_CARD_INVENTORY = 'fetch card inventory',
    FAIL_REQUEST = 'fail request',
    FETCH_CARD_CLIENT_LIST = 'fetch card client list',
    FETCH_CARD_LIST = 'fetch card list',
    CLEAR_CARD_CLIENT_LIST = 'clear card client list'
}

type FetchingCardInventoryType = {
    type: CardEnum.FETCHING_CARD_INVENTORY_DATA
    payload?: CardPayloadType
}

type FetchCardInventoryType = {
    type: CardEnum.FETCH_CARD_INVENTORY
    payload: []
}

type FailRequestType = {
    type: CardEnum.FAIL_REQUEST
    payload: string
}

export type StoredDataType = {
    client_code: string
}

type FetchCardClientListType = {
    type: CardEnum.FETCH_CARD_CLIENT_LIST
    payload: {
        allClientData: {}
        storedData: StoredDataType[]
    }
    clientCode: string
}

type FetchCardListType = {
    type: CardEnum.FETCH_CARD_LIST
    payload: []
}

type ClearCardClientListType = {
    type: CardEnum.CLEAR_CARD_CLIENT_LIST
}

export enum CardPaymentEnum {
    SENDING_CARD_PAYMENT = 'fetching card payment data',
    SENT_CARD_PAYMENT = 'fetch card payment',
    FAILED_CARD_PAYMENT = 'fail request',
    FETCHING_CARD_PAYMENT_RESULT = 'fetching card payment result',
    FETCHED_CARD_PAYMENT_RESULT = 'fetch card payment result',
    FAILED_CARD_PAYMENT_RESULT = 'fail card payment result',
    CLEAR_CARD_PAYMENT = 'clear card payment',
    CLEAR_CARD_PAYMENT_RESULT = 'clear card payment result'
}

type SendingCardPaymentType = {
    type: CardPaymentEnum.SENDING_CARD_PAYMENT
    payload?: CardPayloadType
}

type SentCardPaymentType = {
    type: CardPaymentEnum.SENT_CARD_PAYMENT
    payload: string
}

type FailedCardPaymentType = {
    type: CardPaymentEnum.FAILED_CARD_PAYMENT
    payload: string
}

type FetchingCardPaymentResultType = {
    type: CardPaymentEnum.FETCHING_CARD_PAYMENT_RESULT
}

type FetchedCardPaymentResultType = {
    type: CardPaymentEnum.FETCHED_CARD_PAYMENT_RESULT
    payload: []
}

type FailedCardPaymentResultType = {
    type: CardPaymentEnum.FAILED_CARD_PAYMENT_RESULT
    payload: string
}

type ClearCardPaymentType = {
    type: CardPaymentEnum.CLEAR_CARD_PAYMENT
}

type ClearCardPaymentResultType = {
    type: CardPaymentEnum.CLEAR_CARD_PAYMENT_RESULT
}

export type CardPaymentActionType =
    | SendingCardPaymentType
    | SentCardPaymentType
    | FailedCardPaymentType
    | FetchingCardPaymentResultType
    | FetchedCardPaymentResultType
    | FailedCardPaymentResultType
    | ClearCardPaymentType
    | ClearCardPaymentResultType

export type CardActionTypes =
    | FetchingCardInventoryType
    | FetchCardInventoryType
    | FailRequestType
    | FetchCardClientListType
    | FetchCardListType
    | ClearCardClientListType

export enum CardPaymentStatus {
    SUCCESS = 'success',
    OFFLINE = 'offline',
    FAILED = 'failed',
    PENDING = 'pending'
}

export type CardPaymentPayloadType = {
    transaction_id: string
    date_received: string
    memo: string
    client_code: string
    uuid: string
    amount?: string
    failed_msg?: string
    status?: string
}
export interface CardPaymentInterface {
    paymentData: CardPaymentPayloadType
}

export interface CardPaymentResultInterface {
    [key: string]: string
}
