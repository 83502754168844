import React, { useEffect, useState, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { TargetList } from './TargetList'
import {
    Col,
    Container,
    Links,
    Row,
    TopBar,
    Spinner,
    TimedAlert,
    Alert,
    Search
} from '../../components'
import {
    useBindDispatch,
    useGetStorage,
    useOnChange,
    useValidation
} from '../../hooks'
import {
    capitalizeWord,
    constants,
    permissionFlags,
    quickSearchValidationRules
} from '../../helpers'
import './home.css'
import OneSignal from 'react-onesignal'
import { findPermission } from '../../helpers/findPermission'
import { closeIcon } from '../../assets'
import { VeMetrics } from './VeMetrics'
import { InputErrorMessage } from 'components/enrollForm/InputErrorMessage'

export const Home = () => {
    const [animate, setAnimate] = useState(false)
    const navigate = useNavigate()
    const [animationList, setAnimationList] = useState(false)
    const [errorMsg, setErrorMsg] = useState(false)
    const [targetScrollPos, setTargetScrollPos] = useState(0)
    const { t } = useTranslation()
    const [topBarTitle, setTopBarTitle] = useState(t('welcome'))
    const [changeIconColorTo, setChangeIconColorTo] = useState(false)
    const nodeRef = useRef(null)
    const inputRef = useRef(null)
    const targetScrollRef = useRef(null)
    const {
        veHomeHandler,
        navbarHandler,
        storeVillageClientListHandler,
        villageClientListHandler,
        storeEnrollmentFormHandler,
        openVillagePageHandler,
        getUserPermissionHandler,
        storeClientOrdersHandler,
        transactionsHandler,
        fetchInteractionsHandler,
        clientSearchHandler,
        clearClientSearchHandler
    } = useBindDispatch()
    const connectUser = useGetStorage('connect_user')
    const userPermissions = useGetStorage(constants.USER_PERMISSIONS)
    const { SHOW_NAVBAR, CLIENT_LIST_EXPIRATION_NAME, ANIMATION_TIMEOUT } =
        constants
    const {
        loading,
        userData,
        error: homePageError
    } = useSelector((store) => store.home)

    const { permissionsError, permissions } = useSelector(
        ({ userPermissions }) => userPermissions
    )

    const { inputText, onChangeHandler } = useOnChange({
        query: ''
    })

    const queryValidationRules = quickSearchValidationRules()

    const { errors, validateForm } = useValidation(
        inputText,
        queryValidationRules
    )

    const canViewTargetList = findPermission(
        permissions,
        constants.CAN_VIEW_TARGET_LIST
    )

    const canUseFarmerId = findPermission(
        userPermissions,
        permissionFlags.canUseFarmerId
    )
    const TOP_BAR_HEIGHT = 47
    // fetch ve data
    useEffect(() => {
        setAnimate(true)
        // show menu bar
        navbarHandler(SHOW_NAVBAR)

        clearClientSearchHandler()
        localStorage.removeItem(constants.SEARCH_RESULT)

        if (userData.length < 1) {
            veHomeHandler({
                veCODE: connectUser.username,
                country: connectUser.country
            })
        }

        // set page title
        document.title = t('home.pageTitle')
    }, [])

    useEffect(() => {
        if (permissions?.length < 1 || userPermissions?.length < 1) {
            getUserPermissionHandler()
        }
    }, [permissions?.length, userPermissions?.length])

    useEffect(() => {
        const { veVillages, zoneSFID, username, country } = JSON.parse(
            localStorage.getItem('connect_user')
        )
        // get client list expiration time
        const clientListExpiration = JSON.parse(
            localStorage.getItem(CLIENT_LIST_EXPIRATION_NAME)
        )
        const currentTime = new Date().getTime()
        const isClientListExpired =
            veVillages?.length > 0 &&
            clientListExpiration &&
            currentTime > parseFloat(clientListExpiration[veVillages[0].sf_id])

        if (
            (veVillages?.length > 0 && !clientListExpiration) ||
            isClientListExpired
        ) {
            storeVillageClientListHandler({
                villages: veVillages
            })
            storeClientOrdersHandler(veVillages)
        }
        if (veVillages?.length > 0) {
            veVillages.forEach((village) => {
                villageClientListHandler({ villageId: village.sf_id })
            })
        }
        if (veVillages?.length > 0) {
            fetchInteractionsHandler({
                villageIds: veVillages.map((village) => village.sf_id),
                surveyName: constants.FARMER_AWARENESS_SURVEY_NAME,
                surveyVersion: constants.FARMER_AWARENESS_SURVEY_VERSION
            })
        }

        veVillages?.forEach((village) => {
            transactionsHandler({ villageId: village.sf_id })
        })

        const isEnrollmentFormCached = JSON.parse(
            localStorage.getItem(constants.ENROLLMENT_FORM_CACHED_NAME)
        )
        if (!isEnrollmentFormCached && zoneSFID) {
            storeEnrollmentFormHandler({ zoneSFID })
        }
        // Update OneSignal tags
        OneSignal.sendTags({
            country,
            username,
            zone: zoneSFID
        })
    }, [userData])

    useEffect(() => {
        if (targetScrollPos < -TOP_BAR_HEIGHT) {
            setTopBarTitle(t('targetList.targetListHeaderTitle'))
        } else {
            setTopBarTitle(t('welcome'))
        }
    }, [targetScrollPos])

    useEffect(() => {
        function getScrollPos() {
            const pos = targetScrollRef.current?.getBoundingClientRect().top
            setTargetScrollPos(pos)
        }

        window.addEventListener('scroll', getScrollPos)
        getScrollPos()

        return () => window.removeEventListener('scroll', getScrollPos)
    }, [])

    useEffect(() => {
        validateForm()
        if (inputRef.current) {
            inputRef.current.style.border = 'none'
            setChangeIconColorTo('#16A34A')
        }
    }, [inputText.query, inputRef.current])

    const cardClickHandler = (filterOptions, clientTab) => {
        if (!canViewVillages) {
            setErrorMsg(true)
            return setTimeout(() => setErrorMsg(false), 2500)
        }
        const hasMultipleVillages = connectUser?.veVillages.length > 1

        openVillagePageHandler({
            filterOptions,
            clientTab,
            villageId: hasMultipleVillages
                ? ''
                : connectUser?.veVillages[0].sf_id
        })

        navigate('/village')
    }

    const { name } = userData[0] || { name: '' }
    const canViewDeposits = findPermission(
        permissions,
        constants.VIEW_MY_DEPOSITS
    )
    const canViewRankings = findPermission(
        permissions,
        constants.VIEW_VE_RANKINGS
    )
    const canViewCommissionCalculator = findPermission(
        permissions,
        constants.VIEW_COMMISSION_CALCULATOR
    )
    const canViewDepositGap = findPermission(permissions, constants.VIEW_MY_GAP)
    const canViewVillages = findPermission(permissions, constants.VIEW_VILLAGES)

    const villageIds = connectUser.veVillages?.map((village) => village.sf_id)

    const handleClientSearch = () => {
        if (!inputText.query || errors?.query) {
            inputRef.current.focus()
            inputRef.current.style.border = '1px solid red'
            return setChangeIconColorTo('red')
        }
        clientSearchHandler({
            searchValue: {
                fullName: inputText.query,
                clientId: inputText.query
            },
            villageIds
        })
        navigate('/client-search/result')
    }

    const homePageContent = (ve) => {
        if (!ve) {
            return
        }

        return (
            <>
                <div className="header-bar" id="header-bar">
                    <Container>
                        <TopBar>{topBarTitle}</TopBar>
                    </Container>
                </div>
                <CSSTransition
                    unmountOnExit
                    timeout={ANIMATION_TIMEOUT}
                    in={animate}
                    classNames="generic"
                    appear
                    onEnter={() => setAnimationList(true)}
                    nodeRef={nodeRef}
                >
                    <div className="page-wrapper" ref={nodeRef}>
                        <Container className="home-main">
                            <Row className="align-items-center">
                                <Col md={6}>
                                    <h2
                                        className={`home-welcome--text${
                                            canUseFarmerId ? '-large' : ''
                                        } header-welcome`}
                                    >
                                        {t('home.greetings')}{' '}
                                        {capitalizeWord(name.split(' ')[0])}
                                    </h2>
                                </Col>

                                <Col md={6} className="ranking-link-col">
                                    <Row>
                                        {canViewRankings && (
                                            <Col
                                                md={6}
                                                col={6}
                                                className="ranking-link-col"
                                                title="rankings-page"
                                            >
                                                <Links
                                                    className="ranking-link"
                                                    to="ranking"
                                                    id="ranking-link"
                                                >
                                                    {t('home.ranking')}
                                                </Links>
                                            </Col>
                                        )}
                                        {canViewCommissionCalculator && (
                                            <Col
                                                md={6}
                                                col={6}
                                                className="ranking-link-col"
                                                title="commission-calc"
                                            >
                                                <Links
                                                    className="ranking-link"
                                                    to="/calcapp"
                                                    id="calcapp-link"
                                                >
                                                    {t('calcapp.calcappTitle')}
                                                </Links>
                                            </Col>
                                        )}
                                    </Row>
                                </Col>
                            </Row>
                            {canUseFarmerId && (
                                <Row className="justify-content-center">
                                    <Col
                                        md={12}
                                        className="search-input-container"
                                    >
                                        <Search
                                            inputRef={inputRef}
                                            iconSize={30}
                                            iconColor={changeIconColorTo}
                                            iconPos="left"
                                            style="rounded"
                                            name="query"
                                            onChange={onChangeHandler}
                                            onSearchHandler={handleClientSearch}
                                            placeholder={t(
                                                'searchInputPlaceholder'
                                            )}
                                            data-testid="quick-search-input"
                                        />
                                        {errors?.query && (
                                            <InputErrorMessage
                                                errorMessage={errors?.query}
                                            />
                                        )}
                                    </Col>
                                </Row>
                            )}
                            <VeMetrics
                                animationList={animationList}
                                ve={ve}
                                connectUser={connectUser}
                                canViewDeposits={canViewDeposits}
                                canViewDepositGap={canViewDepositGap}
                                cardClickHandler={cardClickHandler}
                            />
                            {canViewTargetList && (
                                <TargetList
                                    targetScrollRef={targetScrollRef}
                                    veCode={connectUser.username}
                                />
                            )}
                            {errorMsg && (
                                <div data-testid="error-msg">
                                    <Alert
                                        type="floating"
                                        status="error"
                                        icon={true}
                                        iconType={closeIcon({ color: 'red' })}
                                    >
                                        {t('permission.noPermission')}
                                    </Alert>
                                </div>
                            )}
                        </Container>
                    </div>
                </CSSTransition>
            </>
        )
    }

    const homePage = loading ? (
        <Spinner size={'90'} pageSpinner={true} fullscreen={true} />
    ) : homePageError || permissionsError ? (
        <TimedAlert type="floating" status="error">
            {homePageError || permissionsError}
        </TimedAlert>
    ) : (
        homePageContent(userData[0])
    )

    return homePage
}
