import React from 'react'
import { checkMarkIcon, closeIcon, hamburgerIcon, moreDotsIcon } from 'assets'
import { useTranslation } from 'react-i18next'
import { updateCssClass } from './notificationUtils'

type NotificationFilterType = {
    filterHandler: (selectedFilter: string) => void
    resetSearchInput: () => void
    activeFilter: string
    setActiveFilter: (filter: string) => void
}

export const NotificationFilter: React.FC<NotificationFilterType> = ({
    filterHandler,
    resetSearchInput,
    activeFilter,
    setActiveFilter
}) => {
    const { t } = useTranslation()

    const filterOptions = [
        {
            name: t('all'),
            icon: hamburgerIcon,
            id: 'all'
        },
        {
            name: t('cardPayment.successful'),
            icon: checkMarkIcon,
            id: 'success'
        },
        {
            name: t('cardPayment.offline'),
            icon: moreDotsIcon,
            id: 'pending'
        },
        {
            name: t('cardPayment.failed'),
            icon: closeIcon,
            id: 'failed'
        }
    ]

    const paymentFilterHandler = (selectedFilter: string) => {
        resetSearchInput()
        setActiveFilter(selectedFilter)
        filterHandler(selectedFilter)
    }

    return (
        <div
            className="notification-filter--parent"
            data-testid="payment-notification-filter"
        >
            {filterOptions.map(({ name, icon, id }) => (
                <div
                    className="notification-filter"
                    onClick={() => paymentFilterHandler(id)}
                    key={id}
                    data-testid={`payment-filter-${id}`}
                >
                    <div
                        className={updateCssClass(
                            'notification-filter--icon-parent',
                            activeFilter === id ? 'active' : ''
                        )}
                    >
                        {icon({
                            color: activeFilter === id ? '#16a34a' : '#979797'
                        })}
                    </div>
                    <div
                        className={updateCssClass(
                            'notification-filter--text-parent',
                            activeFilter === id ? 'active' : ''
                        )}
                    >
                        <p className="notification-filter--text">{name}</p>
                    </div>
                </div>
            ))}
        </div>
    )
}
