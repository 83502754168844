import React from 'react'
import './button.css'
interface ButtonProps {
    children: React.ReactNode
    style?: 'primary' | 'secondary' | 'info' | 'warning' | 'light' | 'danger'
    icon?: React.ReactElement | string
    iconPos?: 'left' | 'right'
    onClick?: () => void
    size?: 'small' | 'large' | 'icon' | 'xl'
    className?: string
    id?: string
    disable?: boolean
    type?: 'button' | 'submit' | 'reset' | undefined
}

export const Button = ({
    children,
    style,
    icon,
    size,
    iconPos,
    className,
    disable,
    type,
    ...props
}: ButtonProps) => {
    const stylesOptions = {
        primary: 'btn__primary',
        secondary: 'btn_secondary',
        info: 'btn_info',
        warning: 'btn_warn',
        light: 'btn_light',
        danger: 'btn_danger'
    }
    const types = style && stylesOptions[style]
    const raws = icon ? (
        iconPos === 'right' ? (
            <>
                <span>{children}</span> {icon}
            </>
        ) : (
            <>
                {icon} <span>{children}</span>
            </>
        )
    ) : (
        children
    )
    const disabledBtn = disable ? 'disabled-btn' : ''
    return (
        <button
            className={[
                'default__btn',
                types,
                `btn-size--${size || 'large'}`,
                disabledBtn,
                className
            ].join(' ')}
            {...props}
            disabled={disable}
            type={type}
        >
            {raws}
        </button>
    )
}
