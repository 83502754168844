import { sendMessageToWorkerWithResponse } from './communication'

export const transitionToStep = (
    oldStep: number,
    newStep: number,
    currentClass: string,
    addonClass: string
): string => {
    const slideDirection = newStep > oldStep ? 'left' : 'right'
    currentClass = `${currentClass} enrollment-${addonClass}-${slideDirection}-${newStep}`
    window.scroll(0, 0)
    return currentClass
}

export const getPrevClientOrders = async (clientCode: string) => {
    const { result } = await sendMessageToWorkerWithResponse({
        'get client orders': clientCode
    })

    return result
}

export const getAllClientOrders = async () => {
    const { result } = await sendMessageToWorkerWithResponse({
        'get all client orders': ''
    })

    return result
}
