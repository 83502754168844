import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

export const LastYearDelivered = ({ children, paymentBalance, ...props }) => {
    const { t } = useTranslation()
    return (
        <div {...props}>
            <div className="card-client-payment">
                <p className="card-pipe-left card-payment-amount-date card-last-payment">
                    {children ? (
                        <>
                            {t('village.lastYearDelivered')}{' '}
                            <span className="payment-amount-date">
                                {children}
                            </span>
                        </>
                    ) : (
                        t('village.notDelivered')
                    )}
                </p>
                {paymentBalance && (
                    <p className="card-pipe-left">{paymentBalance}</p>
                )}
            </div>
        </div>
    )
}

LastYearDelivered.propTypes = {
    children: PropTypes.string,
    status: PropTypes.oneOf(['red', 'green', 'good']),
    paymentBalance: PropTypes.string
}
