import { FarmerIdActionType, FarmerIdEnum, FarmerIdType } from 'storeTypes'

export const displayFarmerIdModalReducer = (
    state: FarmerIdType = { displayModal: false, farmerIdQrData: {} },
    action: FarmerIdActionType
): FarmerIdType => {
    switch (action.type) {
        case FarmerIdEnum.DISPLAY_MODAL:
            return {
                ...state,
                displayModal: action?.payload || false
            }
        case FarmerIdEnum.SET_QR_DATA:
            return {
                ...state,
                farmerIdQrData: action?.payload || {}
            }
        default:
            return state
    }
}
