import { CloseIcon } from 'assets'
import React, { FC } from 'react'

type PopupModalProps = {
    closeModal: () => void
    children?: React.ReactNode
    showBg?: boolean
}

export const PopupModal: FC<PopupModalProps> = ({
    closeModal,
    children,
    showBg
}) => {
    return (
        <div className="modal--parent">
            <div
                className={`modal--body-feedback ${showBg ? 'modal-bg' : ''}`}
                data-testid="success-modal"
            >
                <div
                    className="modal--close-icon"
                    onClick={closeModal}
                    data-testid="modal-close-btn"
                >
                    <CloseIcon />
                </div>
                <div className="modal--body-container">{children}</div>
            </div>
        </div>
    )
}
