/* eslint-disable */
export enum ClientInteractions {
    CARD_PAYMENT = 'card payment',
    ENROLL = 'enroll',
    REQUEST_FOLLOW_UP = 'request follow up',
    MOBILE_MONEY_PAYMENT = 'mobile money payment',
    NOT_INTERESTED = 'not interested',
    REQUEST_REIMBURSEMENT = 'request reimbursement',
    DUPLICATE_FLAG = 'duplicate flag'
}
/* eslint-enable */
