export enum AuthEnum {
    LOGIN_SUCCESS = 'success',
    LOGIN_FAIL = 'fail',
    LOGGING = 'logging',
    LOGOUT = 'logout'
}

export interface AuthInterface {
    username: string
    password: string
}

type PayloadType = {
    message: string
    statusCode: number
    user?: {}
}

interface LoginSuccess {
    type: AuthEnum.LOGIN_SUCCESS
    payload: PayloadType
}

interface LoginFail {
    type: AuthEnum.LOGIN_FAIL
    payload: PayloadType
}

interface Logging {
    type: AuthEnum.LOGGING
    payload?: PayloadType
}

interface Logout {
    type: AuthEnum.LOGOUT
    payload?: PayloadType
}

export type AuthActionType = LoginSuccess | LoginFail | Logging | Logout

export type Village = {
    village_name: string
    sf_id: string
}
