export const constants = {
    HIDE_NAVBAR: 'HIDE_FULL',
    HIDE_PART_NAVBAR: 'HIDE_NAV',
    SHOW_NAVBAR: 'SHOW_FULL',
    MALI: 'CFA',
    SENEGAL: 'CFA',
    TANZANIA: 'TZS',
    endpoints: {
        LOGIN: '/login/',
        VE_CARD_INVENTORY: '/ve-card-inventory/',
        VES: '/ves/',
        ENROLLMENT: '/enrollment/',
        VE_DEPOSIT: '/ve-deposits/',
        ALL_CLIENT_LIST: '/all-client-list/',
        CLIENT_LIST: '/client-list/',
        PREVIOUS_CLIENT_LIST: '/historical-client-list/',
        COUNTRY_CONFIG: '/country-config/',
        MARK_DELIVERED: '/delivery/',
        PRE_CERTIFY: '/pre-certify/',
        PERMISSIONS: '/permissions/',
        PACKAGES_ENDPOINT: '/client-orders/',
        ORDERS: '/orders/',
        CLIENT_DETAILS: '/clients/',
        VISIT: '/visit/',
        VE_CARD_PAYMENT: '/payments/',
        TRANSACTIONS: '/transactions/',
        INTERACTIONS: '/interactions/',
        TARGET_LIST: '/target-list/'
    },
    USER_PERMISSIONS: 'user_permissions',
    CLIENT_DETAIL_LOCAL_STORE: 'client_details',
    CLIENT_FOR_ENROLLMENT_STORE: 'client_for_enrollment',
    GPS_COORDINATES: 'gps_coordinates',
    COUNTRY_CODE: {
        SENEGAL: 'SN',
        MALI: 'ML',
        TANZANIA: 'TZ'
    },
    MOBILE_SIZE: 540,
    TABLET_SIZE: 768,
    ENROLLMENT_FORM_CACHED_NAME: 'enrollment-form-cached',
    CLIENT_LIST_EXPIRATION_NAME: 'client-list-expiration',
    CLIENT_LIST_EXPIRATION_DURATION: 1,
    TRANSACTIONS_EXPIRATION_NAME: 'transactions-expiration',
    TRANSACTIONS_EXPIRATION_DURATION: 1,
    INTERACTIONS_EXPIRATION_NAME: 'interactions-expiration',
    INTERACTIONS_EXPIRATION_DURATION: 1,
    CLIENT_TYPES: {
        PREVIOUS_CLIENT: 'previous_client',
        CURRENT_CLIENT: 'current_client',
        ALL_CLIENT: 'all_client'
    },
    ANIMATION_TIMEOUT: 700,
    VILLAGE_PAGE_TABS: {
        PREVIOUS_CLIENT_TAB: 0,
        CURRENT_CLIENT_TAB: 1
    },
    SORT_TYPE: {
        DATE: 'date',
        ALPHA_NUMERIC: 'alpha_numeric'
    },
    SORT_DIRECTION: {
        ASCENDING: 'ascending',
        DESCENDING: 'descending'
    },
    RANKING_OPTIONS: {
        NUMBER_OF_ENROLLMENTS: 'NUMBER_OF_ENROLLMENTS',
        VALUE_OF_PAYMENTS: 'VALUE_OF_PAYMENTS',
        ENROLLMENT_PROGRESS: 'ENROLLMENT_PROGRESS',
        PAYMENT_PROGRESS: 'PAYMENT_PROGRESS'
    },
    PAYMENT_OPTIONS: {
        MOBILE_MONEY: 'Mobile money',
        SCRATCH_CARD: 'Scratch card'
    },
    CLIENT_SORT_OPTIONS: {
        REMAINING_PAYMENT: 'REMAINING_PAYMENT',
        COLOR_CODING: 'COLOR_CODING'
    },
    ADD_CARD_SALE: 'add_card_sale',
    ADD_STAFFUSER: 'add_staffuser',
    VIEW_PAYMENTS: 'view_payments',
    VIEW_STAFFUSER: 'view_staffuser',
    VIEW_VE_RANKINGS: 'view_ve_rankings',
    VIEW_VILLAGES: 'view_villages',
    VIEW_FINISHERS: 'view_finishers',
    VIEW_COMMISSION_CALCULATOR: 'view_commission_calculator',
    VIEW_MY_DEPOSITS: 'view_my_deposits',
    VIEW_CARDS: 'view_cards',
    VIEW_MY_GAP: 'view_my_gap',
    UPDATE_DELIVERED_ORDER: 'update_delivered_order',
    UPDATE_ORDER: 'update_order',
    UPDATE_CLIENT: 'update_client',
    CHANGE_STAFFUSER: 'change_staffuser',
    DELIVER_CLIENT: 'deliver_client',
    DELETE_STAFFUSER: 'delete_staffuser',
    PRE_CERTIFY_CLIENT: 'pre_certify_client',
    CONNECT_USER: 'connect_user',
    CLIENT_FOR_VISIT_STORE: 'client_for_visit',
    CLIENT_VISIT_INTERACTION: 'client_visit_interaction',
    CAN_RECORD_HOME_VISIT: 'can_record_home_visit',
    CAN_RECORD_HOME_VISIT_ENROLLMENT: 'can_record_home_visit_new_client',
    CAN_VIEW_LOGO_BUTTON: 'can_view_logo_button',
    CAN_VIEW_INTERACTION_LIST: 'can_view_interaction_list',
    CAN_ENROLL_CLIENT: 'can_enroll_client',
    CAN_VIEW_CARD_QR: 'can_view_card_qr',
    CAN_VIEW_TARGET_LIST: 'can_view_target_list',
    DIRECT_CARD_PAYMENT: 'can_make_direct_card_payment',
    CAN_USE_RXDB: 'can_use_rxdb',
    CAN_VIEW_PAYMENT_NOTIFICATION: 'can_view_payment_notification',
    CAN_VIEW_NEW_ENROLLMENT_FLOW: 'can_view_new_enrollment_flow',
    CAN_USE_FARMER_ID: 'can_use_farmer_id',
    ANALYTICS: {
        ACTIONS: {
            FILTER_LIST: 'filter list',
            SEARCH: 'client search'
        },
        SUBMITTED: 'submitted',
        NOT_SUBMITTED: 'not submitted',
        OPENING: 'opening',
        FORM: 'form',
        ENROLLMENT_FORM: 'enrollment form',
        CLIENT_DETAILS_FORM: 'client details form',
        CLIENT_PACKAGE_FORM: 'client package form',
        VILLAGE_CLIENT: 'village current clients',
        VILLAGE_POTENTIAL: 'village potential clients',
        FILTER_DIMENSION: 1,
        ALL: 'All'
    },
    ORIGIN_ENROLLMENT: 'enrollment',
    ORIGIN_CLIENT_DETAIL: 'client_detail',
    ORIGIN_PREV_CLIENT_DETAIL: 'prev_client_detail',
    SEARCH_RESULT: 'search result',
    ITEMS_PER_LOAD: 30,
    DEFAULT_FEEDBACK_MODAL_DURATION: 10000,
    THEME_NAMES: {
        DEFAULT: 'default',
        TRAINING: 'training'
    },
    PACKAGE_ENROLLMENT: 'package_enrollment',
    SINGLE_CLIENT_ORDERS_KEY: 'single_client_orders_key',
    INTERACTION_STORE_KEY: 'interaction_location',
    TRAINING_MODE_LOCAL_STORE: 'connect_training_mode',
    TARGET_LIST_FILTERS: {
        ALL: 'all',
        VISITED: 'visited',
        NOT_VISITED: 'not_visited'
    },
    PAYMENT_NOTIFICATION_FILTERS: {
        ALL: 'all',
        SUCCESS: 'success',
        PENDING: 'pending',
        FAILED: 'failed'
    },
    PERFORMANCE_COLORS: {
        LOW: 'low',
        MEDIUM: 'medium',
        HIGH: 'high'
    },
    FORM_TYPES: {
        ENROLLMENT: 'enrollment',
        MODIFICATION: 'modification',
        RE_ENROLLMENT: 're-enrollment'
    },
    TARGET_LIST_SURVEY_NAME:
        process.env.REACT_APP_TARGET_LIST_SURVEY_NAME || 'Target List',
    TARGET_LIST_SURVEY_VERSION:
        process.env.REACT_APP_TARGET_LIST_SURVEY_VERSION || '1.0',
    FARMER_AWARENESS_SURVEY_NAME:
        process.env.REACT_APP_AWERNESS_SURVEY_NAME || 'Awareness Survey',
    FARMER_AWARENESS_SURVEY_VERSION:
        process.env.REACT_APP_AWERNESS_SURVEY_VERSION || '0.2',
    PACKAGE_MODIFICATION_SURVEY_NAME:
        process.env.REACT_APP_PACKAGE_MODIFICATION_SURVEY_NAME ||
        'Package Modification',
    PACKAGE_MODIFICATION_SURVEY_VERSION:
        process.env.REACT_APP_PACKAGE_MODIFICATION_SURVEY_VERSION || '1.0',
    PACKAGE_ENROLLMENT_SURVEY_NAME:
        process.env.REACT_APP_PACKAGE_ENROLLMENT_SURVEY_NAME ||
        'Package Enrollment',
    PACKAGE_ENROLLMENT_SURVEY_VERSION:
        process.env.REACT_APP_PACKAGE_ENROLLMENT_SURVEY_VERSION || '1.0',

    CLIENT_REGISTRATION_SURVEY_NAME: 'Client Registration',
    CLIENT_REGISTRATION_SURVEY_VERSION: '1.0',
    MAX_ORDER_OF_PACKAGE: 20,
    CLIENT_DETAILS_FORM_STEPS: 4,
    FULL_ENROLLMENT_FORM_STEPS: 6,
    FULL_ENROLLMENT_FORM_WITH_VOUCHER_STEPS: 7,
    CLIENT_MODIFICATION_FORM_STEPS: 3,
    PACKAGE_MODIFICATION_FORM_STEPS: 2,
    VOUCHER_CODE_LENGTH: 14,
    PAYMENT_SOURCES: {
        MANUAL: 'manual',
        QR_CODE: 'qr_code'
    },
    CLIENT_MODIFICATION_TYPES: {
        DETAILS: 'client_details',
        PACKAGES: 'packages'
    }
}

export const routes = {
    enroll: 'enroll',
    packages: {
        index: 'packages',
        selectSize: 'select-size'
    },
    selectPayment: 'payment-selection',
    cardDetails: 'card-details',
    clientDetails: 'client-details',
    clientDetailsSteps: {
        stepOne: 'step-1',
        stepTwo: 'step-2',
        stepThree: 'step-3'
    }
}

export const permissionFlags = {
    canUseFarmerId: 'can_use_farmer_id'
}
