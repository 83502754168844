import React from 'react'
import { noVillage } from '../assets'

type NoVillageProps = {
    text: string
}

const NoVillagePage = ({ text }: NoVillageProps) => (
    <div className="no-village--parent" data-testid="no-village">
        {noVillage('#e85b5b')}
        <h2 className="no-village--text">
            {text || 'No villages assigned to this account'}
        </h2>
    </div>
)

export default NoVillagePage
