import { ActionTypes } from '../actions'

export const authReducer = (state = {}, action) => {
    switch (action.type) {
        case ActionTypes.LOGIN_SUCCESS:
            return {
                loggingIn: false,
                loggedIn: true,
                response: action.payload
            }
        case ActionTypes.LOGIN_FAIL:
            return {
                loggingIn: false,
                loggedIn: false,
                response: action.payload
            }
        case ActionTypes.LOGGING:
            return {
                loggingIn: true
            }
        case ActionTypes.LOGOUT:
            return {}
        default:
            return state
    }
}
