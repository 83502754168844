import React from 'react'
import { Col, Container, Row } from '../layout'
import { Card, CardClient } from '../card'
import { CardFormSummary } from '../card/CardFormSummary'
import { Button } from '../button/Button'
import { useTranslation } from 'react-i18next'
import { EnrollmentConfirmInterface } from './enrollFormTypes'

export const EnrollmentConfirm: React.FC<EnrollmentConfirmInterface> = ({
    enrollmentConfirmState,
    formGroupStep3,
    step,
    userProgress,
    generateDemographicSummary,
    generatePaymentSummary,
    buttonCTAText,
    permissions: { canUpdateClient, canUpdateOrder },
    hideClientDemoGraphic
}) => {
    const { t } = useTranslation()

    return (
        <div
            ref={formGroupStep3}
            className="enrollment-step"
            id="form-group-step3"
            data-testid="form-step3"
        >
            <div className="question-form-vertical">
                <Container>
                    <Row>
                        <Col md={6}>
                            <Card className="enrollment-summary-cards">
                                <CardClient
                                    clientId={enrollmentConfirmState.clientCode}
                                    size="small"
                                    clientPhone={enrollmentConfirmState.phone}
                                >
                                    {`${enrollmentConfirmState.firstname} ${enrollmentConfirmState.lastname}`}
                                </CardClient>
                            </Card>
                            {!hideClientDemoGraphic && (
                                <Card
                                    className="enrollment-summary-cards"
                                    data-testid="client-demographics"
                                >
                                    <CardFormSummary
                                        title={t(
                                            'enrollment.personalInformation'
                                        )}
                                        linkName={
                                            canUpdateClient && t('modify')
                                        }
                                        handleClick={() => userProgress(1)}
                                        // eslint-disable-next-line
                                        contentDict={generateDemographicSummary!()}
                                    ></CardFormSummary>
                                </Card>
                            )}
                        </Col>
                        <Col md={6}>
                            <Card className="enrollment-summary-cards">
                                <CardFormSummary
                                    title={t('enrollment.step2Title')}
                                    productList={
                                        enrollmentConfirmState.selectedPackets
                                    }
                                    contentDict={generatePaymentSummary()}
                                    linkName={canUpdateOrder && t('modify')}
                                    handleClick={() => userProgress(step - 1)}
                                ></CardFormSummary>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Button
                className="enrollment-button"
                style="primary"
                data-testid="button-step3"
                id="enrollment-btn-step3"
                onClick={() => userProgress(step + 1)}
            >
                {buttonCTAText}
            </Button>
        </div>
    )
}
