import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import './links.css'

export const Links = ({
    to,
    children,
    className,
    refreshDocument = false,
    ...props
}) => {
    const classNames = className ? `link-anchor ${className}` : 'link-anchor'
    return (
        <Link
            reloadDocument={refreshDocument}
            to={to}
            className={classNames}
            {...props}
        >
            {children}
        </Link>
    )
}

Links.propTypes = {
    to: PropTypes.string,
    children: PropTypes.string,
    className: PropTypes.string,
    refreshDocument: PropTypes.bool
}

Links.defaultProps = {
    to: '#'
}
