import React from 'react'
import { capitalizeWord } from '../helpers'
import { EditIcon } from '../assets'
import { t } from 'i18next'

export const buildCardContentFromDict = (
    infoDict,
    parentClassNames,
    modifyClickHandler,
    displayEditBtn
) => {
    if (infoDict) {
        const clientInfoRender = []
        for (const [key, value] of Object.entries(infoDict)) {
            const lowerCaseKey = key
                .replace(/([a-z])([A-Z])/g, '$1 $2')
                .toLowerCase()
            const render = (
                <div className="card-summary-single" key={key}>
                    <p className="card-summary-other-key">
                        {capitalizeWord(lowerCaseKey)} :
                    </p>
                    <p className="card-summary-other-value">{value}</p>
                </div>
            )
            clientInfoRender.push(render)
        }
        return (
            <div className={parentClassNames}>
                {displayEditBtn && (
                    <div className="client-details-data">
                        <p className="client-details-data--header-title">
                            {t('clientInformation')}
                        </p>

                        <div
                            className="client-details--link"
                            onClick={modifyClickHandler}
                            data-testid="modify-click-handler"
                        >
                            <EditIcon color="#16a34a" width="15" height="15" />
                            <p className="client-details--content--link">
                                {t('modify')}
                            </p>
                        </div>
                    </div>
                )}
                {clientInfoRender}
            </div>
        )
    }
}
