import { uniqBy } from 'lodash'
import { constants } from 'helpers/constants'
import {
    InteractionActionTypes,
    InteractionPayloadType,
    InteractionsStateType
} from 'storeTypes'

const localStoreHandler = (action: string, data?: {} | string) => {
    if (action === 'get') {
        return JSON.parse(
            localStorage.getItem(constants.INTERACTION_STORE_KEY) as string
        )
    }
    localStorage.setItem(constants.INTERACTION_STORE_KEY, JSON.stringify(data))
}

const handleSuccess = (position: {
    coords: { latitude: number; longitude: number; accuracy: number }
}) => {
    const timeNow = new Date()
    const locationToStore = {
        location: {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            accuracy: position.coords.accuracy
        },
        expirationTime: timeNow.setMinutes(timeNow.getMinutes() + 1)
    }

    localStoreHandler('set', locationToStore)
}

export const getInteractionLocation = () => {
    const startTime = Date.now()
    const storedLocation = localStoreHandler('get')
    if (storedLocation) {
        const expired = new Date(storedLocation.expirationTime)
        if (expired.getTime() > startTime) {
            return Promise.resolve(storedLocation.location)
        }
    }
    if ('geolocation' in navigator) {
        const positionId = navigator.geolocation.watchPosition(
            handleSuccess,
            (e: GeolocationPositionError) => console.error(e)
        )

        return new Promise((resolve) => {
            const intervalId = setInterval(() => {
                const duration = Math.round((Date.now() - startTime) / 1000)
                const geolocation = localStoreHandler('get')

                if (geolocation?.accuracy < 20 || duration > 20) {
                    clearInterval(intervalId)
                    navigator.geolocation.clearWatch(positionId)
                    resolve(geolocation?.location)
                }
            }, 1000)
        })
    }
}

export const isFarmerAwareness = (interaction: InteractionPayloadType) => {
    return (
        interaction.survey_name === constants.FARMER_AWARENESS_SURVEY_NAME &&
        interaction.survey_version === constants.FARMER_AWARENESS_SURVEY_VERSION
    )
}

export const findInteraction = (
    interactions: InteractionPayloadType[],
    clientCode: string
) =>
    interactions.find(
        (interaction) =>
            interaction.client_code === clientCode &&
            isFarmerAwareness(interaction)
    )

export const mergeInteractions = (
    interactions: InteractionsStateType['interactions'],
    newInteractions: InteractionActionTypes['payload']
) => {
    if (interactions && newInteractions) {
        const mergedInteractions = [...newInteractions, ...interactions]
        return uniqBy(mergedInteractions, 'id')
    }

    return newInteractions || interactions
}
