export const abbreviateName = (name) => {
    if (!name) {
        return
    }
    const nameArray = name.split(' ')
    const abbreviatedName =
        nameArray.length > 1
            ? `${nameArray[0][0]}${nameArray.slice(-1)[0][0]}`
            : nameArray[0][0]
    return abbreviatedName.toUpperCase()
}
