import { constants } from 'helpers'

type collectionsApiEndpointsType = {
    [key: string]: ((args: string) => string) | string
}

export const collectionsApiEndpoints = (): collectionsApiEndpointsType => {
    const connectUser = localStorage.getItem(constants.CONNECT_USER)
    const { veVillages } = connectUser
        ? JSON.parse(connectUser)
        : { veVillages: [] }
    const villageSfId = veVillages
        .map((veVillage: { sf_id: string }) => {
            return `village=${veVillage.sf_id}`
        })
        .join('&')
    return {
        clients: `${constants.endpoints.ALL_CLIENT_LIST}?${villageSfId}`
    }
}
