export enum RankingEnum {
    FETCHING_VE_RANKING_DATA = 'fetching ve ranking data',
    FETCH_VE_RANKING_DATA = 'fetch ve ranking data',
    FAIL_VE_RANKING_DATA = 'fail ve ranking data',
    PRE_FETCHING_VE_RANKING_DATA = 'prefetch fetching ve ranking data'
}

export interface RankingInterface {
    zoneCode: string
}

export type RankingPayloadType = {
    veRankingData?: []
    loading?: boolean
    error?: string
    preFetchLoading?: boolean
}

type FetchingVeRankingDataType = {
    type: RankingEnum.FETCHING_VE_RANKING_DATA
}

type FetchVeRankingDataType = {
    type: RankingEnum.FETCH_VE_RANKING_DATA
    payload: RankingPayloadType
}

type FailVeRankingDataType = {
    type: RankingEnum.FAIL_VE_RANKING_DATA
    payload: string
}

type PreFetchingVeRankingDataType = {
    type: RankingEnum.PRE_FETCHING_VE_RANKING_DATA
    payload: RankingPayloadType
}

export type RankingActionType =
    | FetchingVeRankingDataType
    | FetchVeRankingDataType
    | FailVeRankingDataType
    | PreFetchingVeRankingDataType
