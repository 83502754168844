import React from 'react'
import PropTypes from 'prop-types'

import { Card, CardClientPayment, CardNameAbr, ClientPhone } from '..'
import { amountFormatter, constants } from '../../helpers'
import { LastYearDelivered } from '.'

export const CardPotentialClient = ({
    clientCode,
    fullname,
    recentYearDelivered,
    phone,
    balance,
    country,
    prevClientSelection
}) => {
    return (
        <Card
            shadow={false}
            key={clientCode}
            className="client-contact-card"
            data-testid="client-card"
            onClick={() => prevClientSelection(clientCode)}
            id={`client-list-${clientCode}`}
        >
            <CardClientPayment lastYearDelivered={recentYearDelivered || ' '}>
                <CardNameAbr
                    subHeader={`ID ${clientCode}`}
                    balance={amountFormatter(parseFloat(balance), country)}
                    hideBalance={!balance || parseFloat(balance) === 0}
                    clientType={constants.CLIENT_TYPES.PREVIOUS_CLIENT}
                >
                    {fullname}
                </CardNameAbr>
                <LastYearDelivered>{recentYearDelivered}</LastYearDelivered>
                <ClientPhone>{phone}</ClientPhone>
            </CardClientPayment>
        </Card>
    )
}

CardPotentialClient.propTypes = {
    clientCode: PropTypes.string,
    fullname: PropTypes.string,
    recentYearDelivered: PropTypes.string,
    phone: PropTypes.string,
    balance: PropTypes.string,
    country: PropTypes.string,
    prevClientSelection: PropTypes.func
}
