// Send a message to the serviceWorker
export const sendMessageToWorker = (data) => {
    try {
        navigator.serviceWorker.controller.postMessage(data)
    } catch (error) {
        // No need to log or do anything.
        // if service worker isn't defined yet
    }
}

export const sendMessageToWorkerWithResponse = (message) => {
    // This wraps the message posting/response in a promise, which will
    // resolve if the response doesn't contain an error, and reject with
    // the error if it does. If you'd prefer, it's possible to call
    // controller.postMessage() and set up the onmessage handler
    // independently of a promise, but this is a convenient wrapper.
    return new Promise(function (resolve, reject) {
        const messageChannel = new MessageChannel()
        messageChannel.port1.onmessage = function (event) {
            if (event.data && event.data.error) {
                reject(event.data.error)
            } else {
                resolve(event.data)
            }
        }

        // This sends the message data as well as transferring
        // messageChannel.port2 to the service worker.
        // The service worker can then use the transferred port to reply
        // via postMessage(), which will in turn trigger the onmessage
        // handler on messageChannel.port1.
        // See
        // https://html.spec.whatwg.org/multipage/workers.html#dom-worker-postmessage
        navigator.serviceWorker.controller.postMessage(message, [
            messageChannel.port2
        ])
    })
}

export const handlePushNotification = (event) => {
    if (event.data?.clear_cache) {
        sendMessageToWorker({ 'clear cache': event.data.clear_cache })
    }
}
