import React, { ReactNode } from 'react'
import { updateCssClass } from './notificationUtils'

type SinglePaymentNotificationType = {
    cardNumber: string
    clientCode: string
    dateTime: string
    amount: string
    icon: ReactNode
    status: string
    onClick: () => void
    formattedDateTime: string
}

export const SinglePaymentNotification: React.FC<
    SinglePaymentNotificationType
> = ({
    cardNumber,
    clientCode,
    dateTime,
    amount,
    icon,
    status = '',
    onClick,
    formattedDateTime
}) => {
    return (
        <div
            className="payment-notification"
            onClick={onClick}
            data-testid="single-payment"
        >
            <div className="payment-notification--row">
                <div className="">
                    <p className="payment-notification--card-number">
                        {cardNumber}
                    </p>
                    <p className="payment-notification--client-code">
                        ID{' '}
                        <span className="client-code--green">{clientCode}</span>
                    </p>
                </div>
                <div
                    className={updateCssClass(
                        'payment-notification--icon-parent',
                        status
                    )}
                >
                    {icon}
                </div>
            </div>
            <div className="payment-notification--row">
                <p className="payment-notification--date-time">
                    {formattedDateTime}{' '}
                    {new Date(dateTime).toLocaleDateString()}
                </p>
                {amount && (
                    <p className="payment-notification--amount">{amount}</p>
                )}
            </div>
        </div>
    )
}
