import { FlagIcon } from 'assets'
import { Button } from 'components/button/Button'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const ConfirmDuplicateFlagInteraction: FC<{
    clientCode: string
    clientNames: string
    handleOnClick: () => void
}> = ({ clientCode, clientNames, handleOnClick }) => {
    const { t } = useTranslation()
    return (
        <section className="modal--confirm-content">
            <div>
                <div>
                    <FlagIcon width="70" height="70" />
                </div>
                <div>
                    <h2 className="modal--header card-client-detail--name">
                        {clientNames}
                    </h2>
                    <p>{`ID: ${clientCode}`}</p>
                </div>
            </div>
            <div>
                <h3 className="modal--confirm-message">
                    {t('modalContent.duplicateConfirmMessage')}
                </h3>
            </div>
            <div>
                <Button
                    size="xl"
                    style="danger"
                    className="client-details--bottom-button"
                    icon={<FlagIcon color="white" />}
                    onClick={handleOnClick}
                >
                    <span className="responsive-btn-font">
                        {t('clientDetails.duplicateFlag')}
                    </span>
                </Button>
            </div>
        </section>
    )
}
