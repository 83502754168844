import React, { FC } from 'react'
import './steps.css'

type StepsProps = {
    steps: number
    activeSteps: number[]
}

export const Steps: FC<StepsProps> = ({ steps, activeSteps }) => {
    return (
        <div>
            <div className="steps--container">
                {Array.from(Array(steps).keys()).map((step) => {
                    return (
                        <div key={step} className="steps--parent">
                            <div
                                className={`steps--tail ${
                                    activeSteps.includes(step + 1)
                                        ? 'steps--tail-active'
                                        : ''
                                }`}
                            ></div>
                            <div
                                className={`steps--head ${
                                    activeSteps.includes(step + 1)
                                        ? 'steps--head-active'
                                        : ''
                                }`}
                            ></div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
