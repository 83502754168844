import React from 'react'
import PropTypes from 'prop-types'
import { abbreviateName } from '../../helpers'
import { phoneIcon } from '../../assets'

export const CardClient = ({
    size,
    children,
    clientId,
    clientPhone,
    ...props
}) => {
    const nameAbbr = abbreviateName(children)
    const cardClientId = clientId && (
        <p className="card-client-id--client">ID {clientId}</p>
    )
    const cardClientPhone = clientPhone && (
        <span className="card-client-phone--icon">
            <p className="card-client-phone">{clientPhone}</p>
            {phoneIcon({ color: '' })}
        </span>
    )
    return (
        <div className="card-client-parent card-client--no-spread" {...props}>
            <div
                className={[
                    'card-client-abbr',
                    'card-client-abbr--name',
                    `card-client-abbr--${size}`
                ].join(' ')}
            >
                {nameAbbr}
            </div>
            <div className="card-client-data">
                <h2 className="card-client-data--name">{children}</h2>
                {cardClientId}
                {cardClientPhone}
            </div>
        </div>
    )
}

CardClient.propTypes = {
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    children: PropTypes.any,
    clientId: PropTypes.string,
    clientPhone: PropTypes.string,
    onClick: PropTypes.func
}
