import React from 'react'
import { TextInput } from '../textInput/TextInput'
import { Select } from '../select/Select'
import { Col, Container, Row } from '../layout'
import { CheckBox } from '../checkbox/CheckBox'
import { Button } from '../button/Button'
import { useTranslation } from 'react-i18next'
import {
    ClientDetailsFormInterface,
    ClientDetailsFormValidationErrorType
} from './enrollFormTypes'
import { RadioButton } from '../radioButton/RadioButton'
import { clientDetailsFormRequiredFields, constants } from '../../helpers'
import { findPermission } from '../../helpers/findPermission'
import { useGetStorage } from '../../hooks'

export const ClientDetailsForm: React.FC<ClientDetailsFormInterface> = ({
    formGroupStep1,
    clientDetailsState,
    handleInputChange,
    formType,
    buttonCTAText,
    formValidationError,
    phoneOwner,
    showPhoneOwner = false,
    navigationPathHandler
}) => {
    const { t } = useTranslation()
    const { CAN_RECORD_HOME_VISIT_ENROLLMENT, USER_PERMISSIONS, UPDATE_ORDER } =
        constants
    const userPermissions = useGetStorage(USER_PERMISSIONS)
    const displayVisitButton = findPermission(
        userPermissions,
        CAN_RECORD_HOME_VISIT_ENROLLMENT
    )
    const canEnrollPackage = findPermission(userPermissions, UPDATE_ORDER)

    const handlePacketEnrollmentNavigation = () => {
        if (navigationPathHandler) {
            const url = `/enrollment/package-selection?form_type=${formType}`
            navigationPathHandler(url)
        }
    }

    const handleVisitRecordNavigation = () => {
        if (navigationPathHandler) {
            return navigationPathHandler('/visit')
        }
    }

    const formLabelClassName = (fieldName: string) => {
        const classNames = ['form-label']

        if (clientDetailsFormRequiredFields.includes(fieldName)) {
            classNames.push('required')
        }

        if (
            formValidationError?.[
                fieldName as keyof ClientDetailsFormValidationErrorType
            ]
        ) {
            classNames.push('error')
        }

        return classNames.join(' ')
    }

    return (
        <div
            className="enrollment-step"
            ref={formGroupStep1}
            id="form-group-step1"
            data-testid="form-step1"
        >
            <div className="question-form-vertical">
                <label
                    htmlFor="lastname"
                    className={formLabelClassName('lastname')}
                >
                    {t('lastName')}
                </label>
                <TextInput
                    id="lastname"
                    name="lastname"
                    value={clientDetailsState.lastname}
                    data-testid="lastname-input"
                    onChange={handleInputChange}
                    errorValidation={!!formValidationError?.lastname}
                />
                {formValidationError?.lastname && (
                    <p
                        className="generic--error-message"
                        data-testid="client-details-error"
                    >
                        {formValidationError.lastname}
                    </p>
                )}
            </div>
            <div className="question-form-vertical">
                <label
                    htmlFor="firstname"
                    className={formLabelClassName('firstname')}
                >
                    {t('firstName')}
                </label>
                <TextInput
                    id="firstname"
                    name="firstname"
                    value={clientDetailsState.firstname}
                    onChange={handleInputChange}
                    errorValidation={!!formValidationError?.firstname}
                    data-testid="firstname-input"
                />
                {formValidationError?.firstname && (
                    <p
                        className="generic--error-message"
                        data-testid="client-details-error"
                    >
                        {formValidationError.firstname}
                    </p>
                )}
            </div>
            <div className="question-form-horizontal">
                <div style={{ width: '49%' }}>
                    <label
                        htmlFor="gender"
                        className={formLabelClassName('gender')}
                    >
                        {t('gender')}
                    </label>
                    <Select
                        id="gender"
                        name="gender"
                        value={clientDetailsState?.gender}
                        data-testid="gender-select"
                        onChange={handleInputChange}
                        options={[
                            {
                                label: '----------',
                                value: ''
                            },
                            {
                                label: t('man'),
                                value: 'Male'
                            },
                            {
                                label: t('woman'),
                                value: 'Female'
                            }
                        ]}
                        errorValidation={!!formValidationError?.gender}
                    ></Select>
                    {formValidationError?.gender && (
                        <p
                            className="generic--error-message"
                            data-testid="client-details-error"
                        >
                            {formValidationError.gender}
                        </p>
                    )}
                </div>
                <div style={{ width: '49%' }}>
                    <label htmlFor="age" className={formLabelClassName('age')}>
                        {t('age')}
                    </label>
                    <TextInput
                        id="age"
                        name="age"
                        value={clientDetailsState.age as string | undefined}
                        data-testid="age-input"
                        onChange={handleInputChange}
                        errorValidation={!!formValidationError?.age}
                    />
                    {formValidationError?.age && (
                        <p
                            className="generic--error-message"
                            data-testid="client-details-error"
                        >
                            {formValidationError.age}
                        </p>
                    )}
                </div>
            </div>
            <div className="question-form-vertical">
                <label htmlFor="phone" className={formLabelClassName('phone')}>
                    {t('enrollment.primaryPhone')}
                </label>
                <TextInput
                    id="phone"
                    name="phone"
                    value={clientDetailsState.phone}
                    onChange={handleInputChange}
                    data-testid="phone-input"
                    errorValidation={!!formValidationError?.phone}
                />
                {formValidationError?.phone && (
                    <p
                        className="generic--error-message"
                        data-testid="client-details-error"
                    >
                        {formValidationError.phone}
                    </p>
                )}
            </div>
            {showPhoneOwner && (
                <div className="question-form-vertical">
                    <label className={formLabelClassName('phoneOwner')}>
                        {t('enrollment.numberOwner')}
                    </label>
                    <div>
                        <RadioButton
                            labelName={t('enrollment.ownNumber')}
                            value="Own number"
                            onChange={handleInputChange}
                            name="phoneOwner"
                            checked={phoneOwner === 'Own number'}
                        />
                        <RadioButton
                            labelName={t('enrollment.other')}
                            value="Other"
                            onChange={handleInputChange}
                            name="phoneOwner"
                            checked={
                                phoneOwner !== 'Own number' && !!phoneOwner
                            }
                        />
                    </div>
                    {formValidationError?.phoneOwner && (
                        <p
                            className="generic--error-message"
                            data-testid="client-details-error"
                        >
                            {formValidationError.phoneOwner}
                        </p>
                    )}
                </div>
            )}
            {clientDetailsState.phoneOwner === 'Other' && showPhoneOwner && (
                <div className="question-form-vertical">
                    <label className={formLabelClassName('phoneOwner')}>
                        {t('enrollment.whoOwns')}
                    </label>
                    <Select
                        id="otherNumberOwner"
                        name="otherNumberOwner"
                        value={clientDetailsState?.otherNumberOwner}
                        data-testid="otherNumberOwner-select"
                        onChange={handleInputChange}
                        options={[
                            {
                                label: '----------',
                                value: ''
                            },
                            {
                                label: t('spouse'),
                                value: 'Spouse'
                            },
                            {
                                label: t('child'),
                                value: 'Child'
                            },
                            {
                                label: t('sister'),
                                value: 'Sister'
                            },
                            {
                                label: t('brother'),
                                value: 'Brother'
                            },
                            {
                                label: t('friend'),
                                value: 'Friend'
                            },
                            {
                                label: t('other'),
                                value: 'Other'
                            }
                        ]}
                        errorValidation={
                            !!formValidationError?.otherNumberOwner
                        }
                    ></Select>

                    {formValidationError?.otherNumberOwner && (
                        <p
                            className="generic--error-message"
                            data-testid="client-details-error"
                        >
                            {formValidationError.otherNumberOwner}
                        </p>
                    )}
                </div>
            )}
            <div className="question-form-vertical">
                <label
                    htmlFor="phone2"
                    className={formLabelClassName('phone2')}
                >
                    {t('enrollment.secondaryPhone')}
                </label>
                <TextInput
                    id="phone2"
                    name="phone2"
                    value={clientDetailsState?.phone2}
                    data-testid="phone2-input"
                    onChange={handleInputChange}
                    errorValidation={!!formValidationError?.phone2}
                />
                {formValidationError?.phone2 && (
                    <p
                        className="generic--error-message"
                        data-testid="client-details-error"
                    >
                        {formValidationError.phone2}
                    </p>
                )}
            </div>
            <div className="question-form-horizontal">
                <Container>
                    <Row>
                        <Col col={6} md={12}>
                            <div className="enrollment-checkbox">
                                <CheckBox
                                    id="groupLeader"
                                    name="groupLeader"
                                    checked={clientDetailsState.groupLeader}
                                    onChange={handleInputChange}
                                />
                                <label
                                    htmlFor="groupLeader"
                                    className={formLabelClassName(
                                        'groupLeader'
                                    )}
                                >
                                    {t('enrollment.groupLeader')}
                                </label>
                            </div>
                        </Col>
                        <Col col={6} md={12}>
                            <div className="enrollment-checkbox">
                                <CheckBox
                                    id="headOfHousehold"
                                    name="headOfHousehold"
                                    checked={clientDetailsState.headOfHousehold}
                                    onChange={handleInputChange}
                                />
                                <label
                                    htmlFor="groupLeader"
                                    className={formLabelClassName(
                                        'groupLeader'
                                    )}
                                >
                                    {t('enrollment.headOfHousehold')}
                                </label>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            {formType === 'modification' && (
                <>
                    <div className="question-form-vertical">
                        <label
                            htmlFor="reimbursementChoice"
                            className={formLabelClassName(
                                'reimbursementChoice'
                            )}
                        >
                            {t('enrollment.reimbursementChoice')}
                        </label>
                        <Select
                            id="reimbursementChoice"
                            name="reimbursementChoice"
                            value={clientDetailsState.reimbursementChoice}
                            data-testid="reimbursement-select"
                            onChange={handleInputChange}
                            options={[
                                {
                                    label: '----------',
                                    value: ''
                                },
                                {
                                    label: t('enrollment.toBeRollovered'),
                                    value: 'Rollover'
                                },
                                {
                                    label: t('enrollment.toBeReimbursed'),
                                    value: 'Reimbursement'
                                }
                            ]}
                        ></Select>
                    </div>

                    <div
                        className={`question-form-vertical ${
                            clientDetailsState.reimbursementChoice ===
                            'Reimbursement'
                                ? ''
                                : 'hidden'
                        }`}
                    >
                        <label
                            htmlFor="mobileMoneyPhone"
                            className={formLabelClassName('mobileMoneyPhone')}
                        >
                            {t('enrollment.mobileMoneyPhone')}
                        </label>
                        <TextInput
                            className={`${
                                clientDetailsState.reimbursementChoice ===
                                'Reimbursement'
                                    ? ''
                                    : 'hidden'
                            }`}
                            id="mobileMoneyPhone"
                            name="mobileMoneyPhone"
                            value={clientDetailsState.mobileMoneyPhone}
                            data-testid="mobile-money-phone-input"
                            onChange={handleInputChange}
                            errorValidation={
                                !!formValidationError?.mobileMoneyPhone
                            }
                        />
                        {formValidationError?.mobileMoneyPhone && (
                            <p
                                className="generic--error-message"
                                data-testid="client-details-error"
                            >
                                {formValidationError.mobileMoneyPhone}
                            </p>
                        )}
                    </div>
                </>
            )}
            {formType === 'enrollment' ? (
                <div className={'question-form-horizontal'}>
                    {displayVisitButton && (
                        <Button
                            className="enrollment-button btn-font-size-smaller"
                            style="primary"
                            onClick={handleVisitRecordNavigation}
                            data-testid="button-record-visit"
                            id="enrollment-btn-record-visit"
                        >
                            {t('enrollment.recordVisit')}
                        </Button>
                    )}
                    {canEnrollPackage && (
                        <Button
                            className="enrollment-button btn-font-size-smaller"
                            style="primary"
                            type="submit"
                            data-testid="button-step1"
                            id="enrollment-btn-step1"
                            onClick={handlePacketEnrollmentNavigation}
                        >
                            {t('enrollment.enrollForPackets')}
                        </Button>
                    )}
                </div>
            ) : (
                <Button
                    className="enrollment-button"
                    style="primary"
                    type="submit"
                    data-testid="button-step1"
                    id="enrollment-btn-step1"
                >
                    {buttonCTAText}
                </Button>
            )}
        </div>
    )
}
