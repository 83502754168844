import { ActionTypes } from '../actions/ActionTypes'

const initialState = {}

export const userPermissionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_USER_PERMISSIONS:
            return {
                ...state,
                loading: false,
                permissionsError: '',
                permissions: action.payload
            }
        case ActionTypes.FAIL_USER_PERMISSIONS:
            return {
                loading: false,
                permissionsError: action.payload,
                permissions: undefined
            }
        default:
            return state
    }
}
