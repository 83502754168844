import { ActionTypes } from 'actions'

export interface TransactionsInterface {
    villageId: string
}

export enum TransactionKind {
    VOUCHER_PAYMENT = 'VOUCHER_PAYMENT',
    MOBILE_MONEY_PAYMENT = 'MOBILE_MONEY_PAYMENT',
    DELIVERY = 'DELIVERY',
    PAYMENT_MODIFICATION = 'PAYMENT_MODIFICATION'
}

export type TransactionPayloadType = {
    type?: string
    client?: string
    client_code: string
    voucher_code?: string
    amount?: number
    date_received?: string
    village_id?: string
}

export type TransactionsPayloadType = {
    data?: TransactionPayloadType[]
    loading?: boolean
    error?: string
}

export type TransactionsStateType = {
    transactions: TransactionPayloadType[]
    loading: boolean
    error: string
}

type FetchingTransactionsActionType = {
    type: typeof ActionTypes.FETCHING_TRANSACTIONS
    payload?: string
}

type FetchTransactionsSuccessActionType = {
    type: typeof ActionTypes.FETCH_TRANSACTIONS_SUCCESS
    payload: TransactionPayloadType[]
}

type FetchTransactionsFailureActionType = {
    type: typeof ActionTypes.FETCH_TRANSACTIONS_FAILURE
    payload: string
}

type FetchTransactionsCompleteActionType = {
    type: typeof ActionTypes.FETCH_TRANSACTIONS_COMPLETE
    payload?: string
}

export type TransactionsActionType =
    | FetchingTransactionsActionType
    | FetchTransactionsSuccessActionType
    | FetchTransactionsFailureActionType
    | FetchTransactionsCompleteActionType
