export const partitionClients = (clients) => {
    const currentClients = []
    const previousClients = []

    clients.forEach((client) => {
        if (parseFloat(client.all_enrollment) > 0) {
            currentClients.push(client)
        } else {
            previousClients.push(client)
        }
    })

    return { currentClients, previousClients }
}
