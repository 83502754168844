import { Dispatch } from 'redux'
import { FarmerIdEnum, FarmerIdModalType, FarmerIdQRDataType } from 'storeTypes'

export const displayFarmerIdModalAction =
    (modalStatus: boolean) => (dispatch: Dispatch<FarmerIdModalType>) => {
        dispatch({
            type: FarmerIdEnum.DISPLAY_MODAL,
            payload: modalStatus
        })
    }

export const setFarmerIdQrDataAction =
    (data: FarmerIdQRDataType) => (dispatch: Dispatch) => {
        dispatch({
            type: FarmerIdEnum.SET_QR_DATA,
            payload: data
        })
    }
