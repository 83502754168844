import { ActionTypes } from 'actions'
import { mergeInteractions } from 'helpers/interactions/interactionHelper'
import {
    InteractionsStateType,
    InteractionActionTypes,
    InteractionPayloadType
} from 'storeTypes/interactionsTypes'

const initState: InteractionsStateType = {
    isInteractionRecorded: false,
    interactions: [],
    loading: {},
    error: ''
}

const extractInteractionKey = (action: InteractionActionTypes) => {
    if (action.surveyName && action.surveyVersion) {
        return `${action.surveyName}-${action.surveyVersion}`
    } else {
        return 'all'
    }
}

export const interactionsReducer = (
    state: InteractionsStateType = initState,
    action: InteractionActionTypes
): InteractionsStateType => {
    const interactionKey = extractInteractionKey(action)

    switch (action.type) {
        case ActionTypes.RECORD_INTERACTION_SUCCESS:
            return {
                ...state,
                isInteractionRecorded: true
            }
        case ActionTypes.RECORD_INTERACTION_FAILURE:
            return {
                ...state,
                error: 'Un problème est survenu'
            }
        case ActionTypes.FETCHING_INTERACTIONS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    [interactionKey]: true
                }
            }
        case ActionTypes.FETCH_INTERACTIONS_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    [interactionKey]: false
                },
                interactions: mergeInteractions(
                    state.interactions,
                    action.payload
                ) as InteractionPayloadType[]
            }
        case ActionTypes.FETCH_INTERACTIONS_FAILURE:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    [interactionKey]: false
                },
                error: 'Un problème est survenu'
            }
        default:
            return state
    }
}
