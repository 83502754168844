import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Stats } from '../stats/Stats'
import { ProgressBar } from '../progressBar/ProgressBar'

export const RegisteredDeposit = ({
    children,
    className,
    percentage,
    enrollmentPercentageRange,
    target,
    result,
    ...props
}) => {
    const { t } = useTranslation()
    return (
        <div
            className={['registered-progressbar--parent', className]
                .join(' ')
                .trim()}
            {...props}
        >
            <div className="registered-progressbar--label">
                <p>{children}</p>
                {target ? (
                    <Stats
                        className="registered-progressbar--stats"
                        ranges={
                            enrollmentPercentageRange && {
                                high: enrollmentPercentageRange.high,
                                low: enrollmentPercentageRange.low
                            }
                        }
                    >
                        {percentage}
                    </Stats>
                ) : (
                    ''
                )}
            </div>
            <div>
                <ProgressBar
                    percentage={percentage}
                    size="small"
                    ranges={
                        enrollmentPercentageRange && {
                            high: enrollmentPercentageRange.high,
                            low: enrollmentPercentageRange.low
                        }
                    }
                />
            </div>
            <div className="registered-progressbar--target-result">
                <p className="result">{result}</p>
                {target ? (
                    <span className="target--parent">
                        <p className="target--content target--of">{t('on')}</p>
                        <p className="target--content">{target}</p>
                    </span>
                ) : (
                    ''
                )}
            </div>
        </div>
    )
}

RegisteredDeposit.propTypes = {
    children: PropTypes.string,
    className: PropTypes.string,
    percentage: PropTypes.number,
    enrollmentPercentageRange: PropTypes.objectOf(PropTypes.number),
    target: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    result: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}
