import { SelectedPacket } from 'components/enrollForm'
import { GoalItemType } from 'pages/enrollment/enrollmentTypes'

export const ordersAreDifferent = (
    goalItems: GoalItemType[],
    selectedPackets: SelectedPacket[]
) => {
    if (!goalItems && !selectedPackets) return false
    if (goalItems?.length !== selectedPackets?.length) {
        return true
    }
    for (const goalItem of goalItems) {
        if (
            !selectedPackets.some((packet) => {
                return (
                    goalItem.product.id === packet.product_id &&
                    (goalItem.desired_units === packet.size ||
                        goalItem.price?.toString() === packet.price ||
                        goalItem.desired_units_display === packet.size_display)
                )
            })
        ) {
            return true
        }
    }
    return false
}
