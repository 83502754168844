import React, { useEffect, useState, useRef, useContext } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useSelector } from 'react-redux'
import {
    useParams,
    useNavigate,
    useSearchParams,
    useLocation
} from 'react-router-dom'
import {
    Card,
    Col,
    Container,
    Row,
    TopBar,
    CardClientDetails,
    Button,
    Spinner,
    Modal
} from '../../components'
import { useBackAction, useBindDispatch, useGetStorage } from '../../hooks'
import { VillageClientPacket, VillageClientPayment } from './'
import {
    amountFormatter,
    constants,
    generateUuidv4,
    isClientInfoComplete,
    routes
} from '../../helpers'
import { useTranslation } from 'react-i18next'
import ClientStatus from '../../components/card/ClientStatus'
import { ClientStatus as ClientStatusEnum } from '../../storeTypes/clientTypes'
import { findPermission } from '../../helpers/findPermission'
import { ClientInteractions, InteractionOutcomes } from '../../types'
import { AppModalContext } from '../../components/appModal/AppModalProvider'
import { ConfirmDuplicateFlagInteraction } from 'components/modal/ConfirmDuplicateFlagInteraction'

export const VillageClientDetails = () => {
    const [animate, setAnimate] = useState(false)
    const [animationList, setAnimationList] = useState(false)
    const [displayModal, setDisplayModal] = useState(false)
    const [paymentEnrollmentData, setPaymentEnrollmentData] = useState([])
    const { displayAppModal: showInteractionFeedbackModal } =
        useContext(AppModalContext)

    const [searchParams, setSearchParams] = useSearchParams()
    const reload = searchParams.get('reload')
    const fromURL = searchParams.get('from')

    const { pathname } = useLocation()

    const [clientPreCertify, setClientPreCertify] = useState(false)
    const nodeRef = useRef(null)

    const { t } = useTranslation()
    const navigate = useNavigate()
    const { clientData } = useSelector((state) => state.villageClientList)
    const { preCertified, loading: preCertifiedLoading } = useSelector(
        (state) => state.preCertified
    )
    const { enrollmentPayload } = useSelector((state) => state.updatePackages)
    const { loading: singleClientLoader, client: singleClient } = useSelector(
        (state) => state.singleClient
    )
    const { clientOrders } = useSelector((state) => state.clientOrders)

    const [clientDetails, setClientDetails] = useState({})
    const [clientPackages, setClientPackages] = useState([])
    const [btnProps, setBtnProps] = useState({
        title: t('sellCard'),
        onClick: '',
        disable: false
    })
    const {
        selectVillageClientHandler,
        navbarHandler,
        preCertifyClientHandler,
        updateSingleClientBalAndSmsHandler,
        sendInteractionsActionHandler,
        clearSingleClientDataHandler,
        fetchClientOrdersHandler,
        resetClientOrdersHandler,
        clientDetailsEnrollmentHandler
    } = useBindDispatch()
    const { villageId, clientId } = useParams()
    const { country, veVillages, username } = useGetStorage('connect_user')
    const userPermissions = useGetStorage(constants.USER_PERMISSIONS)
    const orders = useGetStorage(constants.SINGLE_CLIENT_ORDERS_KEY)

    // handles on device back button press or back swipe
    useBackAction(fromURL || `/village/${villageId}`)

    const canModifyClient = !clientDetails?.duplicate

    const canSellCard =
        findPermission(userPermissions, constants.ADD_CARD_SALE) &&
        canModifyClient
    const displayVisitButton =
        findPermission(userPermissions, constants.CAN_RECORD_HOME_VISIT) &&
        canModifyClient
    const preCertifyPermission = findPermission(
        userPermissions,
        constants.PRE_CERTIFY_CLIENT
    )
    const canPrecertify =
        btnProps?.title === t('village.preCertify') &&
        country === 'Senegal' &&
        preCertifyPermission &&
        canModifyClient

    const canDeliver =
        findPermission(userPermissions, constants.DELIVER_CLIENT) &&
        canModifyClient
    const client = JSON.parse(
        localStorage.getItem(constants.CLIENT_DETAIL_LOCAL_STORE)
    )
    // get village name
    const { name: villageName } = veVillages.find(
        ({ sf_id: sfId }) => sfId === villageId
    ) || { name: '' }
    const { openVillagePageHandler } = useBindDispatch()

    useEffect(() => {
        if (reload) {
            updateSingleClientBalAndSmsHandler({
                villageId,
                clientId
            })
        }
    }, [reload])

    useEffect(() => {
        if (singleClient.length > 0) {
            searchParams.delete('reload')
            setSearchParams(searchParams)
            let updatedClientData
            if (Object.keys(paymentEnrollmentData).length > 0) {
                updatedClientData = {
                    ...singleClient[0],
                    all_enrollment: paymentEnrollmentData.allEnrollment,
                    fullname: `${singleClient[0].firstname} ${singleClient[0].lastname}`,
                    zone: paymentEnrollmentData.zone
                }
            } else {
                updatedClientData = {
                    ...singleClient[0],
                    fullname: `${singleClient[0].firstname} ${singleClient[0].lastname}`
                }
            }
            localStorage.setItem(
                constants.CLIENT_DETAIL_LOCAL_STORE,
                JSON.stringify(updatedClientData)
            )

            setClientDetails(updatedClientData)
        }
    }, [singleClientLoader, singleClient])
    // show menu bar
    useEffect(() => {
        setAnimate(true)
        navbarHandler(constants.SHOW_NAVBAR)
        document.title = t('clientDetails.pageTitle')

        return () => {
            clearSingleClientDataHandler()
        }
    }, [])

    useEffect(() => {
        selectVillageClientHandler({ clientId, villageId })
        fetchClientOrdersHandler(clientId, villageId)
    }, [])

    const isClientLocalStoreValid = (clientData) => {
        return clientData?.client_code === client?.client_code
    }

    // locally store client details
    useEffect(() => {
        if (
            clientData?.client_code &&
            !isClientLocalStoreValid(clientData) &&
            clientData?.client_code === clientId
        ) {
            const updatedClientData = {
                ...clientData,
                fullname: `${clientData.firstname} ${clientData.lastname}`
            }
            setClientDetails(updatedClientData)
            setClientPreCertify(clientData.certified_for_delivery === 'True')
            localStorage.setItem(
                constants.CLIENT_DETAIL_LOCAL_STORE,
                JSON.stringify(updatedClientData)
            )
        } else {
            if (client) {
                setClientDetails(client)
            }
        }
    }, [clientData])

    useEffect(() => {
        if (clientOrders?.length > 0) {
            setClientPackages(clientOrders)
            localStorage.setItem(
                constants.SINGLE_CLIENT_ORDERS_KEY,
                JSON.stringify(clientOrders)
            )
        } else if (orders) {
            setClientPackages(orders)
        }
    }, [clientOrders])

    useEffect(() => {
        return () => {
            resetClientOrdersHandler()
        }
    }, [])

    useEffect(() => {
        if (enrollmentPayload?.client_code) {
            setPaymentEnrollmentData({
                allEnrollment: enrollmentPayload.all_enrollment,
                zone: enrollmentPayload.zone
            })
            const updatedClientData = {
                ...enrollmentPayload,
                fullname: `${enrollmentPayload.firstname} ${enrollmentPayload.lastname}`
            }
            setClientDetails(updatedClientData)
            localStorage.setItem(
                constants.CLIENT_DETAIL_LOCAL_STORE,
                JSON.stringify(updatedClientData)
            )
        }
    }, [enrollmentPayload])

    // update pre-certified client star
    useEffect(() => {
        // fetch updated client certification
        const { certified_for_delivery: certifiedForDelivery } = JSON.parse(
            localStorage.getItem('client_details')
        ) || { certified_for_delivery: 'False' }
        setClientPreCertify(certifiedForDelivery === 'True')
    }, [preCertified])

    useEffect(() => {
        changeBtnText(client)
    }, [clientDetails, client?.certified_for_delivery, btnProps.title])

    const getCardSaleUrl = () => {
        return `/cards/card-sale?client-code=${clientDetails?.client_code}&village-id=${villageId}`
    }

    const changeBtnText = (client) => {
        const deliveredItems = client?.goal_items?.filter(
            (item) => item.delivered
        )
        const disableDelivered =
            deliveredItems?.length === client?.goal_items?.length || !canDeliver

        if (
            parseInt(client?.balance) >= parseInt(client?.all_enrollment) &&
            client?.certified_for_delivery === 'True'
        ) {
            return setBtnProps({
                title: t('village.deliver'),
                onClick: () => setDisplayModal(true),
                disable: disableDelivered
            })
        }
        if (
            parseInt(client?.balance) >= parseInt(client?.all_enrollment) &&
            client?.certified_for_delivery === 'False'
        ) {
            return setBtnProps({
                title: t('village.preCertify'),
                onClick: preCertifyHandler,
                disable: !canPrecertify
            })
        }
        return setBtnProps({
            title: t('sellCard'),
            onClick: getCardSaleUrl(),
            disable: !canSellCard
        })
    }

    const saveClientInLocalStore = (clientData) => {
        localStorage.setItem(
            constants.CLIENT_FOR_ENROLLMENT_STORE,
            JSON.stringify({
                ...clientData,
                goal_items: clientPackages?.filter((item) => !item.delivered)
            })
        )
    }

    const modifyHandler = (modificationType, nextURL = '') => {
        if (modificationType) {
            // include form type and village info in client data
            const localClientData = JSON.parse(
                localStorage.getItem(constants.CLIENT_DETAIL_LOCAL_STORE)
            )
            localClientData.form_type = 'modification'
            localClientData.village_name = villageName
            localClientData.village = villageId
            const next = nextURL ? `&next=${nextURL}` : ''
            // Navigate to the new enrollment flow
            saveClientInLocalStore({
                ...localClientData,
                modification: modificationType.includes('package')
                    ? constants.CLIENT_MODIFICATION_TYPES.PACKAGES
                    : constants.CLIENT_MODIFICATION_TYPES.DETAILS
            })
            return navigate(
                `/${routes.enroll}/${localClientData.village}?from=${pathname}${next}`
            )
        } else btnProps.onClick()
    }

    const backNavigationHandler = () => {
        openVillagePageHandler({
            filterOptions: '',
            villageId,
            clientTab: constants.VILLAGE_PAGE_TABS.CURRENT_CLIENT_TAB
        })
        navigate(fromURL || `/village/${villageId}`)
    }

    const openVisitPageHandler = () => {
        const localClientData = JSON.parse(
            localStorage.getItem(constants.CLIENT_DETAIL_LOCAL_STORE)
        )
        if (isClientInfoComplete(localClientData)) {
            localStorage.setItem(
                constants.CLIENT_FOR_VISIT_STORE,
                JSON.stringify({
                    clientCode: clientDetails.client_code,
                    villageId: clientDetails.village_id || villageId,
                    clientName: clientDetails.firstname,
                    origin: constants.ORIGIN_CLIENT_DETAIL
                })
            )
            return navigate('/visit')
        } else {
            modifyHandler(constants.CLIENT_MODIFICATION_TYPES.DETAILS, '/visit')
        }
    }

    // handle pre-certification of client
    const preCertifyHandler = async () => {
        const payload = {
            client_code: clientDetails.client_code,
            client_sfid: clientDetails.sf_id,
            village_sfid: clientDetails.village_id || villageId,
            certified: true,
            certified_date: new Date(),
            uuid: generateUuidv4()
        }

        preCertifyClientHandler({ payload })
    }

    const markClientAsDuplicate = () => {
        clientDetailsEnrollmentHandler({
            clientDetailsPayload: {
                ...clientDetails,
                duplicate: true,
                village: villageId,
                form_type: 'modification',
                username,
                uuid: generateUuidv4(),
                date: new Date().toISOString()
            }
        })
        sendInteractionsActionHandler({
            client_code: clientId,
            user_id: username,
            outcome: InteractionOutcomes.DUPLICATE,
            client_status: clientDetails.client_status,
            survey_name: constants.TARGET_LIST_SURVEY_NAME,
            survey_version: constants.TARGET_LIST_SURVEY_VERSION,
            village_id: villageId
        })
        showInteractionFeedbackModal(t('clientDetails.interactionRecorded'))
        const updatedClient = {
            ...clientDetails,
            duplicate: true
        }
        localStorage.setItem(
            constants.CLIENT_DETAIL_LOCAL_STORE,
            JSON.stringify(updatedClient)
        )
        setDisplayModal(false)
        setClientDetails(updatedClient)
    }

    const interactionHandler = (interaction) => {
        if (interaction.name === ClientInteractions.DUPLICATE_FLAG) {
            return setDisplayModal(true)
        }

        sendInteractionsActionHandler({
            ...interaction,
            client_code: clientId,
            user_id: username,
            outcome: interaction.outcome,
            client_status: clientDetails.client_status,
            survey_name: constants.TARGET_LIST_SURVEY_NAME,
            survey_version: constants.TARGET_LIST_SURVEY_VERSION,
            village_id: villageId
        })
        showInteractionFeedbackModal(t('clientDetails.interactionRecorded'))
        if (!isClientInfoComplete(clientDetails)) {
            const nextUrl =
                interaction.name === ClientInteractions.CARD_PAYMENT
                    ? getCardSaleUrl()
                    : ''
            localStorage.setItem(
                constants.CLIENT_FOR_ENROLLMENT_STORE,
                JSON.stringify(clientDetails)
            )
            return modifyHandler(
                constants.CLIENT_MODIFICATION_TYPES.DETAILS,
                nextUrl
            )
        }
        if (interaction.name === ClientInteractions.CARD_PAYMENT) {
            navigate(getCardSaleUrl())
        }
    }

    const villageClientDetailsRender = (clientDetailsData) => {
        const {
            fullname,
            client_code: clientCode,
            sex: gender,
            group_role: groupRole,
            saving_group_name: savingGroupName,
            zone,
            phone,
            phone_2: phone2,
            reimbursement_choice: reimbursementChoice,
            all_enrollment: allEnrollment,
            balance,
            last_voucher_date: lastVoucherDate
        } = clientDetailsData
        const remainingToPay = parseFloat(allEnrollment - balance)
        const translatedGender = !gender
            ? '-'
            : gender === 'Male'
            ? t('man')
            : t('woman')

        return (
            <>
                <div className="header-bar" id="header-bar">
                    <Container>
                        <TopBar back backNavigation={backNavigationHandler}>
                            {t('village.village')} {villageName}
                        </TopBar>
                    </Container>
                </div>
                <CSSTransition
                    unmountOnExit
                    timeout={constants.ANIMATION_TIMEOUT}
                    in={animate}
                    classNames="generic"
                    appear
                    onEnter={() => setAnimationList(true)}
                    nodeRef={nodeRef}
                >
                    <div
                        className="village-client-details-parent page-wrapper"
                        data-testid="client-details-page"
                        ref={nodeRef}
                    >
                        <Container>
                            <Row>
                                <Col md={12} lg={4}>
                                    <ClientStatus
                                        status={
                                            clientDetails.duplicate
                                                ? ClientStatusEnum.DUPLICATE
                                                : clientDetails.client_status
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <Card
                                        shadow
                                        className={
                                            animationList
                                                ? 'height-100 animate-show-1 animate-showed-1'
                                                : 'height-100 animate-show-1'
                                        }
                                    >
                                        <CardClientDetails
                                            clientCode={clientCode}
                                            clientPhone={phone}
                                            modifyHandler={() =>
                                                modifyHandler()
                                            }
                                            interactionHandler={
                                                interactionHandler
                                            }
                                            disableEdit={btnProps.disable}
                                            buttonProps={btnProps}
                                            otherClientData={{
                                                [t('gender')]: translatedGender,
                                                [t('groupRole')]:
                                                    groupRole || 'Non',
                                                [t('savingGroupName')]:
                                                    savingGroupName || 'Non',
                                                [t('zone')]: zone,
                                                [t('personalPhone')]:
                                                    amountFormatter(phone),
                                                [t('enrollment.phone2')]: phone2
                                                    ? amountFormatter(phone2)
                                                    : '',
                                                [t('reimbursementChoice')]:
                                                    reimbursementChoice
                                            }}
                                            preCertified={clientPreCertify}
                                            preCertifiedButtonLoader={
                                                preCertifiedLoading
                                            }
                                            country={country}
                                            allEnrollment={amountFormatter(
                                                allEnrollment,
                                                country
                                            )}
                                            balance={balance}
                                            isClientDuplicate={
                                                clientDetails?.duplicate
                                            }
                                            clientStatus={
                                                clientDetails.client_status
                                            }
                                        >
                                            {fullname}
                                        </CardClientDetails>
                                    </Card>
                                </Col>
                                <Col md={8} className="display-flex fd-col">
                                    <Col
                                        md={12}
                                        className={
                                            animationList
                                                ? 'clear-h-padding flex-0 animate-show-3 animate-showed-3'
                                                : 'clear-h-padding flex-0 animate-show-3'
                                        }
                                    >
                                        <VillageClientPacket
                                            goalItems={clientPackages}
                                            clientId={clientId}
                                            allowModification={canModifyClient}
                                            modifyHandler={() =>
                                                modifyHandler(
                                                    constants
                                                        .CLIENT_MODIFICATION_TYPES
                                                        .PACKAGES
                                                )
                                            }
                                        />
                                    </Col>
                                    <Col
                                        md={12}
                                        className={
                                            animationList
                                                ? 'clear-h-padding flex-1 village-payment--col flex-0 animate-show-5 animate-showed-5'
                                                : 'clear-h-padding flex-1 village-payment--col flex-0 animate-show-5'
                                        }
                                    >
                                        <VillageClientPayment
                                            allEnrollment={amountFormatter(
                                                allEnrollment,
                                                country
                                            )}
                                            balance={amountFormatter(
                                                balance,
                                                country
                                            )}
                                            paymentLeft={amountFormatter(
                                                remainingToPay,
                                                country
                                            )}
                                            lastVoucherDate={
                                                lastVoucherDate
                                                    ? t('dateMonthYear', {
                                                          date: new Date(
                                                              lastVoucherDate
                                                          )
                                                      })
                                                    : '-'
                                            }
                                            percentage={parseFloat(
                                                (balance / allEnrollment) * 100
                                            )}
                                            clientCode={clientCode}
                                            preCertifyHandler={
                                                preCertifyHandler
                                            }
                                            country={country}
                                            preCertified={clientPreCertify}
                                            preCertifiedButtonLoader={
                                                preCertifiedLoading
                                            }
                                        />
                                    </Col>
                                    {displayVisitButton && (
                                        <div className="visit-new-container">
                                            <Button
                                                className="visit-new-btn"
                                                style="primary"
                                                onClick={openVisitPageHandler}
                                                data-testid="button-new-visit"
                                                id="btn-new-visit"
                                            >
                                                {t('enrollment.newVisit')}
                                            </Button>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </CSSTransition>
                <Modal
                    showModal={displayModal}
                    closeClickHandler={() => setDisplayModal(false)}
                    hideButtons
                    showCloseIconBtn
                    position="center"
                    className="duplicate-confirm-modal"
                    data-testid="mark-client-duplicate-modal"
                >
                    <ConfirmDuplicateFlagInteraction
                        clientCode={clientDetails?.client_code}
                        clientNames={clientDetails?.fullname}
                        handleOnClick={markClientAsDuplicate}
                    />
                </Modal>
            </>
        )
    }

    const singleClientLoading = (
        <Container>
            <Row>
                <Col md={12}>
                    <div className="loading-wrapper">
                        <p className="loading-text">
                            {t('fetchingPaymentLoader')}
                        </p>
                        <Spinner size="120" />
                    </div>
                </Col>
            </Row>
        </Container>
    )

    const villageClientDetailPage =
        (clientDetails || enrollmentPayload) && singleClientLoader
            ? singleClientLoading
            : villageClientDetailsRender(clientDetails)

    return villageClientDetailPage
}
