import React, { useState, Children } from 'react'
import PropTypes from 'prop-types'
import './tab.css'

export const Tab = ({ children, activeTabHandler, initialTab, ...props }) => {
    const [activeTab, setActiveTab] = useState({
        status: true,
        key:
            initialTab ||
            JSON.parse(localStorage.getItem('active_tab'))?.key ||
            0
    })
    const tabChildren = Children.toArray(children)
    return (
        <div
            className={[
                'tab-primary',
                'tab-pos',
                `tab-pos-${activeTab.key}`
            ].join(' ')}
            {...props}
        >
            {tabChildren.map((child, idx) =>
                React.cloneElement(child, {
                    activeTab,
                    setActiveTab,
                    idx,
                    key: idx,
                    activeTabHandler
                })
            )}
        </div>
    )
}

Tab.propTypes = {
    children: PropTypes.array,
    activeTabHandler: PropTypes.func,
    initialTab: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}
