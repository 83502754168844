interface ErrorResponseInterface {
    response: {
        data: {
            code: string
            message: string
            detail: string
        }
    }
}

export class ErrorResponse implements ErrorResponseInterface {
    response: {
        data: {
            code: string
            message: string
            detail: string
        }
    }

    constructor(code: string, message: string, detail: string) {
        this.response = { data: { code, message, detail } }
    }
}
