import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { compact } from 'lodash'

import { TargetListInterface } from './targetTypes'
import { useBindDispatch } from 'hooks'
import { constants, searchHandler, sortTargetList } from '../../helpers'
import {
    TargetPayloadType,
    ClientDetailsPayloadInterface,
    InteractionPayloadType
} from 'storeTypes'

import { Col, Row, Spinner, TextInput } from '../../components'
import { Target } from './Target'
import { RootState } from '../../store/configStore'
import { TargetFilter } from './TargetFilter'

export const TargetList = ({
    targetScrollRef,
    veCode
}: TargetListInterface) => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const {
        fetchTargetListHandler,
        processTargetListHandler,
        filterTargetListHandler,
        fetchInteractionsHandler
    } = useBindDispatch()

    const { clientList, loading: clientListLoading } = useSelector(
        ({ villageClientList }: RootState) => villageClientList
    )
    const { previousClientList, loading: previousClientListLoading } =
        useSelector(
            ({ villagePreviousClientList }: RootState) =>
                villagePreviousClientList
        )

    const {
        loading: targetListLoading,
        targetList,
        processing: targetListProcessing,
        processedTargetList,
        selectedFilter: storeSelectedFilter
    } = useSelector(({ targetList }: RootState) => targetList)

    const [searchResults, setSearchResults] = useState(processedTargetList)

    const { interactions = [], loading: loadingInteractions } = useSelector(
        ({ interaction }: RootState) => interaction
    )
    const interactionType = `${constants.TARGET_LIST_SURVEY_NAME}-${constants.TARGET_LIST_SURVEY_VERSION}`
    const loadingTargetListInteractions =
        loadingInteractions && loadingInteractions[interactionType]
    const [searchValue, setSearchValue] = useState('')

    useEffect(() => {
        if ((processedTargetList?.length || 0) < 1) {
            fetchTargetListHandler({ veCode })
        }

        localStorage.removeItem(constants.CLIENT_DETAIL_LOCAL_STORE)
        localStorage.removeItem(constants.SINGLE_CLIENT_ORDERS_KEY)
    }, [])

    useEffect(() => {
        if (targetList.length > 0) {
            const clientCodes = compact(
                targetList.map(
                    (target: TargetPayloadType) => target.client_code
                )
            )
            fetchInteractionsHandler({
                clientCodes,
                surveyName: constants.TARGET_LIST_SURVEY_NAME,
                surveyVersion: constants.TARGET_LIST_SURVEY_VERSION
            })
        }
    }, [targetList])

    useEffect(() => {
        clientSearchHandler()
    }, [searchValue, processedTargetList])

    useEffect(() => {
        if (
            !clientListLoading &&
            !previousClientListLoading &&
            !loadingTargetListInteractions &&
            targetList.length > 0
        ) {
            const clients = [
                ...Object.values(clientList),
                ...Object.values(previousClientList)
            ].flat() as ClientDetailsPayloadInterface[]

            const clientCodes = compact(
                targetList.map(
                    (target: TargetPayloadType) => target.client_code
                )
            )
            processTargetListHandler({
                targetList,
                clients,
                interactions: (interactions as InteractionPayloadType[]).filter(
                    (interaction) =>
                        clientCodes.includes(interaction.client_code) &&
                        interaction.survey_version ===
                            constants.TARGET_LIST_SURVEY_VERSION
                ),
                selectedFilter: storeSelectedFilter
            })
        }
    }, [
        loadingTargetListInteractions,
        targetList,
        clientList,
        previousClientList
    ])

    const onSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        const { value } = e.target
        setSearchValue(value)
    }

    const clientSearchHandler = () => {
        if (searchValue.trim().length > 2) {
            return setSearchResults(
                searchHandler(processedTargetList || [], {
                    fullname: searchValue,
                    reversed_fullname: searchValue,
                    client_code: searchValue
                })
            )
        }
        setSearchResults(processedTargetList)
    }

    const targetListClickHandler = (
        villageId = '',
        clientCode = '',
        clientType = ''
    ) => {
        const clientTypeRoute = clientType === 'previous' ? 'previous' : ''

        navigate(
            `/village/${villageId}/client/${clientCode}/${clientTypeRoute}`
        )
    }

    return targetListLoading || targetListProcessing ? (
        <div className="target-list--spinner-parent">
            <Spinner
                data-testid="loading-indicator"
                size="50"
                pageSpinner={false}
                fullscreen={false}
            />
        </div>
    ) : (
        ((processedTargetList?.length || 0) > 0 ||
            (storeSelectedFilter !== constants.TARGET_LIST_FILTERS.ALL &&
                storeSelectedFilter)) && (
            <div ref={targetScrollRef} data-testid="target-list">
                <Row>
                    <Col md={12}>
                        <h2 className="target-list--header target-list--parent">
                            {t('targetList.targetListHeaderTitle')}
                        </h2>
                        <TextInput
                            placeholder={t('targetList.searchPlaceholder')}
                            onChange={(e) => onSearchInput(e)}
                            value={searchValue}
                            data-testid="search-input"
                            id="client-list-search"
                            className="target-list--search-input"
                        />
                        <div>
                            {
                                <TargetFilter
                                    filterHandler={filterTargetListHandler}
                                />
                            }
                        </div>
                    </Col>
                    <div className="card-session target-list--card-session">
                        <div>
                            {sortTargetList(searchResults || []).map(
                                (singleTarget) => (
                                    <Target
                                        targetData={singleTarget}
                                        key={singleTarget.uuid}
                                        targetOnClick={() => {
                                            targetListClickHandler(
                                                singleTarget.village_id,
                                                singleTarget.client_code,
                                                singleTarget.client_type
                                            )
                                        }}
                                    />
                                )
                            )}
                        </div>
                    </div>
                </Row>
            </div>
        )
    )
}
