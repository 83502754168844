import React, { Children } from 'react'
import PropTypes from 'prop-types'
import { CardClientContext } from '../../contexts'
import { useTranslation } from 'react-i18next'

export const CardClientPayment = ({
    children,
    lastPaymentDate,
    paymentCompleted,
    clientStatus,
    lastYearDelivered,
    langTranslate = false,
    paymentBalance,
    ...props
}) => {
    const { t } = useTranslation()
    const childrenArray = Children.toArray(children)

    const formattedLastPaymentDate = langTranslate
        ? t('dayMonth', {
              date: new Date(lastPaymentDate)
          })
        : lastPaymentDate

    return (
        <CardClientContext.Provider
            value={{
                formattedLastPaymentDate,
                paymentCompleted,
                clientStatus,
                lastYearDelivered,
                paymentBalance
            }}
        >
            <div
                className="card-client-parent card-client-text-color"
                {...props}
            >
                {childrenArray}
            </div>
        </CardClientContext.Provider>
    )
}

CardClientPayment.propTypes = {
    children: PropTypes.any,
    lastPaymentDate: PropTypes.instanceOf(Date),
    paymentCompleted: PropTypes.bool,
    clientStatus: PropTypes.string,
    lastYearDelivered: PropTypes.string,
    langTranslate: PropTypes.bool,
    paymentBalance: PropTypes.string
}
