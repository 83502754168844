import React from 'react'
import { RadioButtonInterface } from './radioButtonTypes'
import './radioButton.css'

export const RadioButton: React.FC<RadioButtonInterface> = ({
    name,
    value,
    labelName,
    onChange,
    checked,
    'data-testid': dataTestId,
    id
}) => {
    const inputID = id || value?.replace(/\s/g, '').toLocaleLowerCase()
    return (
        <div className="radio-button" id="radio-button">
            <input
                type="radio"
                name={name}
                id={inputID}
                value={value}
                onChange={onChange}
                checked={checked}
                data-testid={dataTestId}
            />
            <label htmlFor={inputID} className="radio-button--label">
                {labelName}
            </label>
        </div>
    )
}
