import { ClientType } from 'storeTypes'

export const buildClientUrl = (client: Partial<ClientType>) => {
    const isPrevious = (client?.all_enrollment || 0) <= 0

    if (isPrevious) {
        return `/village/${client.village_id}/client/${client.client_code}/previous`
    }

    return `/village/${client.village_id}/client/${client.client_code}`
}
