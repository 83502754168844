/* eslint-disable */
export enum InteractionOutcomes {
    ENROLL = 'ENROLL',
    CANCEL_PACKAGE = 'CANCEL_PACKAGE',
    ENROLLED = 'ENROLLED',
    FOLLOW_UP = 'FOLLOW_UP',
    NOT_INTERESTED = 'NOT_INTERESTED',
    REIMBURSEMENT_REQUESTED = 'REIMBURSEMENT_REQUESTED',
    CARD_PAYMENT = 'CARD_PAYMENT',
    MOBILE_MONEY_PAYMENT = 'MOBILE_MONEY_PAYMENT',
    DUPLICATE = 'DUPLICATE'
}
/* eslint-enable */
