import React from 'react'

export const InputErrorMessage: React.FC<{ errorMessage: string }> = ({
    errorMessage
}) => {
    return (
        <p className="generic--error-message" data-testid="client-search-error">
            {errorMessage}
        </p>
    )
}
