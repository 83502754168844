import { constants } from './'

export const amountFormatter = (num, country) => {
    let splitNumber = ''
    if (num) {
        splitNumber = num.toString().split('.')
        splitNumber[0] = splitNumber[0]
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ') // regex to add space after the third character count e.g 1000 will be 1 000
        splitNumber = splitNumber.join('.')
    }

    const currency = country ? constants[country.toUpperCase()] : ''
    return `${splitNumber || 0} ${currency}`
}
