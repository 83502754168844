import { InteractionPayloadType } from '../storeTypes'
import { differenceBy, intersectionBy, groupBy, compact } from 'lodash'

const hasExpired = (interaction: InteractionPayloadType) => {
    const expirationDate = interaction.expirationDate
    if (!expirationDate) return false

    const currentTime = new Date().getTime()
    const expiredTime = new Date(expirationDate).getTime()
    return currentTime > expiredTime
}

const expireInteraction = (interaction: InteractionPayloadType) => {
    interaction._deleted = true
    return interaction
}

const keyExtractor = (interaction: InteractionPayloadType) =>
    `${interaction.client_code}-${interaction.survey_name}-${interaction.survey_version}`

const groupByExpired = (interactions: InteractionPayloadType[]) => {
    const grouped = groupBy(interactions, hasExpired)

    return {
        valid: (grouped.false || []) as InteractionPayloadType[],
        expired: (grouped.true || []) as InteractionPayloadType[]
    }
}

export const resolveInteractions = (
    localInteractions: InteractionPayloadType[],
    remoteInteractions: InteractionPayloadType[]
) => {
    const localOnlyInteractions = differenceBy(
        localInteractions,
        remoteInteractions,
        keyExtractor
    )

    const remoteOnlyInteractions = differenceBy(
        remoteInteractions,
        localInteractions,
        keyExtractor
    )

    const intersectionInteractions = intersectionBy(
        localInteractions,
        remoteInteractions,
        keyExtractor
    )

    const resolvedInteractions = compact(
        intersectionInteractions.map((interaction) => {
            if (hasExpired(interaction)) {
                return remoteInteractions.find(
                    (remoteInteraction) =>
                        keyExtractor(remoteInteraction) ===
                        keyExtractor(interaction)
                )
            } else {
                return interaction
            }
        })
    )

    const { valid: validLocalInteractions, expired: expiredLocalInteractions } =
        groupByExpired(localOnlyInteractions)

    return validLocalInteractions
        .concat(expiredLocalInteractions.map(expireInteraction))
        .concat(remoteOnlyInteractions)
        .concat(resolvedInteractions)
}
