import { minimalValidationRules } from './validationRules'
import { useGetStorage } from '../hooks/useGetStorage'
import { constants } from './constants'

const isClientInfoValid = (clientData, validationRules) => {
    let isValid = true
    Object.keys(validationRules).forEach((fieldName) => {
        const rules = validationRules[fieldName]
        let fieldError
        rules.some((rule) => {
            fieldError = rule(clientData[fieldName])
            return fieldError !== undefined
        })
        if (fieldError) {
            isValid = false
        }
    })
    return isValid
}

export const isClientInfoComplete = (clientData) => {
    const { country } = useGetStorage(constants.CONNECT_USER)
    const enrollmentValidationRules = minimalValidationRules({
        country
    })
    return isClientInfoValid(clientData, enrollmentValidationRules)
}
