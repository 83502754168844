import { useState, useEffect } from 'react'

const getScreenSize = () => {
    const { innerWidth: screenWidth, innerHeight: screenHeight } = window
    return {
        screenWidth,
        screenHeight
    }
}

export const useScreenSize = () => {
    const [screenSize, setScreenSize] = useState(getScreenSize())

    useEffect(() => {
        const handleResize = () => {
            setScreenSize(getScreenSize())
        }
        window.addEventListener('resize', handleResize)
        return () => window.addEventListener('resize', handleResize)
    }, [])

    return screenSize
}
