import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { useBindDispatch, useGetStorage } from '../../hooks'
import {
    constants,
    formatPayloadToClientObject,
    generateUuidv4,
    getUserLocation
} from '../../helpers'
import type { RootState, AppDispatch } from '../../store/configStore'
import {
    EnrollmentConfirmStateType,
    GpsCoords,
    ProductInfo,
    SelectedPacket,
    ZoneInfoTemplate
} from '../../components/enrollForm/enrollFormTypes'
import { transitionToStep } from '../../helpers/packageSelectionHelpers'
import {
    Col,
    Container,
    Row,
    Spinner,
    TopBar,
    PacketSelectionPage,
    ProductSelectionPage
} from '../../components'
import TitleStep from '../../components/enrollForm/TitleStep'
import { EnrollmentConfirm } from '../../components/enrollForm/EnrollmentConfirm'
import { findPermission } from '../../helpers/findPermission'
import {
    getDemographicSummary,
    getPaymentSummary,
    mapClientDataToSummary
} from '../../helpers/enrollmentConfirmHelpers'
import { To, useNavigate, useSearchParams } from 'react-router-dom'
import { ClientStatus, EnrollmentInterface } from '../../storeTypes'
import { useTrackTimedEvent } from 'hooks/useTrackTimedEvent'
import { useTranslation } from 'react-i18next'
import { PaymentSelection } from 'components/enrollForm/PaymentSelection'
import { GoalItemType } from './enrollmentTypes'

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

type UserType = {
    zoneSFID: string
    formPayload: {}
    country: string
    username: string
}

export const PackageSelectionPage: FC = () => {
    const [searchParams] = useSearchParams()
    const [formTypeParams] = useSearchParams()
    const formRef = useRef<HTMLDivElement>(null)
    const navigate = useNavigate()
    const stepUrl = searchParams.get('step')
    const formTypeParam = formTypeParams.get('form_type')
    const { trackTimedEvent } = useTrackTimedEvent()

    const {
        updatePackagesActionHandler,
        productListHandler,
        clearCardPaymentResultHandler,
        sendInteractionsActionHandler,
        resetSelectVillageClientActionHandler
    } = useBindDispatch()

    const [voucherCode, setVoucher] = useState('')
    const [selectedPaymentType, setSelectedPaymentType] = useState('')
    const [selectedPackets, setSelectedPackets] = useState<SelectedPacket[]>([])
    const [totalToPay, setTotalToPay] = useState<number>(0)
    const [showPaymentSelection, setShowPaymentSelection] =
        useState<boolean>(false)

    const [step, setStep] = useState<number>(1)
    // eslint-disable-next-line
    const [fetchingLocation, setFetchingLocation] = useState<boolean>(false)
    const [hideClientDemoGraphic, setHideDemo] = useState<boolean>(true)
    const [geoLocation, setGeoLocation] = useState<GpsCoords>({
        latitude: null,
        longitude: null,
        accuracy: null
    })
    const [selectedProduct, setSelectedProduct] = useState<ProductInfo>()

    const { productListData, loading, preFetchLoading } = useAppSelector(
        (store) => store.enrollment
    )

    const { clientDetailsPayload, gpsCoords } = useAppSelector(
        (store) => store.clientDetailEnrollment
    )

    const { paymentResponse } = useAppSelector(
        (state) => state.cardPaymentResult
    )

    const currentClient = useGetStorage(constants.CLIENT_FOR_ENROLLMENT_STORE)
    const { t } = useTranslation()

    useEffect(() => {
        if (stepUrl) {
            changeStep(parseInt(stepUrl))
        }
    }, [stepUrl])

    useEffect(() => {
        if (currentClient?.payment_flow === 'card payment') {
            const { allSelectedPackets, voucherCode, paymentMethod } =
                currentClient
            setSelectedPaymentType(paymentMethod)
            setVoucher(voucherCode)
            setSelectedPackets(allSelectedPackets.selectedPackets)
        }
    }, [])

    const zone = useGetStorage('connect_user').zoneSFID
    const zoneProductsInfo = useMemo(
        () =>
            productListData?.find(
                (product: ZoneInfoTemplate) => product.zone_sf_id === zone
            ) as unknown as ZoneInfoTemplate,
        [productListData]
    )

    useEffect(() => {
        if (currentClient?.goal_items?.length && productListData?.length) {
            const preSelectedPackets = currentClient.goal_items.reduce(
                (selectedPackets: SelectedPacket[], order: GoalItemType) => {
                    const product = zoneProductsInfo.product_list.find(
                        (product: ProductInfo) =>
                            product.sf_id === order.product.id
                    )

                    selectedPackets.push({
                        product_id: order.product.id,
                        pricebook_id:
                            product?.product_prices[0]?.pricebook?.sf_id || '',
                        size: order.desired_units,
                        size_display: order.desired_units_display,
                        name: order.product.name,
                        hidePrice: product?.hide_price || false,
                        price: order.price?.toString(),
                        unit: product?.unit || '',
                        uuid: order.uuid
                    })
                    return selectedPackets
                },
                []
            )
            setSelectedPackets(selectedPackets.concat(preSelectedPackets))
        }
    }, [zoneProductsInfo])

    useEffect(() => {
        if (clientDetailsPayload) {
            localStorage.setItem(
                constants.CLIENT_FOR_ENROLLMENT_STORE,
                JSON.stringify(clientDetailsPayload)
            )
        }
    }, [clientDetailsPayload])

    const user: UserType = useGetStorage('connect_user')
    const submitted = useRef(false)

    const userPermissions = useGetStorage(constants.USER_PERMISSIONS)
    const canUpdateOrder: boolean = findPermission(
        userPermissions,
        constants.UPDATE_ORDER
    )
    const canViewCardQrPage = findPermission(
        userPermissions,
        constants.CAN_VIEW_CARD_QR
    )
    const canMakeDirectPaymentPermission = findPermission(
        userPermissions,
        constants.DIRECT_CARD_PAYMENT
    )

    const generateDemographicSummary = () => {
        if (hideClientDemoGraphic) {
            return null
        }
        return getDemographicSummary(
            mapClientDataToSummary(zoneProductsInfo, currentClient)
        )
    }

    const handlePacketConfirmation = () => {
        if (
            currentClient?.form_type !== 'modification' &&
            !selectedPaymentType
        ) {
            setShowPaymentSelection(true)
        } else {
            changeStep(2)
        }
    }

    const generatePaymentSummary = () => {
        const paymentData = {
            paymentCardAmount: '0',
            voucherCode: ''
        }
        const cardPaymentResponse:
            | {
                  status: string
                  amount?: string | undefined
                  transaction_id?: string | undefined
              }
            | undefined = paymentResponse?.[0]
        if (cardPaymentResponse?.status === 'success') {
            paymentData.paymentCardAmount = cardPaymentResponse?.amount || ''
            paymentData.voucherCode = cardPaymentResponse?.transaction_id || ''
        }

        if (!canMakeDirectPaymentPermission) {
            paymentData.paymentCardAmount =
                currentClient?.paymentCardAmount || ''
            paymentData.voucherCode = currentClient?.voucherCode || voucherCode
        }

        const summary = getPaymentSummary(
            t('enrollment.totalToPay'),
            paymentData?.paymentCardAmount || '',
            paymentData?.voucherCode || '',
            totalToPay,
            user.country
        )
        return summary
    }

    const navigateBack = () => {
        if (showPaymentSelection) {
            setShowPaymentSelection(false)
            setSelectedProduct(undefined)
            return
        }
        if (selectedProduct) {
            return setSelectedProduct(undefined)
        }
        const { ENROLLMENT } = constants.FORM_TYPES
        if (step === 1) {
            const url =
                formTypeParam === ENROLLMENT
                    ? `/village/${currentClient.village_id}/client/${currentClient.client_code}/previous`
                    : -1
            return navigate(url as To, { replace: true })
        }
        changeStep(step - 1)
    }

    const changeStep = (newStep: number): void => {
        // Submit form if we are on the last step
        if (newStep === 3) {
            return handleSubmit()
        }
        if (newStep === 1) {
            setShowPaymentSelection(false)
            setSelectedProduct(undefined)
        }
        setStep((prev: number): number => {
            if (formRef.current) {
                formRef.current.className = transitionToStep(
                    prev,
                    newStep,
                    'enrollment-form--modify-package',
                    'transition'
                )
            }
            return (prev = newStep)
        })
    }

    const fetchLocation = () => {
        setFetchingLocation(true)
        return new Promise((resolve) => {
            getUserLocation(
                (position: { coords: GpsCoords }) => {
                    setFetchingLocation(false)
                    setGeoLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                        accuracy: position.coords.accuracy
                    })
                },
                () => {
                    setFetchingLocation(false)
                    resolve({})
                }
            )
        })
    }

    const handleSubmit = () => {
        clearCardPaymentResultHandler()

        const {
            sf_id: sfId,
            client_code: clientCode,
            form_type: formType,
            ...clientData
        } = currentClient
        submitted.current = true
        const uuid = generateUuidv4()

        const payload = {
            village: currentClient?.village,
            client_id: sfId,
            client_code: clientCode,
            form_type: formType,
            packets: selectedPackets,
            username: user.username,
            date: new Date().toISOString(),
            uuid
        }

        const { latitude, longitude, accuracy } = geoLocation

        if (formType === 'modification') {
            // eslint-disable-next-line
            const { uuid: uuId, ...restClientData } = clientData
            let clientDetails = formatPayloadToClientObject({
                ...restClientData,
                ...payload
            })

            sendInteractionsActionHandler({
                survey_data: { orders: selectedPackets },
                client_code: clientDetails.client_code,
                client_status: clientDetails.client_status,
                user_id: user.username,
                survey_name: constants.PACKAGE_MODIFICATION_SURVEY_NAME,
                survey_version: constants.PACKAGE_MODIFICATION_SURVEY_VERSION,
                village_id: currentClient?.village
            })

            updatePackagesActionHandler(
                {
                    ...clientDetails,
                    latitude,
                    longitude,
                    accuracy
                },
                currentClient.goal_items,
                selectedPackets
            )

            clientDetails = {
                smss: currentClient?.smss,
                last_voucher_date: currentClient?.lastVoucherDate,
                fullname: `${currentClient?.firstname} ${currentClient?.lastname}`,
                ...clientDetails
            }

            localStorage.setItem(
                constants.CLIENT_DETAIL_LOCAL_STORE,
                JSON.stringify(clientDetails)
            )

            localStorage.setItem(
                constants.SINGLE_CLIENT_ORDERS_KEY,
                JSON.stringify(clientDetails.goal_items)
            )

            return navigate(
                `/village/${currentClient?.village}/client/${currentClient?.client_code}`,
                { replace: true }
            )
        }
        const balance =
            payload.form_type === 're-enrollment'
                ? currentClient?.balance || 0
                : paymentResponse?.[0]?.status === 'success'
                ? paymentResponse?.[0]?.amount || 0
                : 0
        const newPayload = {
            ...payload,
            ...currentClient,
            goal_items: currentClient?.goal_items || [],
            zone: zoneProductsInfo.zone_name,
            form_type:
                currentClient?.last_season_goal_item ||
                payload.form_type === 're-enrollment'
                    ? 're-enrollment'
                    : 'enrollment',
            uuid,
            balance,
            // sum all the selected packet prices
            all_enrollment: totalToPay,
            client_status:
                payload.form_type !== 'modification'
                    ? ClientStatus.PACKAGE_WITHOUT_PAYMENT
                    : currentClient?.client_status,
            longitude,
            accuracy
        }

        sendInteractionsActionHandler({
            survey_data: { orders: selectedPackets },
            client_code: currentClient.client_code,
            client_status: currentClient.client_status,
            user_id: user.username,
            survey_name: constants.PACKAGE_ENROLLMENT_SURVEY_NAME,
            survey_version: constants.PACKAGE_ENROLLMENT_SURVEY_VERSION,
            village_id: currentClient?.village
        })

        updatePackagesActionHandler(
            { ...newPayload },
            currentClient.goal_items,
            selectedPackets
        )

        const clientDatas = formatPayloadToClientObject(newPayload)
        const updatedClientData = {
            ...clientDatas,
            fullname: `${clientDatas?.firstname} ${clientDatas?.lastname}`
        }
        localStorage.setItem(
            constants.CLIENT_DETAIL_LOCAL_STORE,
            JSON.stringify(updatedClientData)
        )
        localStorage.setItem(
            constants.SINGLE_CLIENT_ORDERS_KEY,
            JSON.stringify(updatedClientData.goal_items)
        )
        localStorage.removeItem(constants.PACKAGE_ENROLLMENT)
        const reload =
            selectedPaymentType === constants.PAYMENT_OPTIONS.SCRATCH_CARD
                ? '?reload=true'
                : ''
        resetSelectVillageClientActionHandler()
        navigate(
            `/village/${newPayload.village}/client/${newPayload.client_code}${reload}`,
            { replace: true }
        )
    }

    const enrollmentConfirmState: EnrollmentConfirmStateType = {
        clientCode: currentClient?.client_code,
        phone: currentClient?.phone,
        // prevent view height to increase and scrollbar to appear as we select packets
        selectedPackets: step === 2 ? selectedPackets : [],
        firstname: currentClient?.firstname,
        lastname: currentClient?.lastname
    }

    useEffect(() => {
        if (productListData?.length === 0 && !preFetchLoading) {
            productListHandler({ zoneSFID: zone } as EnrollmentInterface)
        }
        if (
            currentClient?.form_type === 're-enrollment' ||
            currentClient?.form_type === 'modification'
        ) {
            setHideDemo(true)
        } else {
            setHideDemo(false)
        }
        return () => {
            localStorage.removeItem(constants.CLIENT_FOR_ENROLLMENT_STORE)
        }
    }, [])

    useEffect(() => {
        setTotalToPay(
            selectedPackets.reduce(
                (sum, packet) => sum + parseInt(packet.price || '0'),
                0
            )
        )
    }, [selectedPackets])

    useEffect(() => {
        if (gpsCoords) {
            return setGeoLocation({ ...gpsCoords } as GpsCoords)
        }
        fetchLocation()
    }, [gpsCoords])

    useEffect(() => {
        if (step === 2 && !geoLocation?.latitude) {
            fetchLocation()
            setShowPaymentSelection(false)
        }
    }, [step])

    const handleClose = () => {
        navigate(-1 as To, { replace: true })
    }

    useEffect(() => {
        return () => {
            const outcome = submitted.current
                ? constants.ANALYTICS.SUBMITTED
                : constants.ANALYTICS.NOT_SUBMITTED

            trackTimedEvent({
                category: constants.ANALYTICS.ENROLLMENT_FORM,
                action: `${constants.ANALYTICS.CLIENT_PACKAGE_FORM} ${currentClient?.form_type} ${outcome}`
            })
        }
    }, [])

    const onPaymentMethodSelected = (paymentMethod: string) => {
        setSelectedPaymentType(paymentMethod)
        if (
            paymentMethod === constants.PAYMENT_OPTIONS.SCRATCH_CARD &&
            step === 1
        ) {
            const clientPackageEnrollment = {
                client: currentClient,
                allSelectedPackets: { selectedPackets, totalToPay },
                step: 2
            }
            localStorage.setItem(
                constants.PACKAGE_ENROLLMENT,
                JSON.stringify(clientPackageEnrollment)
            )
            const urlParams = `${currentClient?.client_code}&next=/enrollment/package-selection?step=2%26reload=true`
            const buildCardSaleUrl = canViewCardQrPage
                ? `/cards/card-qr?client-code=${urlParams}`
                : `/cards/card-sale?client-code=${urlParams}`
            return navigate(buildCardSaleUrl, { replace: true })
        }
        setVoucher('')
        changeStep(2)
    }

    const PackagePage: JSX.Element = (
        <Container>
            <Row>
                <Col md={12}>
                    <div className="page-wrapper enrollment-container">
                        <TitleStep
                            id="titleStep"
                            title={t('enrollment.step')}
                            stepTitle={t('enrollment.step2Title')}
                            step={step}
                            totalSteps={2}
                        />
                        <div
                            ref={formRef}
                            className="enrollment-form--modify-package"
                            data-testid="modify-package-form"
                        >
                            <div
                                className="enrollment-step"
                                id="form-group-step2"
                                data-testid="form-step2"
                            >
                                {!showPaymentSelection &&
                                    (selectedProduct ? (
                                        <PacketSelectionPage
                                            selectedProduct={selectedProduct}
                                            selectedPackets={selectedPackets}
                                            onPacketSelection={
                                                setSelectedPackets
                                            }
                                            onOtherPacketsClick={() =>
                                                setSelectedProduct(undefined)
                                            }
                                            handlePacketConfirmation={
                                                handlePacketConfirmation
                                            }
                                            continueButtonText={
                                                currentClient?.form_type ===
                                                'modification'
                                                    ? t('continue')
                                                    : t('pay')
                                            }
                                        />
                                    ) : (
                                        <ProductSelectionPage
                                            zoneProductsInfo={zoneProductsInfo}
                                            selectedPackets={selectedPackets}
                                            onProductSelected={
                                                setSelectedProduct
                                            }
                                            onPacketConfirmation={
                                                handlePacketConfirmation
                                            }
                                        />
                                    ))}
                                {showPaymentSelection && (
                                    <PaymentSelection
                                        onPaymentMethodSelected={
                                            onPaymentMethodSelected
                                        }
                                        totalEnrollment={totalToPay}
                                    ></PaymentSelection>
                                )}
                            </div>
                            <EnrollmentConfirm
                                enrollmentConfirmState={enrollmentConfirmState}
                                buttonCTAText={t('enrollment.enroll')}
                                permissions={{
                                    canUpdateOrder
                                }}
                                generateDemographicSummary={
                                    generateDemographicSummary
                                }
                                generatePaymentSummary={generatePaymentSummary}
                                userProgress={changeStep}
                                step={step}
                                hideClientDemoGraphic={hideClientDemoGraphic}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )

    return (
        <>
            {loading ? (
                <Spinner size="90" pageSpinner={true} fullscreen={true} />
            ) : (
                <>
                    <div className="header-bar" id="header-bar">
                        <Container>
                            <TopBar
                                close={handleClose}
                                backNavigation={navigateBack}
                                back={true}
                            >
                                Client
                            </TopBar>
                        </Container>
                    </div>
                    {PackagePage}
                </>
            )}
        </>
    )
}
