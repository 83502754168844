import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Button, Col, Container, Row, TopBar } from '../../components'
import { useTranslation } from 'react-i18next'
import './gpswarning.css'
import GPSInstructionsGIF from '../../assets/gps-instructions.gif'
import GPSInstructionsGIF2 from '../../assets/gps-instructions2.gif'
import { customHistory } from '../../navigation/customHistory'

export const GPSWarning = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [searchParams] = useSearchParams()
    const nextPage = decodeURIComponent(searchParams.get('next') || '/visit')

    const navigateBack = () => {
        navigate(-1)
    }
    const navigateToNextPage = () => {
        customHistory.replace(nextPage)
    }

    useEffect(() => {
        document.title = t('GPSWarning.pageTitle')
    }, [])

    return (
        <>
            <div className="header-bar" id="header-bar">
                <Container>
                    <TopBar
                        back
                        backNavigation={navigateBack}
                        close={navigateBack}
                    >
                        {t('GPSWarning.pageTitle')}
                    </TopBar>
                </Container>
            </div>
            <Container>
                <Row>
                    <Col md={12}>
                        <div className="gps-warning-container">
                            <h2 className="gps-warning-message header-welcome">
                                {t('GPSWarning.disabledGPS')}
                            </h2>
                        </div>
                        <div className="gps-warning-instruction-container">
                            <p className="gps-warning-instructions-title">
                                {t('GPSWarning.enableSiteGPS')}
                            </p>
                            <p className="gps-warning-instructions-text">
                                {t('GPSWarning.siteStep1')}
                            </p>
                            <p className="gps-warning-instructions-text">
                                {t('GPSWarning.siteStep2')}
                            </p>
                            <p className="gps-warning-instructions-text">
                                {t('GPSWarning.siteStep3')}
                            </p>
                        </div>
                        <div>
                            <img
                                className="gps-warning-animated-instructions"
                                src={GPSInstructionsGIF2}
                            />
                        </div>
                        <div className="line-separator" />
                        <div className="gps-warning-instruction-container">
                            <p className="gps-warning-instructions-title">
                                {t('GPSWarning.enableGPS')}
                            </p>
                            <p className="gps-warning-instructions-text">
                                {t('GPSWarning.step1')}
                            </p>
                            <p className="gps-warning-instructions-text">
                                {t('GPSWarning.step2')}
                            </p>
                            <p className="gps-warning-instructions-text">
                                {t('GPSWarning.step3')}
                            </p>
                        </div>
                        <div>
                            <img
                                className="gps-warning-animated-instructions"
                                src={GPSInstructionsGIF}
                            />
                            <Button
                                className="gps-warning-button"
                                style="primary"
                                onClick={navigateToNextPage}
                                data-testid="gps-warning-button"
                            >
                                {t('next')}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
