import connectApi from '../api/connectApi'
import { constants, sortHandler, fetchProductPictures } from '../helpers'
import {
    sendMessageToWorker,
    sendMessageToWorkerWithResponse
} from '../helpers/communication'
import {
    HomeInterface,
    HomeActionType,
    HomeEnum,
    TargetListType,
    TargetPayloadType,
    ClientTypes,
    ClientDetailsPayloadInterface,
    DesiredOutcomes
} from '../storeTypes'
import { Dispatch } from 'react'
import { ErrorResponse } from '../types'
import { InteractionPayloadType } from 'storeTypes/interactionsTypes'

const { VES, VE_DEPOSIT, COUNTRY_CONFIG, VE_CARD_INVENTORY, ENROLLMENT } =
    constants.endpoints

const homeActionQueries = async (
    dispatch: Dispatch<HomeActionType>,
    veCODE: string,
    country: string
) => {
    let appQueries = []
    let pageQueries = []

    // ve data api query
    const veDataQuery = connectApi.get(VES, {
        params: {
            code: veCODE
        }
    })

    // ve deposit api query
    const veDepositQuery = connectApi.get(`${VE_DEPOSIT}${veCODE}`)

    // country config api query
    const countryConfigQuery = connectApi.get(COUNTRY_CONFIG, {
        params: {
            country
        }
    })

    const cardInventoryQuery = connectApi.get(VE_CARD_INVENTORY, {
        params: {
            code: veCODE
        }
    })

    appQueries = [
        veDataQuery,
        veDepositQuery,
        countryConfigQuery,
        cardInventoryQuery
    ]

    type PromiseSettledResult = {
        status: 'fulfilled'
        // eslint-disable-next-line
        value: any
    }

    const [veData, veDeposit, countryConfig, cardInventory] =
        (await Promise.allSettled(appQueries)) as PromiseSettledResult[]

    const { data: veDataResponse } = veData.value
    const veDataResult = veDataResponse[0]

    const rankingQuery = connectApi.get(VES, {
        params: {
            zone_code: veDataResult.zone_code
        }
    })

    const enrollmentPackagesQuery = connectApi.get(ENROLLMENT, {
        params: {
            zone_code: veDataResult.zone_code
        }
    })

    pageQueries = [rankingQuery, enrollmentPackagesQuery]
    // loader for ranking page
    dispatch({ type: HomeEnum.PRE_FETCHING_VE_RANKING_DATA })
    // loader for enrollment page
    dispatch({ type: HomeEnum.PRE_FETCHING_ENROLLMENT_FORM })

    Promise.allSettled(pageQueries).then(
        ([rankingData, enrollmentPackagesData]) => {
            if ('value' in rankingData) {
                // dispatch ranking response
                if (rankingData?.value?.data) {
                    dispatch({
                        type: HomeEnum.FETCH_VE_RANKING_DATA,
                        payload: rankingData.value.data
                    })
                }
            }
            if ('value' in enrollmentPackagesData) {
                if (enrollmentPackagesData?.value?.data) {
                    // dispatch enrollment package response
                    dispatch({
                        type: HomeEnum.FETCH_ENROLLMENT_FORM,
                        payload: enrollmentPackagesData.value.data
                    })
                    fetchProductPictures(
                        enrollmentPackagesData.value.data[0]?.product_list.map(
                            (product: { image_url: string }) =>
                                product.image_url
                        )
                    )
                }
            }
        }
    )

    // dispatch card inventory
    if (cardInventory?.value?.data) {
        dispatch({
            type: HomeEnum.FETCH_CARD_INVENTORY,
            payload: cardInventory.value?.data
        })
    }

    return {
        veDataResult: veDataResult || {},
        countryConfig: countryConfig.value,
        veDeposit: veDeposit.value || { data: {} }
    }
}

export const veAction =
    ({ veCODE, country }: HomeInterface) =>
    async (dispatch: Dispatch<HomeActionType>) => {
        dispatch({ type: HomeEnum.FETCHING_HOME_DATA })
        try {
            const { countryConfig, veDataResult, veDeposit } =
                await homeActionQueries(dispatch, veCODE, country)

            const connectUserLocalStorage = JSON.parse(
                localStorage.getItem('connect_user') as string
            )
            const user = {
                ...connectUserLocalStorage,
                veVillages: veDataResult.ve_villages,
                supervisor_id: veDataResult.supervisor,
                zoneSFID: veDataResult.zone,
                zoneName: veDataResult.zone_name,
                zone_code: veDataResult.zone_code
            }
            // Update user info in service worker
            sendMessageToWorker({ 'user info': user })

            localStorage.setItem('connect_user', JSON.stringify(user))
            localStorage.setItem(
                'country_config',
                JSON.stringify(countryConfig.data)
            )

            dispatch({
                type: HomeEnum.FETCH_USER_DATA,
                payload: { ...veDataResult, ...veDeposit.data }
            })
        } catch (error: unknown) {
            if (error instanceof ErrorResponse) {
                dispatch({
                    type: HomeEnum.FAIL_REQUEST,
                    payload: error.response.data.detail
                })
            } else {
                dispatch({
                    type: HomeEnum.FAIL_REQUEST,
                    payload: 'Un problème est survenu'
                })
            }
        }
    }

export const fetchTargetListAction =
    ({ veCode }: { veCode: string }) =>
    async (dispatch: Dispatch<TargetListType>) => {
        dispatch({ type: HomeEnum.FETCHING_TARGET_LIST })
        try {
            const { data } = await connectApi.get(
                `${constants.endpoints.TARGET_LIST}${veCode}`
            )

            sendMessageToWorker({ 'store target list': data })

            dispatch({
                type: HomeEnum.FETCH_TARGET_LIST,
                payload: data as TargetPayloadType[]
            })

            if (data.length < 1) {
                dispatch({
                    type: HomeEnum.NO_PROCESSED_TARGET_LIST
                })
            }
        } catch (error: unknown) {
            if (error instanceof ErrorResponse) {
                dispatch({
                    type: HomeEnum.FAIL_TARGET_LIST_REQUEST,
                    payload: error.response.data.detail
                })
            } else {
                dispatch({
                    type: HomeEnum.FAIL_TARGET_LIST_REQUEST,
                    payload: 'Un problème est survenu'
                })
            }
        }
    }

interface ProcessTargetListInterface {
    targetList: TargetPayloadType[]
    clients: ClientDetailsPayloadInterface[]
    interactions?: InteractionPayloadType[]
    selectedFilter?: string
}

export const handleTargetFilter = (
    selectedFilter: string,
    processedTargetList: TargetPayloadType[]
): TargetPayloadType[] => {
    const filterConfig: {
        [key: string]: (targetList: TargetPayloadType) => boolean
    } = {
        [constants.TARGET_LIST_FILTERS.ALL]: () => true,
        [constants.TARGET_LIST_FILTERS.NOT_VISITED]: (
            targetList: TargetPayloadType
        ) => !targetList.isCompleted,
        [constants.TARGET_LIST_FILTERS.VISITED]: (
            targetList: TargetPayloadType
        ) => targetList.isCompleted
    }
    const filterProcessedList = processedTargetList.filter((targetList) =>
        filterConfig[selectedFilter](targetList)
    )
    return filterProcessedList
}

export const processTargetListAction =
    ({
        targetList,
        clients,
        selectedFilter,
        interactions = []
    }: ProcessTargetListInterface) =>
    async (dispatch: Dispatch<TargetListType>) => {
        const selectedClientCodes = getTargetListClientCodes(targetList)
        const selectedClients = clients.filter((client) =>
            selectedClientCodes.includes(client.client_code)
        )

        const updatedTargetList = updateTargetList(
            targetList,
            selectedClients,
            interactions
        )

        const sortedTargetList = sortHandler(updatedTargetList, 'ranking')
        sendMessageToWorker({ 'store target list': sortedTargetList })

        const filtered = handleTargetFilter(
            selectedFilter || constants.TARGET_LIST_FILTERS.ALL,
            sortedTargetList
        )
        dispatch({
            type: HomeEnum.FETCH_PROCESSED_TARGET_LIST,
            payload: filtered as TargetPayloadType[]
        })
    }

const getTargetListClientCodes = (data: Array<{}>) =>
    data.map((item: { [key: string]: string }) => item.client_code)

const updateTargetList = (
    targetList: TargetPayloadType[],
    clients: ClientDetailsPayloadInterface[],
    interactions: InteractionPayloadType[]
) => {
    targetList.map((target: TargetPayloadType) => {
        const clientFound = clients.find(
            (client: ClientDetailsPayloadInterface) =>
                target.client_code === client.client_code
        )
        const interactionFound = interactions.find(
            (interaction: InteractionPayloadType) =>
                target.client_code === interaction.client_code &&
                target.uuid === interaction.target_uuid
        )

        if (clientFound) {
            target.firstname = clientFound.firstname
            target.lastname = clientFound.lastname
            target.fullname = clientFound.fullname
            target.reversed_fullname =
                (clientFound?.lastname ?? '') +
                ' ' +
                (clientFound?.firstname ?? '')
            target.client_status =
                clientFound.client_status || target.client_status
            target.village_id = clientFound.village_id
            target.client_type =
                parseInt(clientFound.all_enrollment || '0') > 0
                    ? ClientTypes.CurrentClient
                    : ClientTypes.PreviousClient
            target.phone = clientFound.phone
            target.isCompleted = !!interactionFound
            target.outcome = interactionFound?.outcome || target.outcome
            target.duplicate = clientFound.duplicate
            target.isFinisher =
                parseInt(clientFound?.all_enrollment ?? '0') > 0 &&
                (clientFound.balance ?? 0) >= (clientFound.all_enrollment ?? 0)
        }

        if (!Object.values(DesiredOutcomes).includes(target.desired_outcome)) {
            target.desired_outcome = DesiredOutcomes.ENROLLED
        }

        return target
    })

    return targetList
}

export const filterTargetListAction =
    (selectedFilter: string) => async (dispatch: Dispatch<TargetListType>) => {
        const { fetchedTargetList } = await sendMessageToWorkerWithResponse({
            'get target list': {}
        })
        dispatch({
            type: HomeEnum.FILTER_PROCESSED_TARGET_LIST,
            payload: { selectedFilter, targetList: fetchedTargetList }
        })
    }
