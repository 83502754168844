import React, { useState, useEffect, Children } from 'react'
import PropTypes from 'prop-types'
import { filterIcon } from '../../assets'
import './filter.css'
import { Button } from '../button/Button'
import { useTranslation } from 'react-i18next'

export const Filter = ({
    children,
    allOption,
    filterSelectionHandler,
    defaultSelected,
    ...props
}) => {
    const { t } = useTranslation()
    const [displayOption, setDisplayOption] = useState(false)
    const [selected, setSelected] = useState([allOption])
    const filterOptions = Children.toArray(children)
    const [refreshSelected, setRefreshSelected] = useState(false)

    useEffect(() => {
        filterSelectionHandler(selected)
        const initialFilterOptions = defaultSelected || [
            allOption,
            ...filterOptions.map((child) => child.props.value)
        ]
        // select all option by default
        setSelected([...initialFilterOptions])
    }, [])

    // handle selection state update
    useEffect(() => {
        // find and remove all from option
        const selectedFilterOptionsCount = selected.length
        const allOptionInArray = selected.indexOf(allOption) !== -1
        let updatedSelectedOptions = [...selected]
        const filterOptionLength = filterOptions.length

        if (
            selectedFilterOptionsCount <= filterOptionLength &&
            allOptionInArray
        ) {
            // remove 'all' selected option
            const newSelected = [...selected]
            newSelected.splice(0, 1)
            updatedSelectedOptions = [...newSelected]
        } else if (
            filterOptionLength === selectedFilterOptionsCount &&
            !allOptionInArray
        ) {
            updatedSelectedOptions = [allOption, ...selected]
        }

        if (refreshSelected) {
            setSelected(updatedSelectedOptions)
            setRefreshSelected(false)
        }
    }, [selected, refreshSelected])

    // handle checkbox select
    const checkboxHandler = (e) => {
        const { value, checked } = e.target
        setRefreshSelected(true)
        if (checked) {
            /*  remove visited this week option if selected
                because it's a hidden filter */
            const selectedOptions = selected.filter(
                (select) => select !== t('village.visitedThisWeek')
            )
            setSelected([...selectedOptions])
            // persist selected filter option
            setSelected([...selectedOptions, value])
            return
        }
        const checkedFilterOptions = selected.filter(
            (select) => select !== value
        )
        setSelected(checkedFilterOptions)
    }
    // handle select all option
    const selectedAll = (e) => {
        const { checked } = e.target
        const allSelected = [allOption]
        if (checked) {
            filterOptions.map((child) => {
                allSelected.push(child.props.value)
                return setSelected([...allSelected])
            })
            return setSelected([...allSelected])
        }
        setSelected([])
    }

    const displaySelected = () => {
        if (selected.length < 1) {
            return
        }
        // find and remove all from option
        const selectedOption = selected.filter((select) => select !== allOption)

        if (filterOptions.length === selectedOption.length) {
            return allOption
        }
        if (selectedOption.length === 1) {
            return selected[0]
        }
        if (selectedOption.length > 1) {
            return `${selectedOption.length} selected`
        }
    }

    const isSelected = (choice) => {
        return selected.includes(choice)
    }
    // filter option list
    const filterOptionHandler = filterOptions.map((child, idx) => {
        return React.cloneElement(child, {
            checkboxHandler,
            isSelected,
            key: idx,
            idx
        })
    })

    const filterActionButtonHandler = () => {
        filterSelectionHandler && filterSelectionHandler([...selected])
        // close filter dropdown
        setDisplayOption(false)
    }

    return (
        <div {...props} className="filter-parent">
            <div
                className="filter-select-parent"
                onClick={() => setDisplayOption(!displayOption)}
                data-testid="filter-select"
            >
                <span className="filter-select-icon">{filterIcon}</span>
                <select className="filter-select">
                    <option
                        value={displaySelected()}
                        hidden
                        className="select-option"
                    >
                        {displaySelected()}
                    </option>
                </select>
            </div>
            {displayOption && (
                <div className="filter-option">
                    <div className="filter-option--container">
                        <div
                            className="filter-option--child"
                            data-testid="filter-option"
                            id={`filter-option-${allOption}`}
                        >
                            <label htmlFor={allOption}>
                                <input
                                    id={allOption}
                                    type="checkbox"
                                    value={allOption}
                                    onChange={selectedAll}
                                    checked={selected.includes(allOption)}
                                    className="filter-checkbox"
                                />{' '}
                                {allOption}
                            </label>
                        </div>
                        {filterOptionHandler}
                    </div>
                    <div className="filter-button--parent">
                        <Button
                            size={'small'}
                            style={'primary'}
                            className="filter-button"
                            onClick={filterActionButtonHandler}
                            id="filter-button"
                            data-testid="filter-button"
                        >
                            {t('filter')}
                        </Button>
                    </div>
                </div>
            )}
        </div>
    )
}

Filter.propTypes = {
    children: PropTypes.any,
    allOption: PropTypes.string,
    filterSelectionHandler: PropTypes.func,
    defaultSelected: PropTypes.array
}
