import React from 'react'
import PropTypes from 'prop-types'
import './layout.css'
import { LayoutInterface } from './layoutTypes'

export const Container: React.FC<LayoutInterface> = ({
    children,
    fluid,
    className,
    ...props
}) => {
    const containerClassName = fluid ? `container ${fluid}` : 'container'
    return (
        <div className={[containerClassName, className].join(' ')} {...props}>
            {children}
        </div>
    )
}

Container.propTypes = {
    children: PropTypes.any,
    fluid: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', 'xxl']),
    className: PropTypes.string
}
