export const ActionTypes = {
    LOGGING: 'logging',
    LOGIN_SUCCESS: 'success',
    LOGIN_FAIL: 'fail',
    LOGOUT: 'logout',
    PROCESS_NAVBAR: 'process navbar',
    FETCH_USER_DATA: 've data',
    FAIL_REQUEST: 'fail request',
    FETCH_CARD_INVENTORY: 'fetch card inventory',
    FETCH_ENROLLMENT_FORM: 'fetch enrollment form',
    FETCHING_ENROLLMENT_FORM: 'fetching enrollment form',
    PRE_FETCHING_ENROLLMENT_FORM: 'pre fetching enrollment form',
    FAIL_ENROLLMENT_REQUEST: 'fail enrollment form request',
    SUBMITTING_ENROLLMENT_FORM: 'submitting enrollment form',
    SUBMIT_ENROLLMENT_FORM: 'submit enrollment form',
    FAIL_ENROLLMENT_SUBMISSION: 'fail enrollment submission',
    FETCH_CARD_LIST: 'fetch card list',
    FETCH_CLIENT_LIST_DATA: 'fetch client list data',
    RESET_CLIENT_LIST: 'reset client list',
    FETCHING_HOME_DATA: 'fetching home data',
    FETCHING_CARD_DATA: 'fetching card data',
    FETCHING_VILLAGE_DATA: 'fetching village data',
    FETCHING_CARD_INVENTORY_DATA: 'fetching card inventory data',
    FETCHING_VILLAGE_CLIENT_DATA: 'fetching village client data',
    SELECT_VILLAGE_CLIENT: 'select village client',
    FETCHING_PREVIOUS_CLIENT: 'fetching previous client',
    FETCH_PREVIOUS_CLIENT_LIST: 'fetch previous client',
    FAIL_PREVIOUS_CLIENT_REQUEST: 'fail previous client request',
    FETCHING_VE_RANKING_DATA: 'fetching ve ranking data',
    FETCH_VE_RANKING_DATA: 'fetch ve ranking data',
    FAIL_VE_RANKING_DATA: 'fail ve ranking data',
    PRE_FETCHING_VE_RANKING_DATA: 'prefetch fetching ve ranking data',
    PREV_SELECT_VILLAGE_CLIENT: 'previous select village client',
    FETCHING_BG_CLIENT_LIST: 'fetching background client list',
    FETCHED_BG_CLIENT_LIST: 'fetched background client list',
    FETCH_CARD_CLIENT_LIST: 'fetch card client list',
    OPENING_VILLAGE_PAGE: 'opening village page',
    MARKING_GOAL_ITEM_DELIVERED: 'marking goal item delivered',
    MARKED_GOAL_ITEM_DELIVERED: 'marked goal item delivered',
    FAILED_GOAL_ITEM_DELIVERED: 'failed goal item delivered',
    FETCHING_VE_DEPOSIT_DATA: 'fetching ve deposit data',
    FETCH_VE_DEPOSIT_DATA: 'fetch ve deposit data',
    FAIL_VE_DEPOSIT_DATA: 'fail ve deposit data',
    SUBMITTING_PRE_CERTIFY: 'submitting pre certify',
    SUBMITTED_PRE_CERTIFY: 'submitted pre certify',
    FETCHING_USER_PERMISSIONS: 'fetching user permissions',
    FETCH_USER_PERMISSIONS: 'fetch user permissions',
    FAIL_USER_PERMISSIONS: 'fail user permissions',
    SUBMITTING_VISIT_FORM: 'submitting visit form',
    SUBMIT_VISIT_FORM: 'submit visit form',
    FAIL_VISIT_FORM: 'fail visit form',
    SET_SELECTION_VILLAGE_URL: 'set selection village url',
    FETCHING_ORDERS: 'fetching orders',
    FETCH_ORDERS: 'fetch orders',
    FAIL_FETCH_ORDERS: 'fail fetch orders',
    RESET_CLIENT_ORDERS: 'reset client orders',
    FETCHING_SINGLE_CLIENT: 'fetching single client',
    FETCHED_SINGLE_CLIENT: 'fetched single client',
    FAIL_SINGLE_CLIENT_REQUEST: 'fail single client request',
    CLEAR_SELECTION_VILLAGE_INFO: 'clear selection village info',
    RECORD_INTERACTION_SUCCESS: 'record interaction success',
    RECORD_INTERACTION_FAILURE: 'record interaction failure',
    FETCH_INTERACTIONS_SUCCESS: 'fetch interactions success',
    FETCH_INTERACTIONS_FAILURE: 'fetch interactions failture',
    FETCHING_INTERACTIONS: 'fetching interactions',
    FETCH_INDEX_DB_CLIENT: 'fetch index db client',
    CLEAR_FETCHED_SINGLE_CLIENT: 'clear fetched single client',
    FETCHING_TRANSACTIONS: 'fetching transactions',
    FETCH_TRANSACTIONS_SUCCESS: 'fetch transactions success',
    FETCH_TRANSACTIONS_FAILURE: 'fetch transactions failure',
    FETCH_TRANSACTIONS_COMPLETE: 'fetch transactions complete',
    RESET_SELECT_VILLAGE_CLIENT: 'reset select village client',
    SELECTED_CLIENT: 'selected client',
    SELECTED_PREV_CLIENT: 'selected previous client',
    MULTIPLE_VILLAGE_COUNT: 'multiple village count'
}
