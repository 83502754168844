import { TargetPayloadType } from 'storeTypes'
import { InteractionOutcomes } from 'types'

export const sortTargetList = (targetList: TargetPayloadType[]) => {
    return targetList.sort((a, b) => {
        const compareOutcome =
            Number(a.outcome === InteractionOutcomes.NOT_INTERESTED) -
            Number(b.outcome === InteractionOutcomes.NOT_INTERESTED)

        if (compareOutcome) return compareOutcome
        return (
            Number(a.duplicate) - Number(b.duplicate) ||
            Number(a.isCompleted) - Number(b.isCompleted)
        )
    })
}
