import connectApi from '../api/connectApi'
import { constants } from '../helpers'
import { Dispatch } from 'redux'
import { RankingActionType, RankingEnum, RankingInterface } from '../storeTypes'
import { ErrorResponse } from '../types'

export const veRankingAction =
    ({ zoneCode }: RankingInterface) =>
    async (dispatch: Dispatch<RankingActionType>) => {
        const { VES } = constants.endpoints
        dispatch({ type: RankingEnum.FETCHING_VE_RANKING_DATA })
        try {
            // get ves data
            const { data } = await connectApi.get(VES, {
                params: {
                    zone_code: zoneCode
                }
            })

            dispatch({
                type: RankingEnum.FETCH_VE_RANKING_DATA,
                payload: data
            })
        } catch (error: unknown) {
            if (error instanceof ErrorResponse) {
                dispatch({
                    type: RankingEnum.FAIL_VE_RANKING_DATA,
                    payload: error.response.data.detail
                })
            } else {
                dispatch({
                    type: RankingEnum.FAIL_VE_RANKING_DATA,
                    payload: 'something went wrong'
                })
            }
        }
    }
