import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { DateTime } from 'luxon'
import { en, fr } from './locales'

i18n
    // detect user language
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    .init({
        debug: false,
        fallbackLng: 'fr',
        interpolation: {
            escapeValue: false
        },
        react: { useSuspense: false },
        resources: {
            fr,
            en
        }
    })

// handle full date translation with abr month
i18n.services.formatter.add('DATE_HUGE', (value, lng) => {
    return DateTime.fromJSDate(value).setLocale(lng).toFormat('dd MMM yyyy')
})

// handle month year translation
i18n.services.formatter.add('MONTH_YEAR', (value, lng) => {
    return DateTime.fromJSDate(value).setLocale(lng).toFormat('MMMM yyyy')
})

// handle day month translation
i18n.services.formatter.add('DAY_MONTH', (value, lng) => {
    return DateTime.fromJSDate(value).setLocale(lng).toFormat('dd MMM')
})

// handle full date translation with full month
i18n.services.formatter.add('DATE_FULL_HUGE', (value, lng) => {
    return DateTime.fromJSDate(value).setLocale(lng).toFormat('dd MMMM yyyy')
})
export default i18n
