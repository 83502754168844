import variables from '../scss/abstract/_variables.scss'
import { constants } from '../helpers'

interface ThemeProperties {
    [key: string]: string
}

export const themes: { [key: string]: ThemeProperties } = {
    [constants.THEME_NAMES.DEFAULT]: {
        '--app-background': variables.bodyBgColor,
        '--client-subscribed-color': variables.yellowProgressBar
    },
    [constants.THEME_NAMES.TRAINING]: {
        '--app-background': variables.trainingBgColor,
        '--client-subscribed-color': variables.white
    }
}

export const setTheme = (theme: string) => {
    try {
        if (themes[theme]) {
            for (const key in themes[theme]) {
                document.documentElement.style.setProperty(
                    key,
                    themes[theme][key]
                )
            }
            localStorage.setItem('connect_theme', theme)
        }
    } catch (error) {
        console.log(`[setTheme]: ${error}`)
    }
}
