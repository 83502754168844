export const clientSchema = {
    version: 0,
    primaryKey: 'client_code',
    type: 'object',
    properties: {
        sf_id: { type: 'string' },
        client_code: { type: 'string', maxLength: 255 },
        village_id: { type: 'string' },
        first_name: { type: 'string' },
        last_name: { type: 'string' },
        fullname: { type: 'string' },
        sex: { type: 'string' },
        zone: { type: 'string' },
        certified_for_delivery: { type: 'boolean' },
        recent_year_delivered: { type: 'string' },
        reimbursement_choice: { type: 'string' },
        mobile_money_phone: { type: 'string' },
        head_of_household: { type: 'boolean' },
        group_leader: { type: 'boolean' },
        savings_group_name: { type: 'string' },
        group_role: { type: 'string' },
        cash_balance: { type: 'integer' },
        phone: { type: 'string' },
        phone_2: { type: 'string' },
        phone_owner: { type: 'string' },
        balance: { type: 'integer' },
        country: { type: 'string' },
        goal: { type: 'string' },
        country_sharing_rule: { type: 'string' },
        date_modified: { type: 'string' },
        date_created: { type: 'string' },
        age: { type: 'string' },
        all_enrollment: { type: 'string' }
    },
    required: [
        'sf_id',
        'client_code',
        'village_id',
        'first_name',
        'last_name',
        'fullname',
        'sex',
        'zone',
        'reimbursement_choice',
        'mobile_money_phone',
        'head_of_household',
        'group_leader',
        'phone',
        'phone_2',
        'phone_owner',
        'date_created',
        'age'
    ]
}
