import {
    ClientSearchActionTypes,
    ClientSearchEnum,
    ClientSearchType,
    ClientType
} from '../storeTypes'

import { omitBy } from 'lodash'

type SearchObject = {
    client_code: string
    firstname: string
    lastname: string
    fullname: string
    phone: string
}

const searchClient = (
    clients: ClientType[] | [],
    searchParams: Partial<SearchObject>
) => {
    const searchTerms = Object.entries(searchParams)
    const searchResult = clients
        .filter(
            (client) => client.firstname !== null && client.lastname !== null
        )
        .filter((client) =>
            searchTerms.find(([key, value]) => {
                const fullName = client.firstname.concat(' ', client.lastname)
                const reverseFullName = client.lastname.concat(
                    ' ',
                    client.firstname
                )

                const nameRegex = new RegExp(
                    `${value
                        .split(' ')
                        .map((word) => `(?=.*${word})`)
                        .join('')}`,
                    'gi'
                )

                if (client.phone === searchParams.phone) {
                    return (
                        fullName.match(nameRegex) ||
                        reverseFullName.match(nameRegex)
                    )
                }

                if (key === 'fullname' && !searchParams?.phone) {
                    return (
                        fullName.match(nameRegex) ||
                        reverseFullName.match(nameRegex)
                    )
                }

                if (key === 'client_code') {
                    return client.client_code.includes(value)
                }

                return null
            })
        )
    return searchResult
}

const buildFirstAndLastName = (firstName?: string, lastName?: string) =>
    firstName
        ?.trim()
        .concat(' ', lastName?.trim() || ' ')
        .trim()

const isBlank = (value: string | undefined) => value == null || value === ''

const clientSearchHandler = (
    clientList: ClientType[],
    searchParams: ClientSearchType
): ClientType[] => {
    const { clientId, firstName, lastName, fullName, primaryPhone } =
        searchParams

    const firstAndLastName = buildFirstAndLastName(firstName, lastName)

    const searchObject: Partial<SearchObject> = {
        client_code: clientId,
        phone: primaryPhone,
        fullname: fullName || firstAndLastName
    }

    return searchClient(clientList, omitBy(searchObject, isBlank))
}

export const clientSearchReducer = (
    state: { searchResult: ClientType[]; searchQuery: ClientSearchType } = {
        searchResult: [],
        searchQuery: {}
    },
    action: ClientSearchActionTypes
): { searchResult: ClientType[]; searchQuery: ClientSearchType } => {
    switch (action.type) {
        case ClientSearchEnum.FETCH_CLIENT_SEARCH: {
            let searchResult = clientSearchHandler(
                action.payload.allClientList,
                action.payload.searchParams
            )
            searchResult = searchResult.filter(
                (result) => !result._is_deleted
            ) as []
            return {
                ...state,
                searchResult,
                searchQuery: action.payload.searchParams
            }
        }
        case ClientSearchEnum.CLEAR_CLIENT_SEARCH:
            return {
                ...state,
                searchResult: [],
                searchQuery: {}
            }
        default:
            return state
    }
}
