import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { closeIcon } from 'assets'
import { CardPaymentPayloadType } from 'storeTypes'
import { updateCssClass } from './notificationUtils'

type NotificationModalType = {
    status: string
    cardNumber: string
    dateTime: string
    clientCode: string
    closeModal: (value: CardPaymentPayloadType | undefined) => void
    icon: ReactNode
    processedStatus: string
    formattedDateTime: string
}

export const NotificationModal: React.FC<NotificationModalType> = ({
    status,
    cardNumber,
    dateTime,
    clientCode,
    closeModal,
    icon,
    processedStatus,
    formattedDateTime
}) => {
    const { t } = useTranslation()
    const cardErrorMessages = {
        used_voucher: t('cardPayment.usedVoucher', {
            voucher: cardNumber
        }),
        non_existant_voucher: t('cardPayment.nonExistentVoucher', {
            voucher: cardNumber
        }),
        invalid_client: t('cardPayment.invalidClient', {
            client: clientCode
        }),
        success: t('cardPayment.successMsg'),
        offline: t('cardPayment.offlineMsg'),
        pending: t('cardPayment.paymentPending')
    }
    return (
        <div className="notification-modal" data-testid="payment-modal">
            <div className="notification-modal--bg"></div>
            <div className="notification-modal--main">
                <div
                    className="notification-modal--close"
                    onClick={() => closeModal(undefined)}
                    data-testid="close-payment-modal"
                >
                    {closeIcon({ color: '#000000', width: '17', height: '17' })}
                </div>
                <div className="notification-modal--header-parent">
                    <span
                        className={updateCssClass(
                            'notification-modal--icon-parent',
                            processedStatus
                        )}
                    >
                        {icon}
                    </span>
                    <p className="notification-modal--header">
                        {t('payment')}{' '}
                        {t(`cardPayment.${processedStatus || ''}`)}
                    </p>
                </div>
                <div className="notification-modal--msg-parent">
                    <p className="notification-modal--msg">
                        {cardErrorMessages[
                            status as keyof typeof cardErrorMessages
                        ] || t('cardPayment.paymentFailedMsg')}
                    </p>
                </div>
                <div className="notification-modal--details-parent">
                    <p className="notification-modal--details-header">
                        {t('cardPayment.paymentDetails')}
                    </p>
                    <div className="notification-modal--details-body">
                        <div className="notification-modal--details-row">
                            <p className="notification-modal--details-content notification-modal--details-title">
                                N° {t('voucher')}
                            </p>
                            <p className="notification-modal--details-content notification-modal--details-text">
                                {cardNumber}
                            </p>
                        </div>
                        <div className="notification-modal--details-row">
                            <p className="notification-modal--details-content notification-modal--details-title">
                                {t('cardPayment.date')}
                            </p>
                            <p className="notification-modal--details-content notification-modal--details-text">
                                {new Date(dateTime)
                                    .toLocaleDateString()
                                    .replace(/\//g, '-')}
                                , {formattedDateTime || ''}
                            </p>
                        </div>
                        <div className="notification-modal--details-row">
                            <p className="notification-modal--details-content notification-modal--details-title">
                                {t('cardPayment.status')}
                            </p>
                            <span
                                className={updateCssClass(
                                    'notification-modal--status-tag',
                                    processedStatus
                                )}
                            >
                                <p className="notification-modal--status-tag-text">
                                    {t(`cardPayment.${processedStatus || ''}`)}
                                </p>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
