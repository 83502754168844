import { setTheme, constants } from '.'
import { sendMessageToWorker } from './communication'

export const setTrainingMode = (trainingMode: boolean) => {
    try {
        localStorage.setItem('connect_training_mode', trainingMode.toString())
        sendMessageToWorker({ 'training mode': trainingMode })
        setTheme(
            trainingMode
                ? constants.THEME_NAMES.TRAINING
                : constants.THEME_NAMES.DEFAULT
        )
    } catch (error) {
        console.log(` [setTrainingMode]: ${error}`)
    }
}
