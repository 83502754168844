import {
    sendMessageToWorker,
    sendMessageToWorkerWithResponse
} from './communication'

// fetch blank Ids
export const fetchBlankIds = async () =>
    await sendMessageToWorkerWithResponse({
        'get blank id': 'client needs a blank id'
    })

export const deleteBlankId = (clientCode: string) =>
    clientCode &&
    sendMessageToWorker({
        'delete blank id': clientCode
    })
